import React from "react";
import "./Coll.css";
import skincare from "../../assets/skincare.jpg";
import haircare from "../../assets/haircare.jpg";
import bodycare from "../../assets/bodycare.jpg";
import newcare from "../../assets/newww.png";
import { Link } from "react-router-dom";

const Collections = () => {
  return (
    <div id="coll" className="collections-container">
      <h2 className="collections-title">İşlemlerimiz</h2>
      <div className="collections-grid">
        <Link to={"/skin"} className="thecard">
          <div className="collection-item thefront">
            <img src={skincare} alt="Skincare" />
            <div className="collection-details">
              <h3 className="collection-name">Cilt</h3>
              <button className="collection-btn">
                <span className="btn-arrow">→</span>
              </button>
            </div>
          </div>
          <div class="theback">
            <h5>Sağlıklı ve Genç Bir Cilt İçin İşlemlerimiz</h5>
            <p>
            Cilt tedavilerimizle daha sağlıklı, pürüzsüz ve genç bir cilde kavuşun. Uzman ekibimizle cildinizin ihtiyaçlarına özel çözümler sunuyoruz.
            </p>
            <Link to={"/skin"} className="explore-btn">Daha Fazlası</Link>
          </div>
        </Link>
        <Link to={"/hair"} className="thecard">
          <div className="collection-item thefront">
            <img src={haircare} alt="Haircare" />
            <div className="collection-details">
              <h3 className="collection-name">Saç</h3>
              <button className="collection-btn">
                <span className="btn-arrow">&#8594;</span>
              </button>
            </div>
          </div>
          <div class="theback">
            <h5>Sağlıklı ve Güçlü Saçlar İçin İşlemlerimiz</h5>
            <p>
            Saç tedavilerimizle daha güçlü, dolgun ve sağlıklı saçlara kavuşun. Uzman ekibimizle saçınızın ihtiyaçlarına özel çözümler sunuyoruz.
            </p>
             <Link to={"/hair"} className="explore-btn">Daha Fazlası</Link>
          </div>
        </Link>
        <Link to={"/body"} className="thecard">
          <div className="collection-item thefront">
            <img src={bodycare} alt="Bodycare" />
            <div className="collection-details">
              <h3 className="collection-name">Vücut</h3>
              <button className="collection-btn">
                <span className="btn-arrow">&#8594;</span>
              </button>
            </div>
          </div>
          <div class="theback">
            <h5>Fit ve Sağlıklı Bir Vücut İçin İşlemlerimiz</h5>
            <p>
            Vücut tedavilerimizle daha fit, sağlıklı ve enerjik bir vücuda kavuşun. Uzman ekibimizle vücudunuzun ihtiyaçlarına özel çözümler sunuyoruz
            </p>
             <Link to={"/body"} className="explore-btn">Daha Fazlası</Link>
          </div>
        </Link>
        <Link to={"/face"} className="thecard">
          <div className="collection-item thefront">
            <img src={newcare} alt="Newcare" />
            <div className="collection-details">
              <h3 className="collection-name">Yüz</h3>
              <button className="collection-btn">
                <span className="btn-arrow">→</span>
              </button>
            </div>
          </div>
          <div class="theback">
            <h5>Canlı ve Genç Bir Yüz İçin İşlemlerimiz</h5>
            <p>
              Yüz tedavilerimizle daha canlı, genç ve sağlıklı bir görünüme kavuşun. Uzman ekibimizle yüzünüzün ihtiyaçlarına özel çözümler sunuyoruz.
            </p>
             <Link to={"/face"} className="explore-btn">Daha Fazlası</Link>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Collections;
