import { Link } from "react-router-dom";
import "./navbar.scss";
import { motion } from "framer-motion";
import Sidebar from "./SideBar";
import SearchBar from "../searchBar/SearchBar";
import logo from "./genesis-nav-logo.png"



const Navbar = () => {
  return (
    <div className="genessis-navbar">
      <div className="genessis-navbar-content">
        <div className="wrapper">
          <Link to={"/"} className="logo-container">
            <motion.img
              className="logo"
              src={logo}
              alt="Genesis Logo"
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            />
          </Link>
          <Sidebar />
          <SearchBar />
          <div className="nav-content">
            <ul id="navbar" className="genessis-ul-navbar">
              <li className="list">
                <Link to="/">ANASAYFA</Link>
              </li>
              <li className="list dropdown">
                <span className="dropbtn">
                  HİZMETLERİMİZ
                  <i className="fa-solid fa-caret-down ms-2 fs-7"></i>
                </span>
                <div className="dropdown-content">
                  <Link to="/genetik">Genetik Test</Link>
                  <Link to="/ozon">Ozon Terapi</Link>
                  <Link to="/iv">IV Terapi</Link>
                  <Link to="/kokhucre">Kök Hücre Tedavisi</Link>
                  <Link to="/eksozom">Eksozom Tedavisi</Link>
                  <Link to="/genclesme">Longevity (Uzun Yaşam)</Link>
                  <div className="dropdown-submenu">
                    <span className="dropbtn">
                      Estetik İşlemlerimiz
                      <i className="fa-solid fa-caret-right ms-2 fs-7"></i>
                    </span>
                    <div className="dropdown-submenu-content">
                      <Link to="/skin">Cilt</Link>
                      <Link to="/hair">Saç</Link>
                      <Link to="/body">Vücut</Link>
                      <Link to="/face">Yüz</Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="list">
                <Link to="/blog">BLOG</Link>
              </li>
              <li className="list">
                <Link to="/about">HİKAYEMİZ</Link>
              </li>
              <li className="list">
                <Link to="/contact">İLETİŞİM</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
