import { motion } from "framer-motion";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { mobile } from "../../responsive";
import axios from "axios";

const Container = styled.div`
  display: flex;
  color: var(--white);
  padding: 4px 0px;
  letter-spacing: 3px;
  overflow: hidden;
  background-color: var(--textColor);
  ${mobile({ display: "none" })}
`;

const sliderVariants = {
  animate: (custom) => ({
    x: ["0%", `-${custom}%`],
    transition: {
      repeat: Infinity,
      duration: custom * 0.2, // Kaydırma süresi, text uzunluğuna bağlı olarak ayarlanır
      ease: "linear",
    },
  }),
};

const Announcement = () => {
  const [text, setText] = useState("");
  const [customDuration, setCustomDuration] = useState(100); // Varsayılan süre

  useEffect(() => {
    fetchText();
  }, []);

  const fetchText = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/information`);
      if (response.data && response.data.length > 0) {
        setText(response.data[0].content);
        const textLength = response.data[0].content.length;
        setCustomDuration(textLength * 1); // Text uzunluğuna bağlı olarak kaydırma süresini ayarla
      }
    } catch (error) {
      console.error("Error fetching text:", error);
    }
  };

  return (
    <Container>
      <motion.span
        variants={sliderVariants}
        initial="animate"
        animate="animate"
        custom={customDuration}
        style={{
          display: "inline-block",
          minWidth: "100%",
          whiteSpace: "nowrap",
          marginRight: "154px",
        }} // Yazılar arasında boşluk bırakmak için marginRight eklendi
      >
        {text}
      </motion.span>
      <motion.span
        variants={sliderVariants}
        initial="animate"
        animate="animate"
        custom={customDuration}
        style={{
          display: "inline-block",
          minWidth: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </motion.span>
    </Container>
  );
};

export default Announcement;