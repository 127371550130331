import "../layout.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from '../contactCard/contactCard';

const Ozon = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="services-body">
      <div className="md:grid  grid-cols-2 services-header">
      <div className="services-responsive-header ozon-header-image"></div>
        <div className="services-header-text">
          <h1 className="services-header-title">Ozon Terapi</h1>
          <h3 className="services-header-title2">
          Ozon terapi, ozon gazının tıbbi amaçlarla kullanılmasıdır. Bu tedavi yöntemi, ozonun antimikrobiyal, antioksidan ve anti inflamatuar özelliklerinden yararlanarak pek çok sağlık sorununun tedavisinde uygulanır. Ozon gazı, vücuda uygulandığında oksijenle reaksiyona girer ve bazı biyokimyasal tepkimeleri tetikleyerek hücresel düzeyde bir dizi olumlu etkiye neden olur. Ozon terapisi, genellikle enfeksiyonlar, yara iyileşmesi, antioksidan desteği, kronik ağrı, iltihaplanma ve bağışıklık sistemini güçlendirme gibi durumlarda oldukça etkilidir. 
          </h3>
        </div>
        <div className="ozon-header-image services-header-image"></div>
        
      </div>

      <div className="services-content">
        <div>
          <h1 className="services-title1">Multipass Ozon Terapi</h1>
          <p className="services-text">
            Multipass ozon terapisi, ozon gazının kan içinden birden fazla kez
            dolaştırılmasını içeren özel bir ozon terapisi yöntemidir. Bu
            yöntem, ozonun terapötik etkilerini daha iyi sağlamak için kanın
            daha fazla oksijenle doyurulmasını sağlamayı amaçlamaktadır.
            Multipass ozon terapi, normal ozon terapisine kıyasla daha yüksek
            dozlarda ozonun vücuda verilebildiği bir yöntemdir. Bu terapide,
            ozonlu solüsyon, bir infüzyon seti aracılığıyla tekrar tekrar
            geçirilir ve kanda daha uzun süre kalır. Bu şekilde, daha fazla ozon
            molekülü hücrelere ulaşabilir ve terapötik etki artar.
          </p>
        </div>
        
        <div className="md:grid gap-x-10 pt-8 grid-cols-2">
          <div className="ozone-img1 services-image">
          </div>
          <div>
          <h1 className="services-title2">Multipass Ozon Terapinin Faydaları</h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="fayda1">
                  Bağışıklık Sistemini İyileştirme
                </AccordionHeader>
                <AccordionBody accordionId="fayda1">
                  Multipass ozon terapinin bağışıklık fonksiyonunu artırarak vücudun enfeksiyonlar ve hastalıklarla savaşmasına yardımcı olduğu gösterilmiştir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda2">
                  Yaşlanmayı Geciktirme
                </AccordionHeader>
                <AccordionBody accordionId="fayda2">
                Ozon tedavisi, antioksidan etkisiyle serbest radikallerin zararlı etkilerini azaltarak hücreleri korur. Bu da yaşlanma sürecini yavaşlatabilir ve hücresel yenilenmeyi destekleyebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda3">
                Üst Solunum Yolu Enfeksiyonlarına Direnci Artırma
                </AccordionHeader>
                <AccordionBody accordionId="fayda3">
                Ozon terapisi, solunum yollarında antiviral ve antibakteriyel etkiye sahip olabilir. Bu sayede üst solunum yolu enfeksiyonlarına karşı direnci artırarak hastalıklara karşı önleyici bir etki gösterebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda4">
                Sindirim Sistemi Sağlığına Katkıda Bulunma
                </AccordionHeader>
                <AccordionBody accordionId="fayda4">
                Ozon terapisi, bağırsaklarda etkili bir şekilde emilerek sindirim sistemini destekleyebilir. Bağırsak hareketlerini düzenleyerek kabızlık sorununu azaltabilir ve bağırsak florasını dengeleyebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda5">
                Enerji Seviyelerini Yükseltme
                </AccordionHeader>
                <AccordionBody accordionId="fayda5">
                Hastalar genellikle multipass ozon terapi sonrası enerji seviyelerinde artış deneyimler.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda6">
                Yara Onarımı
                </AccordionHeader>
                <AccordionBody accordionId="fayda6">
                Yara onarımına yardımcı olarak yaraların daha hızlı iyileşmesini sağlar.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        

      <h1 className="services-title1">Ozon Terapisi Nasıl Uygulanır?</h1>
      <p className="services-text">Ozon terapisi farklı şekillerde uygulanabilir:</p>

      <div className="gap-x-20 md:grid grid-cols-2">
              <div>
                  <p className="services-text">
                      <li className="mt-2"><b className="services-title3">Majör otohemoterapi</b><br /> Bu yöntemde, kişiden 50-100 ml kan alınır ve alınan kan ozon gazıyla karıştırıldıktan sonra tekrar vücuda verilir.</li>
                      <li className="mt-2"><b className="services-title3">Minör otohemoterapi</b><br /> Bu yöntemde, kişiden 5-10 ml kan alınır ve kan ozon gazıyla karıştırıldıktan sonra tekrar vücuda verilir.</li>
                      <li className="mt-2"><b className="services-title3">Subkutan uygulama</b><br /> Ozon gazının cilt altına enjekte edilmesiyle gerçekleştirilen bir yöntemdir.</li>
                      </p>
              </div>
              <div>
                <p className='services-text'>
                  <li><b>Rektal, vajinal veya kulak yoluyla ozon uygulaması</b><br /> Ozon gazı, vücut boşluklarına enjekte edilerek uygulanabilir.</li>
                      <li className="mt-2"><b className="services-title3">Lokal ozon uygulaması</b><br /> Bu yöntemde ozon gazı, eklem içine enjekte edilerek lokal olarak uygulanır.</li>
                      <li className="mt-2"><b className="services-title3">Multipass Ozon Terapisi</b><br /> Ozon gazı bir kapalı devre sistemde kan içinden birden fazla kez dolaştırılır.</li></p>
              </div>
      </div>

      <ContactCard />

      <h1 className="services-title1">Sık Sorulan Sorular</h1>
      <div className="">
      <Accordion  flush open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="soru1">Hangi durumlar ozon terapisiyle tedavi edilebilir?</AccordionHeader>
            <AccordionBody accordionId="soru1">
            Ozon terapisi, kronik enfeksiyonlar, otoimmün hastalıklar, kronik yorgunluk sendromu, kas-iskelet bozuklukları ve diş enfeksiyonları gibi çeşitli durumların tedavisinde kullanılabilir.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="soru2">Ozon terapisi güvenli midir?</AccordionHeader>
            <AccordionBody accordionId="soru2">
            Nitelikli bir sağlık uzmanı tarafından uygulandığında, ozon terapisi genellikle güvenli kabul edilir. 
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="soru3">Ozon terapisini destekleyen bilimsel çalışmalar var mı?</AccordionHeader>
            <AccordionBody accordionId="soru3">
            Evet, çeşitli durumlar için ozon terapisinin kullanımını destekleyen bilimsel literatür artmaktadır. Ancak, bu tedavinin etkinliğini ve mekanizmalarının tam olarak anlaşılabilmesi için daha fazla araştırma yapılması gerektiği unutulmamalıdır.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="soru4">Ozon terapi seansı ne kadar sürer?</AccordionHeader>
            <AccordionBody accordionId="soru4">
            Ozon terapi seans süresi, kullanılan spesifik tedavi yöntemine ve ele alınan duruma bağlı olarak değişebilir. Genellikle, bir oturum birkaç dakikadan bir saate kadar sürebilir.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="soru5">Ozon terapisinin yan etkileri var mı?</AccordionHeader>
            <AccordionBody accordionId="soru5">
            Ozon terapisinden sonra bazı kişiler baş ağrısı, bulantı veya yorgunluk gibi hafif yan etkiler yaşayabilir. Bu yan etkiler genellikle geçicidir ve kendiliğinden düzelir. 
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>

      </div>


    </div>
  );
};

export default Ozon;
