import { Link } from "react-router-dom";
import "./modal.scss";

const KokModal = () => {
  const handleLinkClick = () => {
    // btn-close butonunu seç ve tıklama olayını tetikle
    const closeButton = document.querySelector("#kokModal .btn-close");
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="kokModal"
      tabIndex={-1}
      aria-labelledby="kokModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <strong className="modal-title fs-5" id="kokModalLabel">
              Tıpta Devrim Yaratan Yenilik
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            Kök hücre tedavisi, son yıllarda tıp alanında büyük bir ilgi gören
            yenilikçi bir tedavi yöntemidir. Kök hücreler, vücudumuzdaki diğer
            hücrelere dönüşebilen ve yeni hücreler oluşturabilen özel
            hücrelerdir. Bu tedavi yöntemi, çeşitli hastalıkların tedavisinde
            umut vaat eder. Kök hücre tedavisi, hasar görmüş dokuları onarmak,
            fonksiyonel bozuklukları düzeltmek ve çeşitli hastalıkları tedavi
            etmek amacıyla uygulanan bir dizi prosedürü içerir.
          </div>
          <div class="modal-footer">
            <Link
              to="/kokhucre"
              type="button"
              class="btn"
              onClick={handleLinkClick}
            >
              Daha Fazlası
            </Link>
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KokModal;
