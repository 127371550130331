import React, { useContext } from 'react';
import AnimatedText from '../../components/animatedText/AnimatedText';
import Slider from '../../components/slider/HomeSlider';
import Scroll from '../../components/scrool/scrool';
import Cards from '../../components/cards/Cards';
import HomeSection from '../../components/homeSection/HomeSection';
import Blog from '../../components/blog/Blog';
import Collections from '../../components/collections/Collections';
import MobileSwiper from '../../components/cards/MobileSwiper';
import { SearchContext } from '../../components/searchBar/SearchContext';

const Home = () => {
  const { searchTerm } = useContext(SearchContext);

  return (
    <div>
      <AnimatedText />
      <Slider />
      <Scroll />
      <Cards searchQuery={searchTerm} /> {/* searchQuery prop'unu ekleyin */}
      <MobileSwiper searchQuery={searchTerm}/>
      <HomeSection />
      <Blog />
      <Collections />
    </div>
  );
};

export default Home;
