import { useEffect, useState } from "react";
import "./SkinCareAd.css";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const Bgc = () => {
  const [header, setHeader] = useState(null);
  const [loading, setLoading] = useState(true); // Yükleme durumu için state ekledik

  useEffect(() => {
    const fetchHeader = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blogHeader`);
        setHeader(response.data[0]);
        setLoading(false); // Yükleme tamamlandığında loading state'ini false yap
        // console.log(response.data);
      } catch (error) {
        console.error('Error fetching blog header:', error);
        setLoading(false); // Hata durumunda loading state'ini false yap
      }
    };

    fetchHeader();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <p>Yükleniyor...</p>
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (!header) {
    return null;
  }

  return (
    <div className="bgc">
      <div id="bae" className="containerw">
        <div className="textContainere">
          <h1 className="titlee">{header.title}</h1>
        </div>
        <img src={header.image} alt="" className="imageT" />
      </div>
    </div>
  );
};

export default Bgc;
