import "../operations.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from "../../services/contactCard/contactCard";


const BodyOperations = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  //url fix
  

  return (
    <div id="operations-body">
      <div className="md:grid grid-cols-2 operations-header">
        <div className="operations-responsive-header body-header-image"></div>
        <div className="operations-header-text">
          <h1 className="operations-header-title">Fit ve Sağlıklı Bir Vücut İçin İşlemlerimiz</h1>
          <h3 className="operations-header-title2">
          Vücut tedavilerimizle daha fit, sağlıklı ve enerjik bir vücuda kavuşun. Uzman ekibimizle vücudunuzun ihtiyaçlarına özel çözümler sunuyoruz
            <br />
            <a className="bodyoperations-header-link" href="#terleme-botoksu">
              <div className='bodyoperations-header-link-div bodyoperation-link-div'>
                <div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div>
                <div> Terleme Botoksu</div>
              </div></a>
            <a className="bodyoperations-header-link" href="#migren-botoksu"><div className='bodyoperations-header-link-div bodyoperation-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Migren Botoksu</div></div></a>
            <a className="bodyoperations-header-link" href="#selülit-tedavi"><div className='bodyoperations-header-link-div bodyoperation-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Selülit ve Bölgesel Yağlanma Tedavisi</div></div></a>
            
            <a className="bodyoperations-header-link" href="#gıdı-tedavi"><div className='bodyoperations-header-link-div bodyoperation-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Gıdı Eritme</div></div></a>

          </h3>
        </div>
        <div className="operations-header-image body-header-image"></div>
      </div>
      <div id="terleme-botoksu"></div>
      <div className="operations-content">
        <h1 className="operations-main-title" >
        Terleme Botoksu
        </h1>
        <p className="operations-text">
        Terleme botoksu, aşırı terleme sorununu çözmek amacıyla botulinum toksin enjeksiyonlarının kullanıldığı bir tedavi yöntemidir. Botulinum toksin ter bezlerine enjekte edildiğinde, ter salgılayan sinirlerin aktivitesini bloke eder ve böylece ter üretiminiazaltır. Bu yöntem genellikle aşırı terleme (hiperhidroz) sorununun tedavisinde etkili olabilmektedir.{" "}
        </p>
        <br />
        <h1 className="operations-title2" >
        Terleme Botoksu Hangi Bölgelere Uygulanır?
        </h1>
        <p className="operations-text">
        Terleme botoksu genellikle koltuk altları, el ve ayak tabanları, yüz, saç derisi, avuç içleri gibi aşırı terleme sorununun sıkça görüldüğü bölgelere uygulanmaktadır. Bu bölgelerde ter üretimini azaltmak ve aşırı terlemeyi kontrol altına almak amacıyla botulinum toksini enjeksiyonları yapılmaktadır.{" "}
        </p>

        <div className="md:grid gap-x-10 pt-4 grid-cols-2">
          <div className="operations-image terleme-img1">
          </div>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="terleme1">
                Koltuk Altı Terleme Botoksu
                </AccordionHeader>
                <AccordionBody accordionId="terleme1">
                Koltuk altı terleme botoksu, aşırı terleme sorununun en yaygın olarak görüldüğü bölgelere uygulanan bir terleme botoksu tedavisidir. Bu bölgede gerçekleştirilen botulinum toksin enjeksiyonları, ter bezlerini etkileyen sinirlerin aktivitesini azaltarak, koltuk altı terlemesini kontrol altına almaya yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="terleme2">
                Yüz Terleme Botoksu
                </AccordionHeader>
                <AccordionBody accordionId="terleme2">
                Yüz terleme botoksu, genellikle yüz bölgesinde aşırı terleme sorunu yaşayan kişilere uygulanan bir terleme botoksu tedavisidir. Botulinum toksin enjeksiyonları, ter bezlerinin aktivitesini azaltarak yüz bölgesindeki aşırı terlemeyi kontrol altına almaya yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="terleme3">
                El Terleme Botoksu
                </AccordionHeader>
                <AccordionBody accordionId="terleme3">
                El terleme botoksu, ellerde aşırı terleme problemi yaşayan kişilere uygulanan bir terleme botoksu tedavisidir. Botulinum toksini enjeksiyonları, ter bezlerinin etkisini azaltarak el terlemesini kontrol etmeye yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="terleme4">
                Ayak Terleme Botoksu
                </AccordionHeader>
                <AccordionBody accordionId="terleme4">
                Ayak terleme botoksu, ayaklarda aşırı terleme sorunu yaşayan kişilere uygulanan bir terleme botoksu tedavisidir. Botulinum toksin enjeksiyonları, ter bezlerinin aktivitesini azaltarak ayak terlemesini kontrol altına almaya yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        <div className="gap-x-10 pt-8 md:grid grid-cols-2">          
          <div>
            <h1 className="operations-title2">
            Terleme Botoksu Nasıl Yapılır?
            </h1>
            <p className="operations-text">
            Terleme botoksu işlemi, uzman bir sağlık profesyoneli tarafından yapılır. Botulinum toksin, ince iğneler kullanılarak ter bezlerine enjekte edilir. İşlem sırasında genellikle lokal anestezik krem kullanılır ve işlem süresi bireysel terleme sorununa göre değişebilir.
            </p>
          </div>
          <div className="operations-image terleme-img2"></div>
        </div>
       
        <h1 className="operations-title1">Terleme Botoksu Öncesi Hazırlık Süreci</h1>
        <p className="operations-text">
        Terleme botoksu öncesinde, hastanın tıbbi geçmişi detaylı bir şekilde incelenir ve alerjik reaksiyonlarıyla ilgili bilgi alınır. Ayrıca, işlem öncesinde alkol ve kan inceltici ilaçlardan kaçınmak önemlidir. Uzman sağlık profesyoneli, hastanın durumunu değerlendirerek en uygun tedavi planını belirler.{" "}
        </p>
        <h1 className="operations-title1">Terleme Botoksu Sonrası Dikkat Edilmesi Gerekenler</h1>
        <p className="operations-text">
        Terleme botoksu sonrasında, enjeksiyon bölgesini ovuşturmamak veya masaj yapmamak önemlidir. İşlem sonrasında aşırı fiziksel aktivitelerden veya aşırı sıcaktan kaçınılmalıdır. Ayrıca, kullanılan ilaçları düzenli olarak almak, ılık duş almak ve cildi nemlendirmek gibi adımlar da önemlidir. Herhangi bir sorun görüldüğünde mutlaka sağlık profesyoneline başvurulmalıdır.{" "}
        </p>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="terleme-soru1">
              Terleme botoksu zararlı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="terleme-soru1">
              Terleme botoksu zararlı bir işlem değildir. Sadece enjekte edildiği alanda belirli bir süre boyunca etkili olur ve terleme faaliyetini geçici süreyle kontrol altına alır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="terleme-soru2">
              Terleme botoksu kaç yaşında yapılır?
              </AccordionHeader>
              <AccordionBody accordionId="terleme-soru2">
              Genel olarak terleme botoksu, aşırı terleme sorununun tedavisinde 18 yaşından itibaren yapılabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="terleme-soru3">
              Terleme botoksu yüze yapılır mı?
              </AccordionHeader>
              <AccordionBody accordionId="terleme-soru3">
              Evet, terleme botoksu yüze yapılabilmektedir. Yüz bölgesinde aşırı terleme sorunu varsa, özellikle alın, kaş arası çizgiler ve üst dudak terlemesi gibi belirli bölgelere de botoks enjeksiyonları yapılabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="terleme-soru4">
              Terleme botoksu ne zaman etki eder?
              </AccordionHeader>
              <AccordionBody accordionId="terleme-soru4">
              Terleme botoksu genellikle uygulandıktan sonra birkaç gün içinde etkisini göstermeye başlar. Ancak, en iyi sonuçlar genellikle iki hafta içinde ortaya çıkar. 
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="terleme-soru5">
              Terleme botoksu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="terleme-soru5">
              Terleme botoksu, genellikle uygulandıktan sonra birkaç ay süren etkili bir terleme kontrolü sağlar. Bu süre kişiden kişiye ve uygulama yapılan bölgeye bağlı olarak değişebilir, ancak genellikle altı ay ila bir yıl arasında sürer. Sonrasında etkinin azalmasıyla birlikte yeniden uygulanması gerekebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>

        <ContactCard />

        <div id="migren-botoksu"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
        Migren Botoksu
        </h1>
        <p className="operations-text">
        Migren, şiddetli baş ağrıları, mide bulantısı, ışığa ve sese karşı hassasiyet gibi semptomlarla karakterize edilen kronik bir nörolojik bozukluktur. Migren atakları yaşam kalitesini önemli ölçüde düşürebilir ve günlük aktiviteleri etkileyebilir. Geleneksel olarak migren tedavisinde ağrı kesiciler, antiemetikler ve triptanlar gibi ilaçlar kullanılır. Ancak bazı durumlarda bu ilaçlar yeterli olmayabilir.
         <br /><br />
        Son yıllarda migren tedavisinde yeni bir seçenek olarak migren botoksu ortaya çıkmıştır. Migren botoksu, migren ataklarını sıklık, şiddet ve süre açısından azaltmaya yardımcı olan bir tedavi yöntemidir. Botulinum toksini, migrenin sebep olduğu kas spazmlarını rahatlatarak migren semptomlarını hafifletmeye yardımcı olur.
        </p>

        <div className="gap-x-10 pt-8 md:grid grid-cols-2">
          <div className="operations-image migren-img1"></div>
          <div>
            <h1 className="operations-title2">
            Migren Botoksu Nasıl Yapılır?
            </h1>
            <p className="operations-text">
            Migren botoksu, uzman bir doktor tarafından yapılmalıdır. İşlem sırasında, botulinum toksini ilgili kasların belirli noktalarına enjekte edilir. Bu enjeksiyonlar, migren aurasının başladığı bölgelerde veya migren atağına neden olan kaslara yönlendirilebilir. Migren atağına katkıda bulunan baş, boyun, omuz ve yüz kasları gibi bölgelere botulinum toksini enjeksiyonu yapılır.
            <br /><br />
            İşlem, genellikle lokal anestezi altında ve birkaç dakika süren bir süre zarfında tamamlanır. İşlem sonrasında hastalar aynı gün taburcu edilebilir ve günlük aktivitelerine hemen dönebilir. 
            </p>
          </div>
        </div>
        <div className="gap-x-10 pt-8 md:grid grid-cols-2">         
          <div>
            <h1 className="operations-title2">
            Migren Botoksu Hangi Bölgelere Yapılır?
            </h1>
            <p className="operations-text">
            Migren botoksu genellikle alın, şakaklar, kaşlar, boyun ve omuz kasları gibi bölgelere yapılabilir. Hangi bölgelere uygulanacağı hastaya özgü belirlenir ve hastanın semptomlarına ve migren atağının neden olduğu kaslara bağlı olarak değişebilir. İşlem, kişiselleştirilmiş bir tedavi planına dayanmalıdır ve etkili bir sonuç almak için önceden bir değerlendirme yapılmalıdır.
            </p>
          </div>
          <div className="operations-image migren-img2"></div>
        </div>

        <h1 className="operations-title1" >
        Migren Botoksu Hazırlık Süreci
        </h1>
        <p className="operations-text">
        Migren botoksu uygulaması öncesi hastanın tıbbi geçmişi ve mevcut ilaç kullanımı gözden geçirilir. Migren semptomları ve ataklarının özellikleri değerlendirilir ve hastaya işlem, beklentiler ve olası yan etkiler hakkında ayrıntılı bilgilendirme yapılır. Ayrıca, varsa migren atağını tetikleyebilecek faktörlerin ortadan kaldırılması veya yönetilmesi için önerilerde bulunulur.
        <br /><br />        
        İşlem öncesi, hastaların kesinlikle sigara içmemesi ve aşırı miktarda alkol almaması gerekmektedir. Ayrıca, aspirin veya antikoagülanlar gibi kanı sulandıran ilaçların, işlem öncesinde kesilmesi gerekebilir. Bu nedenle, doktorun talimatlarına uyulmalı ve gerekli hazırlıklar eksiksiz bir şekilde tamamlanmalıdır.
        </p>

        <h1 className="operations-title1" >
        Migren Botoksu Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        <p className="operations-text">
        Migren botoksu işlemi sonrasında dikkat edilmesi gereken bazı noktalar vardır. İşlem sonrasında, hareketlerde aşırı zorlama, sıcak ve nemli ortamlardan kaçınılmalıdır. Aşırı fiziksel aktivite, egzersiz veya masajdan kaçınılmalıdır. Ayrıca, işlem sonrası oluşabilecek yan etkiler gözlemlenmeli ve gerektiğinde doktor ile iletişime geçilmelidir.
        </p>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="migren-soru1">
              Migren botoksunun zararları var mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="migren-soru1">
              Migren botoksu genellikle güvenli bir tedavi yöntemi olsa da nadir durumlarda bazı yan etkiler görülebilir. Bu yan etkiler arasında baş ağrısı, enjeksiyon bölgesinde geçici ağrı veya morarma, kaş düşmesi ve göz kuruluğu gibi durumlar yer alabilir. 
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="migren-soru2">
              Migren botoksunun yan etkileri nelerdir?
              </AccordionHeader>
              <AccordionBody accordionId="migren-soru2">
              Migren botoksu uygulaması sonrasında görülebilecek potansiyel yan etkiler arasında enjeksiyon bölgesinde ağrı, morarma veya kaş düşmesi, baş ağrısı, göz kuruluğu, halsizlik gibi geçici durumlar bulunabilir. Ancak bu yan etkiler genellikle hafif ve geçicidir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="migren-soru3">
              Migren botoksu işe yarar mı?
              </AccordionHeader>
              <AccordionBody accordionId="migren-soru3">
              Migren botoksu, migren atağının sıklığını, şiddetini ve süresini azaltmaya yardımcı olabilir. Birçok hasta için etkili bir tedavi yöntemi olmakla birlikte, sonuçlar kişiden kişiye değişebilir. Uzman bir doktor tarafından doğru şekilde uygulandığında migren botoksu olumlu sonuçlar sunar.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="migren-soru4">
              Migren botoksu ile normal botoks arasındaki fark nedir?
              </AccordionHeader>
              <AccordionBody accordionId="migren-soru4">
              Migren botoksu ve normal botoks (estetik botoks) arasındaki temel fark, uygulama alanlarıdır. Migren botoksu, migren semptomlarını tedavi etmek için baş, boyun, omuz ve yüz bölgelerine yapılan botulinum toksin enjeksiyonlarıdır. Normal botoks ise genellikle kırışıklıkların giderilmesi veya estetik amaçlarla yüz bölgesine uygulanan botulinum toksin enjeksiyonlarıdır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="migren-soru5">
              Migren botoksunun etkisi ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="migren-soru5">
              Migren botoksu genellikle 3 ila 6 ay arasında etkili olabilir. Ancak bu süre kişiden kişiye değişebilir ve bireysel olarak değerlendirilmelidir. Bazı hastalar için etkiler daha uzun sürebilirken, bazılarında etkiler daha kısa sürebilir. Tedavi planı genellikle hastanın migren şiddetine ve sıklığına göre belirlenir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>

        <div id="selülit-tedavi"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
        Selülit ve Bölgesel Yağlanma Tedavisi
        </h1>
        <p className="operations-text">
        Selülit ve bölgesel yağlanma tedavisi, cilt altındaki yağ birikimini azaltarak veya cildin görünümünü iyileştirerek kozmetik endişeleri hafifletmeyi amaçlar. Bu tedaviler, genellikle klinik ortamlarda uzmanlar tarafından uygulanır ve bir dizi farklı teknik ve prosedür içerebilir.
        </p>
        <h1 className="operations-title1" >
        Selülit ve Bölgesel Yağlanma Nasıl Yapılır?
        </h1>
        <div className="md:grid gap-x-10 pt-2 grid-cols-2">
          <div className="operations-image selülit-img1">
          </div>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="selülit1">
                Lazer Tedavisi 
                </AccordionHeader>
                <AccordionBody accordionId="selülit1">
                Lazer tedavisi, cilt altındaki yağ dokusunu ısıtarak parçalayan ve kolajen üretimini artıran bir tekniktir. Bu, cildin daha sıkı ve pürüzsüz bir görünüm kazanmasına yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit2">
                Radyofrekans
                </AccordionHeader>
                <AccordionBody accordionId="selülit2">
                Radyofrekans, cilt altındaki yağ hücrelerini ısıtarak parçalayan ve cildin sıkılaşmasını sağlayan bir tekniktir. Bu prosedür, selülitlerin görünümününazaltılmasına ve cildin daha estetik görünmesine yardımcı olur..
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit3">
                Vakum Terapisi 
                </AccordionHeader>
                <AccordionBody accordionId="selülit3">
                Vakum terapisi, cilt altındaki kan dolaşımını artırarak ve lenfatik drenajı teşvik ederek selülitin görünümünü azaltmaya yardımcı olabilir. Bu yöntem genellikle bir masaj terapisti tarafından uygulanır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit4">
                Liposuction 
                </AccordionHeader>
                <AccordionBody accordionId="selülit4">
                Liposuction, belirli bölgelerdeki aşırı yağ birikimini azaltmak için cerrahi olarak yağ emme işlemidir. Bu prosedür genellikle daha belirgin bölgesel yağlanma için tercih edilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit5">
                Kriyolipoliz
                </AccordionHeader>
                <AccordionBody accordionId="selülit5">
                Kriyolipoliz, yağ hücrelerini dondurarak parçalayan bir yöntemdir. Bu işlem genellikle belirli bölgelerdeki inatçı yağların azaltılması için kullanılır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <h1 className="operations-title1" >
        En Etkili Selülit ve Bölgesel Yağlanma Tedavisi
        </h1>
        <p className="operations-text">
        En etkili selülit ve bölgesel yağlanma tedavisi, kişinin cilt tipi, yaşam tarzı, genetik faktörler ve tedaviye verilen yanıta bağlı olarak değişebilir. Ancak, genellikle bir kombinasyon tedavi yaklaşımı en etkili sonuçları sağlar. Örneğin, lazer veya radyofrekans tedavisi selülitin görünümünü azaltırken, liposuction belirli bölgelerdeki inatçı yağ birikimini giderir.
        </p>
        <h1 className="operations-title1">
        Selülit ve Bölgesel Yağlanma Tedavisi Hangi Bölgelere Uygulanır?
        </h1>
        <div className="md:grid gap-x-10 pt-2 grid-cols-2">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Bacaklar (özellikle üst iç bacaklar) </b>
              </li>
              <li className="mt-2">
                <b className="operations-title3">Kalçalar</b>
              </li>
              <li className="mt-2">
                <b className="operations-title3">Karın bölgesi</b>
              </li>
              <li className="mt-2">
                <b className="operations-title3">Bel çevresi</b>
              </li>
              <li className="mt-2">
                <b className="operations-title3">Kol içleri</b>
              </li>
              <li className="mt-2">
                <b className="operations-title3">Sırt</b>
              </li>
            </p>
          </div>
          <div className="operations-image selülit-img2"></div>
        </div>
        <h1 className="operations-title1" >
        Selülit ve Bölgesel Yağlanma Tedavi Öncesi Hazırlık Süreci
        </h1>
        <p className="operations-text">
        Selülit ve bölgesel yağlanma tedavi öncesi, bir danışma randevusu ayarlamak ve uzman bir doktor veya dermatolog ile görüşmek önemlidir. Bu randevu sırasında, doktor tedavi seçenekleri hakkında bilgi verecek, beklenilen sonuçları tartışacak ve muhtemel riskleri ve yan etkileri açıklayacaktır. 
        </p>

        <h1 className="operations-title1">
        Selülit ve Bölgesel Yağlanma Tedavisi Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        <div className="md:grid gap-x-10 pt-2 grid-cols-2">
          <div className="operations-image selülit-img3">
          </div>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="selülit6">
                Dinlenme 
                </AccordionHeader>
                <AccordionBody accordionId="selülit6">
                İşlem sonrası dinlenmek ve ağır fiziksel aktivitelerden kaçınmak önemlidir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit7">
                Doktorun Talimatlarına Uyulması
                </AccordionHeader>
                <AccordionBody accordionId="selülit7">
                İyileşme sürecini desteklemek için doktorun önerdiği talimatlar dikkatlice izlenmeli ve belirtilen takip randevuları kaçırılmamalıdır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit8">
                Yan Etkileri Bildirme
                </AccordionHeader>
                <AccordionBody accordionId="selülit8">
                İşlem sonrası herhangi bir anormal semptom veya yan etki fark edilirse, derhal ilgili doktora başvurulmalıdır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="selülit9">
                Sağlıklı Yaşam Tarzı
                </AccordionHeader>
                <AccordionBody accordionId="selülit9">
                Tedavi sonrasında sağlıklı bir yaşam tarzı sürdürmek, elde edilen sonuçların uzun süreli olmasına yardımcı olabilir. Bu, düzenli egzersiz yapmak, sağlıklı beslenmek ve sigara içmemek gibi yaşam tarzı önerilerini içerir. 
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        

        <div id="gıdı-tedavi"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
        Gıdı Eritme
        </h1>
        <p className="operations-text">
        Gıdı eritme, genellikle çene altında bulunan ve sarkan yağ dokusunun azaltılması veya şekillendirilmesi için yapılan kozmetik bir müdahaledir. Bu, boyun ve çene bölgesindeki yağ tabakasını incelterek, daha sıkı ve genç bir görünüm elde etmeyi amaçlar.{" "}
        </p>
        <h1 className="operations-title1" >
        Ameliyatsız Gıdı Eritme Nedir?
        </h1>
        <p className="operations-text">
        Ameliyatsız gıdı eritme, cerrahi olmayan yöntemlerle gıdı bölgesindeki yağ dokusunun azaltılmasını sağlayan bir prosedürdür. Bu yöntemler arasında lipoliz enjeksiyonları, radyofrekans, ultrason ve kriyolipoliz gibi teknikler bulunur. Bu teknikler, yağ hücrelerini hedef alarak onları parçalar veya güçlü soğuk ya da ısı enerjisi ile nüfuz ederek vücut tarafından emilimini sağlar.{" "}
        </p>
        <h1 className="operations-title1" >
        Ameliyatsız Gıdı Eritme Nasıl Yapılır?
        </h1>
        <div className="md:grid gap-x-10 pt-2 grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="gıdı1">
                Lipoliz Enjeksiyonları
                </AccordionHeader>
                <AccordionBody accordionId="gıdı1">
                Lipoliz enjeksiyonları, yağ hücrelerini parçalayan bir solüsyonun gıdı bölgesine enjekte edilmesini içerir. Bu enjeksiyonlar genellikle lokal anestezi altında yapılır ve prosedür yaklaşık 30 dakika sürer.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gıdı2">
                Radyofrekans  
                </AccordionHeader>
                <AccordionBody accordionId="gıdı2">
                Radyofrekans, cilt altındaki yağ dokusunu ısıtarak yağ hücrelerinin parçalanmasını sağlar. Isı, cilt altındaki kolajen üretimini artırarak cildin sıkılaşmasına yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gıdı3">
                Ultrason
                </AccordionHeader>
                <AccordionBody accordionId="gıdı3">
                Ultrason enerjisi, yağ hücrelerini parçalayarak vücut tarafından emilimini sağlar. Bu işlem, cilt yüzeyinden ultrason dalgaları gönderilerek yapılır ve genellikle acısızdır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gıdı4">
                Kriyolipoliz  
                </AccordionHeader>
                <AccordionBody accordionId="gıdı4">
                Kriyolipoliz, yağ hücrelerini dondurarak parçalayan bir yöntemdir. Bu işlem, cilt üzerine özel bir aplikatör yerleştirilerek uygulanır ve genellikle bölgesel uyuşma hissi dışında ağrıya neden olmaz.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="operations-image gıdı-img1">
          </div>
        </div>
        <h1 className="operations-title1">
        Ameliyatsız Gıdı Eritmenin Avantajları Nelerdir?
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Minimum Risk: </b>
                <br />Cerrahi müdahaleye göre daha az invaziv olduğu için genellikle daha az risk taşır.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Daha Hızlı İyileşme Süresi: </b>
                <br />Cerrahi işlemlere göre daha az ağrı ve rahatsızlık hissi sunar.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Daha Az Rahatsızlık:  </b> <br /> Kök hücreler organ naklinin başarı
                oranlarını artırmak için kullanılabilir. Hasarlı organların ve
                dokuların yenilenmesine ve onarılmasına yardımcı olarak
                reddedilme riskini azaltabilirler.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Doğal Görünüm: </b> <br /> Doğru şekilde uygulandığında, ameliyatsız gıdı eritme doğal ve dengeli bir görünüm sağlar.
              </li>
            </p>
          </div>
        </div>
        <h1 className="operations-title1" >
        Ameliyatsız Gıdı Eritme Öncesi: Hazırlık Süreci
        </h1>
        <p className="operations-text">
        Ameliyatsız gıdı eritme öncesi, bir danışma randevusu ayarlamak ve doktor ile prosedür hakkında detaylı bir şekilde konuşmak önemlidir. Doktor prosedürün nasıl uygulanacağını ve ne tür sonuçlar beklenebileceğini açıklayacaktır. İşlem öncesi doktorun önerdiği talimatların dikkatlice izlenmesi, çekince ve soruların paylaşılması önemlidir. 
        </p>
        <h1 className="operations-title1">Ameliyatsız Gıdı Eritme Sonrası Dikkat Edilmesi Gerekenler</h1>

        <div className="md:grid pt-2 gap-x-10 grid-cols-2">

          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="gıdı5">
                Dinlenme
                </AccordionHeader>
                <AccordionBody accordionId="gıdı5">
                İşlem sonrası dinlenmek ve ağır fiziksel aktivitelerden kaçınmak önemlidir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gıdı6">
               Soğuk Kompres Uygulaması
                </AccordionHeader>
                <AccordionBody accordionId="gıdı6">
                İşlem sonrası oluşabilecek hafif şişlik ve kızarıklığı azaltmak için soğuk kompres uygulanabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gıdı7">
                Makyaj ve Cilt Bakımı
                </AccordionHeader>
                <AccordionBody accordionId="gıdı7">
                İşlem sonrası birkaç gün boyunca makyaj yapmaktan ve cildi tahriş edebilecek ürünler kullanmaktan kaçınmak gerekir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gıdı8">
                Doktorun Talimatlarına Uyulması
                </AccordionHeader>
                <AccordionBody accordionId="gıdı8">
                İyileşme sürecini desteklemek için doktorun önerdiği talimatlar dikkatlice izlenmeli ve belirtilen takip randevuları kaçırılmamalıdır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="operations-image gıdı-img2">
          </div>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="gıdı-soru1">
              Ameliyatsız gıdı eritme kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="gıdı-soru1">
              Ameliyatsız gıdı eritme sonuçları genellikle uzun süreli olabilir, ancak bunlar kalıcı değildir. Yağ hücrelerinin inceltilmesi veya yok edilmesi, daha sıkı bir görünüm sağlar, ancak yaşlanma süreci ve yaşam tarzı faktörleri nedeniyle zamanla yağ birikimi yeniden oluşabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gıdı-soru2">
              Ameliyatsız gıdı eritme ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="gıdı-soru2">
              Ameliyatsız gıdı eritme işlemi genellikle 30 dakika ile 1 saat arasında sürer. Ancak süre, kullanılan tekniğe, uygulama alanına ve kişinin spesifik ihtiyaçlarına bağlı olarak değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gıdı-soru3">
              Ameliyatsız gıdı eritme acıtır mı?
              </AccordionHeader>
              <AccordionBody accordionId="gıdı-soru3">
              Ameliyatsız gıdı eritme işlemi genellikle hafif rahatsızlık hissiyle ilişkilendirilir. Ancak çoğu doktor, işlem sırasında ağrıyı minimuma indirmek için lokal anestezi veya anestezik krem kullanır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gıdı-soru4">
              Ameliyatsız gıdı eritme kaç seans sürer?
              </AccordionHeader>
              <AccordionBody accordionId="gıdı-soru4">
              Ameliyatsız gıdı eritme genellikle 1-3 seans arasında gerçekleştirilir. Seans sayısı, gıdıdaki yağ birikiminin büyüklüğüne, kişinin hedeflerine ve uygulanan yönteme bağlı olarak değişebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>

        

        
      </div>
    </div>
  );
};

export default BodyOperations;
