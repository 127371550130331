import "./footer.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const sections = [
  {
    items: [
      { name: "ANASAYFA", path: "/" },
      { name: "HİKAYEMİZ", path: "/about" },
      { name: "İLETİŞİM", path: "/contact" },
    ],
  },
  {
    items: [
      { name: "BLOG", path: "/blog" },
      { name: "HİZMETLERİMİZ", path: "/genclesme" },
    ],
  },
];

const items = [
  {
    name: "Facebook",
    icon: FaFacebook,
    link: "/",
  },
  {
    name: "Instagram",
    icon: FaInstagram,
    link: "https://www.instagram.com/genesis_istanbul/",
  },
  {
    name: "Youtube",
    icon: FaYoutube,
    link: "/",
  },
];

const SectionLinks = ({ items }) => {
  return (
    <div>
      <ul>
        {items.map((item, i) => (
          <li
            key={i}
            className="py-1 text-black-300 tracking-wider hover:text-darkGreen cursor-pointer nav-items"
          >
            <Link to={item.path}>{item.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="w-full mt-24 text-black-300 py-y px-2 footer-container">
      <div className="flex items-center max-w-[200px] footer-mobile-logo">
        <Link className="py-1 mob-logo" to="/">
          <img src="./genesis-nav-logo.png" alt="" />
        </Link>
      </div>
      <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-5 border-b-2 border-gray-600 pb-8 px-1 footer-content">
        <div className="flex items-center max-w-[200px] footer-laptop">
          <Link className="py-1" to="/">
            <img src="./genesis-nav-logo.png" alt="" />
          </Link>
        </div>
        {sections.map((section, index) => (
          <SectionLinks key={index} items={section.items} />
        ))}
        <div className="col-span-2 pt-8 md:pt-2">
          <p className="font-bold ques">SORU VE ÖNERİLERİNİZ İÇİN</p>
          <form className="flex flex-col sm:flex-row">
            <input
              type="email"
              placeholder="E-mail adresinizi yazınız..."
              className="w-full p-2 mr-4 rounded-md md-4 heroku"
            />
            <button className="footer-btn hover:text-white text-start butone">
              <span className="border">Gönder</span>
            </button>
          </form>
        </div>
      </div>
      {/* Copyright & Social Icons */}
      <div className="flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-cray-500">
        <p className="py-4 tracking-wider">
          GENESIS HEALTH © 2024 - Tüm hakları saklıdır.
        </p>
        <div className="flex justify-between sm:w-[176px] pt-4 text-3xl">
          {items.map((x, index) => (
            <a
              href={x.link}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <x.icon className="hover:text-darkGreen cursor-pointer" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
