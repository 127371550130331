import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Bgc from "../../components/blogConst/bgc";
import Bb from "../../components/blogBody/bb";

const Bloger = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Bgc />
      <Bb />
      {/* <Bb /> */}
    </div>
  );
};

export default Bloger;
