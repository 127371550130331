import React, { useEffect, useState } from "react";
import Phoneix from "./aboutImages/genessis-logo.png";
import "./About.css";
import "tailwindcss/tailwind.css";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const About = () => {
  const [story, setStory] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [loading, setLoading] = useState(true); // Yükleme durumu için state ekledik

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/stories`
        );
        setStory(response.data[0]); // Assuming you have only one story object
        setLoading(false); // Yükleme tamamlandığında loading state'ini false yap
      } catch (error) {
        console.error("Error fetching story data", error);
        setLoading(false); // Hata durumunda loading state'ini false yap
      }
    };

    fetchStory();
  }, []);

  if (loading) {
    return (
      <div className="loading-spinner">
        <p>Yükleniyor...</p>
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }


  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = story.description || "Açıklama bulunamadı.";

  return (
    <div id="about main">
      <div
        id="aboutHeader"
        className="flex flex-col-reverse md:grid md:grid-cols-2 bg-[#f5f5f5] min-h-[500px]"
      >
        <div className="pl-5 pr-5 py-28 md:pl-48">
          <h1 className="header-title">Genesis Health</h1>
          <p className="header-text">
            <p>
              {showFullDescription
                ? story.description
                : `${description.slice(0, 302)}...`}
            </p>
            <button
              onClick={toggleDescription}
              className="border-2 border-[#142d45] hover:bg-[#142d45] text-[#142d45] hover:text-white font-bold py-2 mt-3 px-2 rounded-xl"
            >
              {showFullDescription ? "Daha Az Göster" : "Devamını Oku"}
            </button>
          </p>
        </div>
        <div className="flex justify-center items-center">
          <img
            src={Phoneix}
            alt="phoneix"
            className="about-logo md:w-[385px] w-[200px] md:h-[300px] h-[200px] object-cover"
          />
        </div>
      </div>
      <section className="aboutLinks flex flex-col md:flex-row justify-center items-start md:items-center mt-8">
        <nav className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <a
            href="#aboutBody"
            className="bottomLine font-bold py-2 pr-4 pl-1 rounded  hover:text-[#8998eb]"
          >
            <i class="fa-solid fa-circle-arrow-right"></i> &nbsp;Biz Kimiz
          </a>
          <a
            href="#missionVision"
            className=" bottomLine font-bold py-2 pr-4 pl-1 rounded  hover:text-[#8998eb]"
          >
            <i className="fa-solid fa-circle-arrow-right"></i> &nbsp;Misyonumuz
            & Vizyonumuz
          </a>
          <a
            href="#journey"
            className="bottomLine font-bold py-2 pr-4 pl-1 rounded  hover:text-[#8998eb]"
          >
            <i class="fa-solid fa-circle-arrow-right"></i> &nbsp;Genesis
            Yolculuğu
          </a>
          <a
            href="#meetUs"
            className="bottomLine font-bold py-2 pr-4 pl-1 rounded  hover:text-[#8998eb]"
          >
            <i class="fa-solid fa-circle-arrow-right"></i> &nbsp;Bizimle Tanışın
          </a>
        </nav>
      </section>
      <div id="aboutBody " className="aboutBody">
        <h1 className="about-title">Biz Kimiz</h1>
        <p className="about-text">{story.whoWeAre}</p>
        <div className="aboutContent gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 md:grid-cols-2">
          <div className="about-img1 md:mb-3">
            <img
              src={story.image1}
              alt=""
              className="phoneixImg md:w-[100%] w-[100%] md:h-[100%] h-[100%] object-contain image1"
            />
          </div>
          <div>
            <h1 className="about-title spaceTitle">Ekibimiz</h1>
            <p className="about-text">{story.ourTeam}</p>
            <h1 className="about-title pt-8">Bizim Hikayemiz</h1>
            <p className="about-text">{story.ourStory}</p>
          </div>
        </div>
        <h1 id="missionVision" className="about-title pt-8">
          Misyonumuz & Vizyonumuz
        </h1>
        <p className="about-text ">{story.missionAndVision}</p>
        <div className=" aboutContentScd gap-x-10 gap-y-5 pt-8 grid grid-cols-3">
          <div className=" col-span-2">
            <h1 id="journey" className="about-title spaceTitle">
              Genesis Yolculuğu
            </h1>
            <p className="about-text">{story.genesisJourney}</p>
            <h1 id="meetUs" className="about-title pt-8">
              Bizimle Tanışın
            </h1>
            <p className="about-text">{story.meetUs}</p>
          </div>
          <div className="about-img2">
            <img
              src={story.image2}
              alt=""
              className="phoneixImg md:w-[100%] w-[200px] md:h-[84%] h-[84%] object-contain image2"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
