import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./AnimatedText.css";

const AnimatedText = () => {
  const texts = [
    "Gençleşme",
    "Genetik Tedavi",
    "Eksozom Terapi",
    "Ozon Terapisi",
    "Kök Hücre Tedavisi",
  ];

  const swiperRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiperRef.current) {
        swiperRef.current.slideNext();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="animated-text-container">
      <div className="animatex">
        <span className="static-text">İleri Teknoloji</span> <br />
        <Swiper
          direction="vertical"
          loop={true}
          speed={1800}
          className="animated-text"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {texts.map((text, index) => (
            <SwiperSlide key={index}>
              <div className="animated-word">{text}</div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="sub-text">
        <p>Yenilenme Yolculuğunuzu Size Özel Çözümlerle Zirveye Taşıyoruz</p>
      </div>
    </div>
  );
};

export default AnimatedText;
