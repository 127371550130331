import React, { useEffect, useRef, useState } from "react";
import "./contact.css";
import shape from "./img/shape.png";
import axios from "axios";
import toast from "react-hot-toast";

const Contact = () => {
  const inputRefs = useRef([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData);
      if (response.status === 200) {
        toast.success(
          "Mesajınız başarıyla gönderildi! En kısa sürede size dönüş sağlayacağız."
        );
        // Formu temizle
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        toast.error(
          "Mesaj gönderilirken bir hata oluştu: Tüm alanların dolu olduğundan emin olunuz!"
        );
      }
    } catch (error) {
      toast.error(
        "Mesaj gönderilirken bir hata oluştu: Tüm alanların dolu olduğundan emin olunuz!"
      );
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const inputs = inputRefs.current;

    const focusFunc = (e) => {
      let parent = e.target.parentNode;
      parent.classList.add("focus");
    };

    const blurFunc = (e) => {
      let parent = e.target.parentNode;
      if (e.target.value === "") {
        parent.classList.remove("focus");
      }
    };

    inputs.forEach((input) => {
      if (input) {
        input.addEventListener("focus", focusFunc);
        input.addEventListener("blur", blurFunc);
      }
    });

    return () => {
      inputs.forEach((input) => {
        if (input) {
          input.removeEventListener("focus", focusFunc);
          input.removeEventListener("blur", blurFunc);
        }
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <span className="big-circle"></span>
        <img src={shape} className="square" alt="" />
        <div className="form">
          <div className="contact-info">
            <h3 className="contact-title">
              Güzelliğinizi Birlikte Dönüştürelim
            </h3>
            <p className="text">
              Genessis olarak, gerçek güzelliğin içten geldiğine ve uzman bakım
              ile parlatılabileceğine inanıyoruz. Küçük dokunuşlardan büyük
              değişimlere kadar, deneyimli ekibimiz size rehberlik etmek için
              burada.
            </p>

            <div className="info">
              <div className="information">
                <i className="fa-solid fa-map-location contact-icon"></i>
                <p>
                  Harbiye Mh Abdi Ipekci Cd No:2 Ziya Karahan Apt. Daire:5 Kat:1{" "}
                  <br /> Nisantasi - İstanbul
                </p>
              </div>
              <div className="information">
                <a href="tel:+905322191020" className="contact-link d-flex">
                  <i className="fa-solid fa-mobile contact-icon"></i>
                  <p>+90-532-219-10-20</p>
                </a>
              </div>
              <div className="information">
                <i className="fa-solid fa-envelope contact-icon"></i>
                <p>info@genesishealthistanbul.com</p>
              </div>
            </div>

            <div className="social-media">
              <p>Bağlantıda kal:</p>
              <div className="social-icons">
                <a
                  href="https://www.instagram.com/genesis_istanbul/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form
              className="form-container"
              action="index.html"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <h3 className="contact-title">Bizimle iletişime geçin</h3>
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  ref={(el) => (inputRefs.current[0] = el)}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="">İsim Soyisim</label>
                <span>İsim Soyisim</span>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  ref={(el) => (inputRefs.current[1] = el)}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="">Email</label>
                <span>Email</span>
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  ref={(el) => (inputRefs.current[2] = el)}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="">Telefon</label>
                <span>Telefon</span>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input"
                  ref={(el) => (inputRefs.current[3] = el)}
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                <label htmlFor="">Mesajınız</label>
                <span>Mesajınız</span>
              </div>
              <input type="submit" value="Gönder" className="btn" />
            </form>
          </div>
        </div>
      </div>
      <section className="map-section">
        <div className="map-container text-center map-container">
          <div className="locaTitleContainer">
            <i className="fa-solid fa-location-dot contact-icon"></i>
            <h3 className="map-title">Bizi Ziyaret Edin</h3>
          </div>

          <iframe
            width="100%"
            height="400px"
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.837900215868!2d28.98856157551614!3d41.05067381681403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab70dd2bb25b9%3A0x1254dbcf6a833501!2zSGFyYml5ZSwgQWJkaSDEsHBla8OnaSBDZC4gTm86MiwgMzQzNjcgxZ5pxZ9saS_EsHN0YW5idWw!5e0!3m2!1str!2str!4v1716811417295!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            title="Map Location"
            className="map"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Contact;
