import React, { useContext, useState } from "react";
import { SearchContext } from "./SearchContext";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "./searchBar.css";

//ToDo categories will be added later to API guys :)

const categories = [
  { name: "Genetik Test", route: "/genetik" },
  { name: "Ozon Terapi", route: "/ozon" },
  { name: "Multipass Ozon Terapi", route: "/ozon" },
  { name: "IV Terapi", route: "/iv" },
  { name: "Kök Hücre Tedavisi", route: "/kokhucre" },
  {
    name: "Yağ Dokusundan Kök Hücre Tedavisi",
    route: "/kokhucre#yagdokusu-tedavi",
  },
  { name: "Eksozom Tedavisi", route: "/eksozom" },
  { name: "Longevity (Uzun Yaşam)", route: "/genclesme" },
  {
    name: "Kordon Kanı ve Plasenta ile Gençleşme Tedavisi",
    route: "/genclesme#genclesme-kordon",
  },
  {
    name: "Sünnet Derisinden Gençleşme Tedavisi",
    route: "/genclesme#genclesme-sunnet",
  },
  {
    name: "Fibroblast ile Gençleşme Tedavisi",
    route: "/genclesme#genclesme-fibroblast",
  },
  { name: "Kriyoterapi", route: "/genclesme#kriyoterapi-nedir" },
  {
    name: "Kırmızı Işık Terapisi (Red Light Therapy)",
    route: "/genclesme#kirmizi-isik-terapisi",
  },
  {
    name: "Hiperbarik Oksijen Tedavisi",
    route: "/genclesme#hiperbarik-oksijen-tedavisi",
  },
  //skin
  { name: "Cilt", route: "/skin" },
  { name: "İp Askı (Fransız Askı)", route: "/skin#ip-aski" },
  { name: "El Mezoterapisi", route: "/skin#el-mezoterapisi" },
  { name: "El Dolgusu", route: "/skin#el-dolgusu" },
  { name: "Kimyasal Peeling", route: "/skin#kimyasal-peeling" },
  //hair
  { name: "Saç", route: "/hair" },
  { name: "Saç Mezoterapisi", route: "/hair#sac-mezoterapisi" },
  { name: "Saç Botoksu", route: "/hair#sac-botoksu" },
  { name: "Saç Dolgusu", route: "/hair#sac-dolgusu" },
  //body
  { name: "Vücut", route: "/body" },
  { name: "Terleme Botoksu", route: "/body#terleme-botoksu" },
  { name: "Migren Botoksu", route: "/body#migren-botoksu" },
  {
    name: "Selülit ve Bölgesel Yağlanma Tedavisi",
    route: "/body#selülit-tedavi",
  },
  { name: "Gıdı Eritme", route: "/body#gıdı-tedavi" },
  //face
  { name: "Yüz", route: "/face" },
  { name: "BotoksBotoks ile Kırışıklık Tedavisi  ", route: "/face#botokspin" },
  { name: "Dudak Dolgusu", route: "/face#dolgupin" },
  { name: "Alın Dolgusu", route: "/face" },
  { name: "Yanak İnceltme", route: "/face#yanakpin" },

  //Blog
  { name: "Blog", route: "/blog" },
  { name: "Güneşin Cilt Sağlığı Üzerindeki Etkileri", route: "/blog" },
  { name: "Akne İle Mücadele Yöntemleri", route: "/blog" },
  { name: "İyi Uykunun Beden Sağlığına Faydaları", route: "/blog" },
];

const SearchBar = () => {
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    if (newSearchTerm) {
      const fetchedResults = await fetchSearchResults(newSearchTerm);
      setResults(fetchedResults);
    } else {
      setResults([]);
    }
  };

  const handleResultClick = (route) => {
    setSearchTerm("");
    setResults([]);
    navigate(route);

    // Scroll to the element if hash is present in the route
    if (route.includes("#")) {
      const [path, hash] = route.split("#");
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100); // Delay to ensure the element is available in the DOM
    }
  };

  //press enter key to search for first result guys :)
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && results.length > 0) {
      e.preventDefault();
      handleResultClick(results[0].route);
    }
  };

  return (
    <>
      <form className="w-[300px] relative searchForm">
        <div className="relative">
          <input
            type="search"
            placeholder="Arama Yap.."
            className="w-full p-2 rounded-full bg-slate-800"
            value={searchTerm}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          <button
            type="button"
            className="absolute right-1 top-1/2 -translate-y-1/2 p-4 bg-slate-600 rounded-full text-xl searchBtn"
          >
            <AiOutlineSearch />
          </button>
        </div>
        {results.length > 0 && (
          <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg shadow-lg mt-1 max-h-60 overflow-y-auto dropdown">
            <ul>
              {results.map((result, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleResultClick(result.route)}
                >
                  {result.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </form>
    </>
  );
};

const fetchSearchResults = async (searchTerm) => {
  return categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
};

export default SearchBar;
