import '../layout.css';
import { useState } from "react";
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from "reactstrap";
import ContactCard from '../contactCard/contactCard';

const Genclesme = () => {

    const [open, setOpen] = useState("1");
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <div id="services-body">
            <div className="md:grid grid-cols-2 services-header">
                <div className="services-responsive-header genclesme-header-image"></div>
                <div className="services-header-text">
                    <h1 className="services-header-title">Longevity ve Rejuvenation Tedavisi</h1>
                    <h3 className="services-header-title2">
                        
                        <a className="services-header-link" href="#genclesme-kordon">
                            <div className='services-header-link-div'>
                                <div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div>
                                <div> Kordon Kanı ve Plasenta ile Gençleşme Tedavisi</div>
                            </div></a>
                        <a className="services-header-link" href="#genclesme-sunnet"><div className='services-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div> Sünnet Derisinden Gençleşme Tedavisi</div></div></a>
                        <a className="services-header-link" href="#genclesme-fibroblast"><div className='services-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div> Fibroblast ile Gençleşme Tedavisi</div></div></a>
                        <a className="services-header-link" href="#kriyoterapi-nedir"><div className='services-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Kriyoterapi</div></div></a>
                        <a className="services-header-link" href="#hiperbarik-oksijen-tedavisi"><div className='services-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Hiperbarik Oksijen Tedavisi</div></div></a>
                        <a className="services-header-link" href="#kirmizi-isik-terapisi"><div className='services-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Kırmızı Işık Terapisi</div></div></a>


                    </h3>
                </div>
                <div className="genclesme-header-image services-header-image"></div>
            </div>



            <div className='services-content'>

            <h1 className="services-main-title" >Longevity ve Rejuvenation Tedavisi</h1>
            <p className="services-text">Longevity (Uzun Yaşam) ve Rejuvenation (Gençleşme) tedavisi, yaşlanmanın etkilerini tersine çevirmeyi ve daha genç ve sağlıklı bir görünümü teşvik etmeyi amaçlayan bir tıbbi yaklaşımdır. Son yıllarda, kordon kanı ve plasental dokunun gençleşme tedavilerinde kullanım potansiyeline artan bir ilgi gözlemlenmektedir.</p>

            <div id="genclesme-kordon"></div>
            <hr className='services-hr'></hr>

                <h1 className="services-main-title" >Kordon Kanı ve Plasenta ile Gençleşme Tedavisi</h1>
                <p className="services-text">Doğumdan sonra göbek bağından elde edilen kordon kanı ve gelişmekte olan fetusu besleyen organ olan plasenta, yenileyici özelliklere sahip kök hücreler ve diğer biyoaktif moleküllerin zengin kaynaklarıdır.</p>

                <h1 className="services-title1">Kordon Kanı ve Plasentanın Avantajları Nelerdir?</h1>

                <p className="services-text">Kordon kanı ve plasenta, yenileyici tıpta kullanımı için değerli kaynaklardır. Kordon kanında bulunan hematopoetik kök hücreler (HSC'ler), çeşitli kan hücrelerine dönüşme yeteneğine sahipken, mezenkimal kök hücreler (MSC'ler) de kemik, kıkırdak ve yağ hücrelerine dönüşebilirler. Bu kök hücreler, hasar görmüş dokuların onarımına yardımcı olabilir, büyüme faktörlerinin üretimini teşvik edebilir ve vücudun kendini yenileme sürecini uyarabilir.</p>

                <div className="md:grid gap-x-10 pt-2 grid-cols-2">

                    <div>    
                        <p className="services-text">
                        Plasenta ise büyüme faktörleri, sitokinler ve diğer biyoaktif moleküller açısından zengindir. Plasental dokudan izole edilen bu moleküller, cilt sağlığını iyileştirme, kolajen sentezini teşvik etme, cilt elastikiyetini artırma ve yeni kan damarlarının oluşumunu uyararak yara iyileşmesine yardımcı olma potansiyeline sahiptir.
</p>
                    </div>
                    <div className="services-image genclesme-img1">
                    </div>
                </div>

                <h1 className="services-title1">Kordon Kanı ve Plasental Dokusunun Gençleşme Uygulamaları</h1>
                <p className="services-text">Kordon kanı ve plasental dokunun gençleşme tedavisindeki potansiyel uygulamaları oldukça geniştir. Aşağıda, bu yenileyici kaynakların çeşitli gençleşme amaçları için nasıl kullanılabileceğine dair örnekler verilmiştir:</p>
                
                <div className="md:grid gap-x-10 pt-3 grid-cols-3">
                    <div className="services-image genclesme-img2">
                    </div>
                    <div className='col-span-2'>                      
                        <Accordion flush open={open} toggle={toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="uygulama1">Yüz Gençleşmesi</AccordionHeader>
                                <AccordionBody accordionId="uygulama1">
                                Kordon kanı ve plasental doku, yüz cildini gençleştirmek ve yaşlanma belirtilerini azaltmak için kullanılabilir. Cilde topikal olarak uygulandığında veya cilde enjekte edildiğinde, bu yenileyici kaynaklardaki biyoaktif moleküller, kolajen ve elastin üretimini teşvik edebilir. Bu, daha pürüzsüz, daha genç görünümlü bir cilt oluşturabilir ve kırışıklıkları ve ince çizgileri azaltabilir.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="uygulama2">Saç Yeniden Yapılandırma</AccordionHeader>
                                <AccordionBody accordionId="uygulama2">
                                Kordon kanı ve plasental dokunun başka bir uygulama alanı da saç yeniden büyümesini teşvik etmek ve saç kalitesini iyileştirmektir. Kök hücrelerin ve biyoaktif moleküllerin yenileyici özellikleri, saç foliküllerini uyarabilir, sağlıklı saç büyümesini teşvik edebilir ve saç dökülmesini azaltabilir. Bu, yaşla ilgili saç incelmesi veya belirli alopesi türleri yaşayan bireyler için özellikle faydalı olabilir.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="uygulama3">Eklem Onarımı</AccordionHeader>
                                <AccordionBody accordionId="uygulama3">
                                Kordon kanı ve plasental doku, yaşla ilgili dejenerasyon veya travma geçirmiş eklemleri gençleştirmek ve güçlendirmek için kullanılabilir. Kök hücrelerin ve biyoaktif moleküllerin yenileyici özellikleri, hasar görmüş kıkırdakları onarmaya, iltihabı azaltmaya ve eklemlerin işlevini iyileştirmeye yardımcı olabilir. Bu, eklem ağrısından ve hareketlilik sorunlarına yönelik rahatlama sunabilir ve yaşam kalitesini artırabilir.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="uygulama4">Cilt Gençleşmesi ve Yara İyileşmesi</AccordionHeader>
                                <AccordionBody accordionId="uygulama4">
                                Kordon kanı ve plasental dokudaki biyoaktif moleküller, yara iyileşmesini hızlandırabilir ve cildin genel sağlığını iyileştirebilir. Bu moleküllerin yaralara veya hasarlı cilde uygulanması durumunda, hücre çoğaltımını teşvik edebilir, yeni kan damarlarının oluşumunu uyarabilir ve yara iyileşmesini hızlandırabilir. Bu, diyabetik ülserler gibi kronik yaraları olan bireyler için özellikle faydalı olabilir.
                                </AccordionBody>
                            </AccordionItem>                          
                        </Accordion>
                    </div>
                </div>
<br />
                <hr className='services-hr'></hr>
<div id="genclesme-sunnet"></div>
                <h1 className="services-main-title" >Sünnet Derisinden Gençleşme Tedavisi</h1>
                <p className="services-text">Gençleşme tedavileri yaşlanmanın etkilerini tersine çevirme ve daha genç ve sağlıklı bir görünümü teşvik etme amacını taşır. Son yıllarda, sünnet derisinin gençleşme tedavilerinde potansiyel olarak kullanılması konusunda artan bir ilgi görülmektedir. Sünnet derisi, penis başını kaplayan ve sünnet sırasında çıkarılan cilt bölgesidir ve regeneratif özellikler sunan çeşitli hücreler ve büyüme faktörleri içerir.</p>

                <h1 className="services-title1">Kök Hücre Kaynağı Olarak Sünnet Derisi</h1>

                <p className="services-text">Sünnet derisi zengin kök hücre kaynağına sahiptir. Bu kök hücreler fibroblast adı verilen çeşitli hücre tiplerine, cilt hücrelerine, kan damarı hücrelerine ve bağ dokusu hücrelerine farklılaşma yeteneğine sahiptirler. Kök hücreler, kendini yenileme ve uzmanlaşmış hücrelere farklılaşma özellikleri nedeniyle rejenerasyon ve doku onarımı için idealdirler.</p>

                <h1 className="services-title1">Sünnet Derisindeki Büyüme Faktörleri ve Biyolojik Olarak Aktif Moleküller</h1>

                <div className="md:grid gap-x-10 pt-2 grid-cols-2">

                    <div>    
                        <p className="services-text">
                        Sünnet derisi ayrıca çeşitli büyüme faktörleri ve biyolojik olarak aktif moleküller içerir. Bu moleküller, hücre iletişimi, doku gelişimi ve yara iyileşmesinde önemli roller oynar. Sünnet derisinde bulunan bazı önemli büyüme faktörleri arasında epidermal büyüme faktörü (EGF), dönüştürücü büyüme faktör-beta (TGF-beta) ve vasküler endotelyal büyüme faktörü (VEGF) yer alır. Bu büyüme faktörleri, hücre çoğalması, kolajen sentezi ve angiogenez gibi rejenerasyon süreçleri için gereklidir.
                    </p>
                    </div>
                    <div className="services-image genclesme-img3">
                    </div>
                </div>

                <h1 className="services-title1">Gençleşme Tedavilerinde Sünnet Derisinin Uygulamaları</h1>
                <div className="md:grid gap-x-10 grid-cols-2">
                    <div className="services-image genclesme-img4">
                    </div>
                    <div className=''>                      
                        <Accordion flush open={open} toggle={toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama1">Cilt Gençleştirme</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama1">
                                Sünnet derisi, kolajen sentezini teşvik etme, cilt elastikiyetini artırma ve kırışıklık görünümünü azaltma amacıyla cilt gençleştirme tedavilerinde kullanılabilir. Sünnet derisinde bulunan büyüme faktörleri ve kök hücreler, yeni kolajen ve elastin liflerinin üretimini uyararak daha pürüzsüz ve genç görünen bir cilde yol açabilir. Sünnet derisi, cilde uygulanmak üzere topikal krem veya jel haline getirilebilir veya mikro iğneleme veya trombositten zengin plazma (PRP) enjeksiyonları gibi diğer rejeneratif tedavilerle birlikte kullanılabilir.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama2">Yara İyileşmesinde Kullanım</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama2">
                                Sünnet derisi, yara tedavilerinde de faydalı olabilir. Sünnet derisinde bulunan büyüme faktörleri ve kök hücreler, doku rejenerasyonunu teşvik edebilir ve cerrahi, yaralanma veya yanıklar nedeniyle oluşan yara izlerinin görünümünü azaltmaya yardımcı olabilir. Sünnet derisinden elde edilen ürünler, yara bölgesine topikal olarak uygulanabilir veya doğrudan yara dokusuna enjekte edilerek kolajen üretimini uyarabilir ve iyileşmeyi teşvik edebilir.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama3">Saç Restorasyonu</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama3">
                                Sünnet derisinde bulunan büyüme faktörleri ve kök hücreler, saç folikülü rejenerasyonunu teşvik edebilir ve saç büyümesini destekleyebilir. Sünnet derisinden elde edilen ürünler, saç derisine topikal olarak uygulanabilir veya etkilenen bölgelere enjekte edilerek saç folikülü sağlığını iyileştirebilir ve sağlıklı saç büyümesini teşvik edebilir.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama4">Ereksiyon Bozukluğu</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama4">
                                Sünnet derisi, ereksiyon bozukluğu (ED) tedavilerinde de kullanılabilir. Sünnet derisinde bulunan kök hücreler, ereksiyon fonksiyonunda önemli bir rol oynayan düz kas hücrelerine farklılaşma potansiyeline sahiptir. Sünnet derisinden elde edilen kök hücreler, penise enjekte edilerek düz kasların rejenerasyonunu teşvik edebilir ve kan akışını artırarak ereksiyon fonksiyonunu iyileştirebilir.
                                </AccordionBody>
                            </AccordionItem>                          
                        </Accordion>
                    </div>
                </div>             

                <ContactCard />
<div id="genclesme-fibroblast"></div>
<hr className='services-hr'></hr>

                <h1 className="services-main-title" >Fibroblast ile Gençleşme Tedavisi</h1>

                <p className="services-text">Yaşlandıkça, cildimiz elastikiyetini kaybeder, kırışıklıklar ortaya çıkar ve derimiz incelir. Bu yaşlanma belirtileri, kolajen üretiminin azalmasına ve cildin yenilenme yeteneğinin bozulmasından kaynaklanır. Fibroblastların keşfi ve cilt yenilemedeki rolü, yaşlanma belirtilerini tersine çevirmek için onları kullanma konusunda önemli bir ilgi uyandırmıştır. </p>

                <h1 className="services-title1">Fibroblastların Cilt Yenilemedeki Rolü</h1>

                <div className="md:grid gap-x-10 grid-cols-3">

                    <div className='col-span-2'>                      
                        <Accordion flush open={open} toggle={toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="rol1">Kolajen Üretimi</AccordionHeader>
                                <AccordionBody accordionId="rol1">
                                Fibroblastlar, ciltteki ana yapısal protein olan kolajenin sentezinden ve salgılanmasından sorumludur. Yaşlandıkça, fibroblast aktivitesi azalır, bu da kolajen üretiminin düşük olmasına ve cilt sıkılığının kaybolmasına neden olur.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="rol2">Hücre Dışı Matriks Yenileme</AccordionHeader>
                                <AccordionBody accordionId="rol2">
                                Fibroblastlar, cildin hücre dışı matriksini yeniden şekillendirmede kritik bir rol oynar. Eski ve hasarlı kolajen liflerini parçalayan matriks metalloproteinazlar (MMP'ler) adlı enzimler üretirler ve yeni kolajen oluşumu sağlar.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="rol3">Yara İyileşmesi</AccordionHeader>
                                <AccordionBody accordionId="rol3">
                                Fibroblastlar, yara iyileşme sürecinde önemli bir rol oynar. Yara bölgesine nüfuz eder ve doku yenilenmesini teşvik eden büyüme faktörleri ve sitokinler salgılar.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="rol4">Fibroblast Tabanlı Anti-Aging Tedaviler</AccordionHeader>
                                <AccordionBody accordionId="rol4">
                                Fibroblast Enjeksiyonları: Fibroblastların gençleştirici özelliklerinden yararlanmanın bir yolu, bu hücrelerin doğrudan cilde enjekte edilmesidir. Bu, kolajen sentezini uyarır ve cildin kendini yenilemesini teşvik ederek daha pürüzsüz ve genç bir görünüm sağlar. 
                                </AccordionBody>
                            </AccordionItem>  
                            <AccordionItem>
                                <AccordionHeader targetId="rol5">Fibroblast Koşullandırılmış Ortam</AccordionHeader>
                                <AccordionBody accordionId="rol5">
                                Anti-aging tedavilerinde kullanılan bir başka teknik, fibroblast koşullandırılmış ortam (FKO) kullanımıdır. FKO, laboratuvarda fibroblastların yetiştirildiği sıvı kültür ortamıdır. Bu ortam, fibroblastlar tarafından salgılanan büyüme faktörleri, sitokinler ve diğer biyolojik aktif moleküller bakımından zengindir. FKO cilt üzerine uygulandığında kolajen sentezini teşvik eder, cilt esnekliğini artırır ve kırışıklıkların görünümünü azaltır.
                                </AccordionBody>
                            </AccordionItem>                          
                        </Accordion>
                    </div>
                    <div className="services-image genclesme-img5">
                    </div>
                </div>

                
                <h1 className="services-title1">Fibroblast Tedavinin Avantajları</h1>
                <div className="md:grid gap-x-10 grid-cols-2">
                    <div className="services-image genclesme-img6">
                    </div>
                    <div className=''>                      
                        <Accordion flush open={open} toggle={toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama1">Doğal Bir Yaklaşım</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama1">
                                Fibroblastlar, insan vücudunun doğal olarak ürettiği hücrelerdir ve cildin yapısını desteklemek için önemli bir role sahiptirler. 
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama2">Kolajen Üretimini Artırır</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama2">
                                Fibroblastlar, kolajen üretiminden sorumludur ve ciltteki kolajen seviyesinin azalması yaşlanma belirtilerinin ortaya çıkmasına neden olur. Fibroblast tabanlı tedaviler, ciltteki kolajen üretimini artırarak cildin elastikiyetini ve sıkılığını artırır.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama3">Doku Yenilenmesini Teşvik Eder</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama3">
                                Fibroblastlar, yara iyileşme sürecinde önemli bir rol oynarlar ve doku yenilenmesini teşvik ederler. Bu nedenle, fibroblast tabanlı tedaviler ciltteki hasarlı dokunun onarılmasını ve yenilenmesini hızlandırır.
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama4">Kişiselleştirilebilir</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama4">
                                Fibroblastlar, hastanın kendi hücrelerinden (otolog) veya başka bir kişiden (allojenik) elde edilebilir. Otolog fibroblastlar kullanılarak tedavi etkinliği ve tolere edilebilirliği artırılabilir. 
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama5">Uzun Süreli Etki</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama5">
                                Fibroblast tabanlı tedaviler genellikle uzun süreli sonuçlar sağlar. Fibroblastlar, kolajen sentezi ve doku rejenerasyonunu teşvik ederek, tedavinin etkilerinin uzun süre devam etmesini sağlar.
                                </AccordionBody>
                            </AccordionItem>  
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama6">Düşük Risk ve Yan Etki</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama6">
                                Fibroblast tabanlı tedaviler nispeten düşük riskli ve yan etki oranına sahiptir. Hücrelerin aynı hastadan veya donörden elde edilmesi durumunda, alerjik reaksiyon riski azalır. Ayrıca, hücrelerin lokal olarak enjekte edilmesi veya uygulanan bir ürün olarak kullanılması nedeniyle, sistemik yan etkiler nadirdir.
                                </AccordionBody>
                            </AccordionItem>  
                            <AccordionItem>
                                <AccordionHeader targetId="sunnetuygulama7">Kombine Edilebilirlik</AccordionHeader>
                                <AccordionBody accordionId="sunnetuygulama7">
                                Fibroblast tabanlı tedaviler diğer anti-aging yöntemler ile kombine edilebilir. Örneğin, fibroblast enjeksiyonları, PRP veya lazer tedavisi gibi yöntemlerle birleştirilebilir ve sunduğu etki oranı artırılabilir.
                                </AccordionBody>
                            </AccordionItem>                            
                        </Accordion>
                    </div>
                </div>

                <div id="kriyoterapi-nedir"></div>

        <hr className='services-hr'></hr>

        <h1 className="services-main-title" >
        Kriyoterapi
        </h1>
        <p className="services-text">
        Soğuk terapi olarak da bilinen kriyoterapi, vücudun birkaç dakika boyunca aşırı soğuğa maruz bırakıldığı bir tekniktir. Bu yenilikçi tedavi; kas ağrısı ve iltihaplanmayı azaltmaktan cilt sağlığını iyileştirmeye ve kilo kaybına yardımcı olmaya kadar çok sayıda fayda sunma potansiyeline sahiptir. 
        </p>
        
        <h1 className="services-title1">
            Kriyoterapi Nasıl Uygulanır?
            </h1>
            <p className="services-text">
              Kriyoterapi çeşitli şekillerde uygulanabilir, ancak iki temel yöntem tüm vücut kriyoterapisi ve bölgesel kriyoterapidir.</p>
        <div className="gap-x-10 pt-8 md:grid grid-cols-2">        
          <div>
            <p className="services-text">
               Tüm vücut kriyoterapisinde, kişi vücudunu çevreleyen ancak üst kısmında baş için bir açıklık bulunan bir kriyoterapi kabinine (esasen büyük bir oda veya küçük bir oda) girer. Oda, sıvı nitrojen kullanılarak genellikle -100°C ile -140°C (-148°F ile -220°F) arasındaki sıcaklıklara soğutulur. Maruziyet iki ila dört dakika arasında sürer. Bu süre zarfında, cildin yüzey sıcaklığı önemli ölçüde düşer, bu da beyne hayatta kalma moduna girmesi için sinyal verir ve böylece onarım işlevlerini yerine getirecek çeşitli süreçler tetiklenir.
            </p>
          </div>
          <div className="services-image kriyoterapi-img1"></div>
        </div>
        <p className="services-text mt-4">Bölgesel kriyoterapi, ağrılı bir eklem, belirli bir kas bölgesi ve yüz gibi vücudun belirli bölgelerini hedef alır. Lokalize bir alan için soğuk hava veya sıvı nitrojen yayan cihazlar kullanılır. Bu özellikle spor yaralanmalarında, izole iltihaplanma alanlarında veya cildi gençleştirmek için yüze soğuk hava verilen kriyo yüz bakımı gibi güzellik tedavilerinde oldukça etkilidir.</p>

        <h1 className="services-title1" >
        Kriyoterapi Faydaları Nelerdir?
        </h1>
        <p className="services-text">
        Kriyoterapi, aşağıdakileri içeren çok sayıda fayda sunar:
        </p>

        <div className="md:grid gap-x-10 pt-4 grid-cols-2">
          <div className="services-image kriyoterapi-img2">
          </div>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="kriyoterapi1">
                Ağrı Giderme ve Kas İyileştirme
                </AccordionHeader>
                <AccordionBody accordionId="kriyoterapi1">
                Kriyoterapi kas ağrısını önemli ölçüde giderebilir, iltihabı azaltarak ve antioksidan kapasiteyi artırarak egzersiz sonrası iyileşmeyi hızlandırabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kriyoterapi2">
                İnflamatuar Tepkiyi Azaltır
                </AccordionHeader>
                <AccordionBody accordionId="kriyoterapi2">
                Düzenli seanslar vücudun genel inflamatuar tepkisini azaltmaya yardımcı olarak artrit ve otoimmün hastalıklar gibi kronik rahatsızlıkları olanlarda faydalı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kriyoterapi3">
                Kilo Kaybını Artırır
                </AccordionHeader>
                <AccordionBody accordionId="kriyoterapi3">
                Aşırı soğuğa maruz kalmak, vücut belirli bir sıcaklık seviyesinde kalmak için daha fazla çalışacağından metabolik hızı artırır, böylece daha fazla kalori yakılmasını sağlar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kriyoterapi4">
                Cilt Sağlığını Geliştirir
                </AccordionHeader>
                <AccordionBody accordionId="kriyoterapi4">
                Kan dolaşımını artırarak toksinlerin atılmasına yardımcı olur ve cildin berraklığını ve elastikiyetini artırabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kriyoterapi5">
                Anksiyete ve Depresyonu Azaltır
                </AccordionHeader>
                <AccordionBody accordionId="kriyoterapi5">
                Soğuk, vücudun doğal ruh hali yükselticileri olan endorfin salınımını uyarır. Bu anksiyete ve depresyon duygularını hafifletmeye yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              </Accordion>
          </div>
        </div>
 
        <h1 className="services-title1">
        Kriyoterapiyi Kimler Yaptırmalıdır?
        </h1>
        <p className="services-text">
        Kriyoterapi sporcular, kronik ağrısı veya inflamatuar rahatsızlıkları olan bireyler ve cilt gençleştirme veya metabolik destek arayan herkes için faydalıdır. Ancak, herkes için uygun değildir. Kardiyovasküler rahatsızlıkları olan kişiler, hamile kadınlar ve şiddetli hipertansiyonu olanlar kriyoterapiden kaçınmalıdır. 
        </p>

        <h1 className="services-title1">
        Kriyoterapi Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        <p className="services-text">
        Kriyoterapi sonrası, vücut yeniden ısınırken geçici olarak biraz uyuşukluk veya karıncalanma yaşanması normaldir. Bu süreçte:
        </p>
        <div className="">
          <div>
            <p className="services-text">
              <li className="mt-2">
                <b className="services-title3">Susuz kalmayın</b>
                <br /> İşlem sizi biraz susuz bırakabilir, bu nedenle seans öncesi ve sonrası su içmek önemlidir.
              </li>
              <li className="mt-2">
                <b className="services-title3">Yavaşça Isının</b>
                <br /> Vücudunuzun doğal bir şekilde yani kademeli olarak normal sıcaklığına dönmesine izin verin; tedaviden hemen sonra sıcak duş veya banyodan kaçının.
              </li>
              <li className="mt-2">

                <b className="services-title3">Vücudunuzu İzleyin</b> <br />Seanslardan sonra vücudunuzun nasıl tepki verdiğine dikkat edin ve herhangi bir olumsuz reaksiyon durumunda işlemi gerçekleştiren uzmana danışın.
              </li>
            </p>
          </div>
        </div>

        <h1 className="services-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="kriyoterapi-soru1">
              Kriyoterapi sonrası kararma normal mi?
              </AccordionHeader>
              <AccordionBody accordionId="kriyoterapi-soru1">
              Evet, kriyoterapiden sonra tedavi edilen bölgenin koyulaşması (veya hiperpigmentasyon) bazı kişilerde normal bir tepkidir. Bu, aşırı soğuk tarafından tetiklenen iltihaplanma ve iyileşme sürecine bağlı olarak ortaya çıkar. Bazı durumlarda cildin rengi de geçici olarak açılabilir (hipopigmentasyon). Bu pigment değişiklikleri genellikle geçicidir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kriyoterapi-soru2">
              Kriyoterapi sonrası iz nasıl geçer?
              </AccordionHeader>
              <AccordionBody accordionId="kriyoterapi-soru2">
              Kriyoterapi sonrası oluşan izler doğal iyileşme süreci ile zamanla azalabilir ve izin görünüşünü iyileştirmek için nemlendirici kremler kullanılması, güneşten korunma, silikon bazlı ürünler veya cilt bakım ürünleri gibi iyileşmeyi destekleyen yöntemlerden yararlanılabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kriyoterapi-soru3">
              Kriyoterapi sonrası cinsel ilişki kısıtlaması var mı?
              </AccordionHeader>
              <AccordionBody accordionId="kriyoterapi-soru3">
              Belirli kısıtlamalar, tedavi edilen bölgeye ve kriyoterapinin nedenine bağlı olabilir. Örneğin, siğiller veya diğer durumlar için genital bölgeye kriyoterapi uygulandıysa, sağlık uzmanınız rahatsızlığı ve enfeksiyon riskini önlemek için bölge tamamen iyileşene kadar cinsel aktiviteden kaçınmanızı önerebilir. Fakat tüm vücut kriyoterapide genellikle böyle bir kısıtlama bulunmamaktadır. 
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kriyoterapi-soru4">
              Kriyoterapi kaç seans uygulanır?
              </AccordionHeader>
              <AccordionBody accordionId="kriyoterapi-soru4">
              Kriyoterapi, uygulanan alanın durumuna ve hastanın iyileşme sürecine bağlı olarak değişken sayıda seans gerektirebilir. Genellikle, tedavinin etkinliği ve hastanın tepkisi değerlendirilirken birkaç hafta arayla birden fazla seans planlanabilir. Ancak, spesifik tedavi protokolleri bireysel durumlara göre belirlenir. 
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kriyoterapi-soru5">
              Kriyoterapi sonrası duş alınır mı?
              </AccordionHeader>
              <AccordionBody accordionId="kriyoterapi-soru5">
              Evet, kriyoterapiden sonra duş alabilirsiniz. Ancak kriyoterapi bir lezyon için cildin belirli bir bölgesine uygulandıysa, doktorunuz belirli bir süre boyunca bölgeyi kuru ve korumalı tutmayı içerebilecek özel bakım talimatlar verebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kriyoterapi-soru6">
              Kriyoterapi gebelikte yapılır mı?
              </AccordionHeader>
              <AccordionBody accordionId="kriyoterapi-soru6">
              Genel olarak, tüm vücut kriyoterapisi hamilelik sırasında, özellikle de ilk üç aylık dönemde tavsiye edilmez, çünkü hamilelik sırasında bu tür tedavilerin güvenliği tam olarak belirlenmemiştir. Bu durumlarda bölgesel kriyoterapi daha güvenlidir.  Ancak hamile kadınların herhangi bir kriyoterapi uygulamasına başlamadan önce sağlık uzmanlarına danışması çok önemlidir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          </div>

        


        <div id="hiperbarik-oksijen-tedavisi"></div>
        <hr className='services-hr'></hr>
        <h1 className="services-main-title" >
        Hiperbarik Oksijen Tedavisi
        </h1>
        <p className="services-text">
        Hiperbarik Oksijen Tedavisi (HBOT), birçok hastalığın tedavisinde kullanılan etkili ve güvenilir bir yöntemdir. Bu teknik, yüksek basınç altında saf oksijen solunmasını içerir ve çeşitli sağlık sorunlarının üstesinden gelmekte kullanılır. Bu yazıda, Hiperbarik Oksijen Tedavisinin ne olduğunu, nasıl yapıldığını, faydalarını ve etkinliğini inceleyeceğiz.
        </p>

        <h1 className="services-title1" >
        Hiperbarik Oksijen Tedavisi Nedir?
        </h1>
        <p className="services-text">
        Hiperbarik Oksijen Tedavisi, hastalara %100 saf oksijenin solutulduğu özel olarak tasarlanmış bir basınç odası içinde uygulanan tıbbi bir tedavidir. Normal atmosferik basıncın üzerinde bir basınç altında solunum yoluyla yüksek düzeyde oksijen alınması esasına dayanır. Bu tedavi yöntemi; yanıklar, şiddetli enfeksiyonlar, yara iyileşmeleri, aniden ortaya çıkan kulak sorunları ve dalış kazalarından kaynaklanan durumlar gibi çeşitli sağlık problemlerini tedavi etmek için kullanılmaktadır.
        </p>

        <h1 className="services-title1" >
        Hiperbarik Oksijen Tedavisi Nasıl Uygulanır?
        </h1>
        <p className="services-text">
        Hiperbarik Oksijen Tedavisi, hiperbarik odalar adı verilen özel odalarda uygulanır. Bu odalar iki çeşit olabilir:
        </p>
        <div className="md:grid gap-x-10 pt-4 grid-cols-2"> 
          <p className="services-text">
              <li className="mt-2">
                <b className="services-title3">Monoplace Oda</b>
                <br />Tek bir hastanın tedavi olabildiği, silindir şeklinde bir odadır.
              </li>
              <li className="mt-2">
                <b className="services-title3">Multiplace Oda</b>
                <br /> Birden fazla hastanın aynı anda tedavi olabileceği, daha büyük odalardır.
              </li>
              <br />
              Tedavi, hastanın içinde olduğu odanın basıncını deniz seviyesinden iki ila üç kat daha fazla bir seviyeye çıkarma prensibiyle çalışır. Hastalar genellikle maske veya başlık aracılığıyla saf oksijen solur. Bir tedavi seansı, hastalığın ciddiyetine göre 60 ila 120 dakika arasında sürebilir ve tedavi süreci genellikle çok sayıda seans gerektirebilir.
            </p>
            <div className="services-image hiperbarik-img1">
          </div>
        </div>
        <h1 className="services-title1" >
        Hiperbarik Oksijen Tedavisinin Faydaları Nelerdir?
        </h1>
        <p className="services-text">
        Hiperbarik Oksijen Tedavisi'nin sunduğu faydalar geniştir:
        </p>
        <div className="md:grid gap-x-10 pt-4 grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="hiperbarik-fayda1">
                İyileşme Sürelerinin Kısalması
                </AccordionHeader>
                <AccordionBody accordionId="hiperbarik-fayda1">
                Yüksek düzeyde oksijen vücuttaki dokuların daha hızlı onarılmasına yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="hiperbarik-fayda2">
                Enfeksiyon Riskinin Azalması
                </AccordionHeader>
                <AccordionBody accordionId="hiperbarik-fayda2">
                Oksijen, enfeksiyonla mücadeleyi destekleyici özelliklere sahiptir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="hiperbarik-fayda3">
                Yeni Kan Damarlarının Oluşumu
                </AccordionHeader>
                <AccordionBody accordionId="hiperbarik-fayda3">
                Yüksek oksijen oranı, hasar görmüş dokularda yeni kan damarlarının oluşumunu teşvik eder.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="hiperbarik-fayda4">
                İltihaplanmanın Azalması 
                </AccordionHeader>
                <AccordionBody accordionId="hiperbarik-fayda4">
                Oksijen, inflamasyonu azaltıcı etkiye sahiptir.
                </AccordionBody>
              </AccordionItem>
              
            </Accordion>
            <br />
            Hiperbarik oksijen tedavisi bu faydalara ek olarak, sağlıklı yaşlanmayı sürecini de destekler.  Doku oksijenlenmesini artırarak, rejenerasyon ve onarım süreçleri için kritik olan büyüme faktörlerinin ve kök hücrelerin salınımını uyarır. Bu potansiyel olarak yaşlanma sürecini yavaşlatır ve cilt ve diğer dokularda olduğu gibi yaşlanmayla ilişkili hasarları onarır.
          </div>
          <div className="services-image hiperbarik-img2">
          </div>
        </div>
        <h1 className="services-title1" >
        Hiperbarik Oksijen Tedavisi İşe Yarar mı?
        </h1>
        <p className="services-text">
        Hiperbarik Oksijen Tedavisi'nin etkinliği, bilimsel araştırmalar ile desteklenmektedir. Özellikle ani işitme kaybı, diabetik ayak ülserleri ve karbonmonoksit zehirlenmesi gibi durumlar için etkili bir tedavi seçeneği olduğu gösterilmiştir. Ancak, tüm hastalar için aynı etkililikte olmayabilir ve tedaviye başlamadan önce uzman bir sağlık kuruluşuna başvurmak önemlidir.
        </p>

        <h1 className="services-title1" >
        Hiperbarik Oksijen Tedavisi Hangi Durumlarda Uygulanır?
        </h1>
        <p className="services-text">
        Hiperbarik Oksijen Tedavisi, aşağıdakiler dahil ancak bunlarla sınırlı olmamak üzere çeşitli tıbbi durumlardan mustarip bireyler için oyunun kurallarını değiştirebilir:
        </p>
        <div className="">
          <div>
            <p className="services-text">
              <li className="mt-2">
                 Diyabetik ayak ülserleri ve diğer iyileşmeyen yaralar                 
              </li>
              <li className="mt-2">
                 Ameliyat sonrası iyileşme                  
              </li>
              <li className="mt-2">
                 Kanser tedavilerinden kaynaklanan radyasyon yaralanmaları   
              </li>
              <li className="mt-2">
                 Bazı enfeksiyonlar   
              </li>
              <li className="mt-2">
                 Karbonmonoksit zehirlenmesi   
              </li>
            </p>
          </div>
        </div>

        <h1 className="services-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="hiperbarik-soru1">
              Hiperbarik oksijen tedavisinin seans süresi ne kadardır?
              </AccordionHeader>
              <AccordionBody accordionId="hiperbarik-soru1">
              Standart bir seans genellikle 60 ila 90 dakika arasında sürer, fakat bu süre hastanın durumuna ve tedavi planına göre değişiklik gösterebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="hiperbarik-soru2">
              Hiperbarik oksijen tedavisinin yan etkileri var mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="hiperbarik-soru2">
              Genellikle güvenli bir tedavi olan hiperbarik oksijen tedavisinin olası yan etkileri kulak ağrısı, sinüs basıncı, geçici görme değişiklikleri ve nadiren hava veya gaz embolisi gibi durumları içerebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="hiperbarik-soru3">
              Hiperbarik oksijen tedavi seansları kaç defa yapılır?
              </AccordionHeader>
              <AccordionBody accordionId="hiperbarik-soru3">
              Tedavi sıklığı ve seans sayısı kişiden kişiye ve tedavi edilen duruma bağlı olarak değişir, bazı hastalar için 20-40 seans arası bir tedavi süreci önerilebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="hiperbarik-soru4">
              Hiperbarik oksijen tedavisi her hastane veya klinikte yapılır mı?
              </AccordionHeader>
              <AccordionBody accordionId="hiperbarik-soru4">
              Hayır, hiperbarik oksijen tedavisi özel ekipman gerektirdiğinden bu hizmeti sunan özel olarak donatılmış merkezler bulunmaktadır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="hiperbarik-soru5">
              Hiperbarik oksijen tedavisi ağrılı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="hiperbarik-soru5">
              Hayır, hiperbarik oksijen tedavisi (HBOT), genellikle ağrısız bir tedavi yöntemidir. HBOT sırasında hastalar genellikle özel olarak tasarlanmış bir basınç odasında rahat bir şekilde oturur veya uzanır. Bu tedavi, yüksek basınç altında saf oksijenin solunmasını içerir ve bir dizi tıbbi durumu tedavi etmek veya iyileşmeyi hızlandırmak için kullanılır.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          </div>

          
        <div id="kirmizi-isik-terapisi"></div>

<hr className='services-hr'></hr>

<h1 className="services-main-title" >
Kırmızı Işık Terapisi (Red Light Therapy)
</h1>
<p className="services-text ">
Sağlıklı yaşam ve cilt bakımı alanında devrim niteliğinde bir yaklaşım olan kırmızı ışık terapisi, hızlı ve invaziv olmayan bir tedavi olarak popülerlik kazanmıştır. Kırmızı ışık terapisi, ışığın gücünden yararlanarak cilt gençleştirme, ağrı azaltma ve iyileşmeyi hızlandırma gibi çeşitli durumlar için etkili bir çözüm sunmaktadır.
</p>
<p className="services-text mt-2">
Özünde, kırmızı ışık terapisi düşük dalga boylu kırmızı ışık yayarak çalışır ve hücresel işlevi derinden etkiler. Esasen tıbbi prosedürlerde kullanılan yoğun lazerlere daha nazik bir alternatiftir ve cilt yüzeyine zarar vermeden iyileşmeyi ve gençleşmeyi destekler. Bu terapi, fotobiyomodülasyon prensibinden yararlanarak vücudun mitokondriyal fonksiyonunu (hücrenin güç merkezi) uyarır ve böylece hücreleri daha fazla adenozin trifosfat (ATP) üretmeye teşvik eder. Daha fazla ATP ile hücreler daha verimli çalışır, kendilerini gençleştirir ve hasarı onarır.
</p>
<p className="services-text mt-2">
Kırmızı ışık terapisinin benzersiz yönü, kaslara, sinirlere ve hatta kemiklere ulaşarak hasara neden olmadan diğer ışık formlarından daha derine nüfuz etme yeteneğidir. 
</p>

<h1 className="services-title1">
    Kırmızı Işık Terapisinin Faydaları
    </h1>
    <p className="services-text">Kırmızı ışık terapisinin çeşitli faydalarını gösteren ve sayıları giderek artan çeşitli araştırmalar mevcuttur:</p>
<div className="pt-8 gap-x-10 md:grid grid-cols-2">
  <div className="services-image skin-img7">
  </div>
  <div>
    
    <Accordion flush open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-fayda1">
        Cilt Sağlığı
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-fayda1">
        Kırmızı ışık terapisi, genç bir cilt için gerekli olan kolajen üretimini hızlandırabilir, kırışıklıkları azaltabilir ve cildin ten rengini ve elastikiyetini iyileştirebilir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-fayda2">
        Yara İyileşmesi
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-fayda2">
        Hücresel onarımı ve yenilenmeyi artırarak, vücudun doğal iyileşme süreçlerini hızlandırır.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-fayda3">
        Ağrı Kesici
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-fayda3">
        Artrit ve tendinit gibi durumlar da dahil olmak üzere kronik inflamasyon ve ağrıyı azaltmada umut verici sonuçlar göstermiştir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-fayda4">
        Kas İyileşmesi
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-fayda4">
        Sporcular, kırmızı ışık terapisinin egzersiz sonrası kas yorgunluğunu ve inflamasyonu azaltma ve iyileşme sürelerini iyileştirme yeteneğinden yararlanır.
        </AccordionBody>
      </AccordionItem>
      
    </Accordion>
  </div>

</div>

<h1 className="services-title1">
Kırmızı Işık Terapisinin Kullanım Alanları
</h1>
<p className="services-text">Kırmızı ışık terapisi estetik, medikal ve performans olmak üzere çeşitli alanlarda giderek artan bir kullanım alanına sahiptir. Bu kullanım alanları şöyle özetlenebilir:
</p>
<div className="">
  <div>
    <p className="services-text">
      <li className="mt-2">
        <b className="services-title3">Kozmetik ve Cilt Bakımı</b>
        <br /> Yaşlanma karşıtı etkileri nedeniyle kırmızı ışık terapisi, akne ile mücadele eden, kırışıklıkları azaltan ve canlı, genç bir cildi destekleyen bir tedavidir.
      </li>
      <li className="mt-2">
        <b className="services-title3">Medikal</b>
        <br /> Sedef hastalığı, kronik ağrı ve nöropati gibi durumların yönetimi için farmasötik olmayan bir seçenek sunar.
      </li>
      <li className="mt-2">
        <b className="services-title3">Fitness</b> <br />Kas iyileşmesinin ötesinde, kırmızı ışık terapisi dayanıklılığı ve fiziksel performansı artırma potansiyeline sahiptir. 
      </li>
    </p>
  </div>
</div>

<h1 className="services-title1">
    Kırmızı Işık Terapisi Nasıl Yapılır?
    </h1>
    <p className="services-text">
    Kırmızı Işık Terapisi tipik olarak terapötik sonuçlar için bu alanda eğitim almış sağlık uzmanları tarafından uygulanır. Süreç, evde tedaviler için kullanılanlardan daha sofistike cihazlar içerir ve daha geniş bir dalga boyu aralığı veya tedavi parametreleri üzerinde daha hassas kontrol sunar.
    <br /> <br /> Terapinin uygulama adımları şöyledir: 
    </p>
<div className="pt-8 gap-x-10 md:grid grid-cols-3">
  <div className="col-span-2">
    
    <Accordion flush open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama1">
        Konsültasyon
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama1">
        Tedavi genellikle bir sağlık hizmeti sağlayıcısı veya terapistle yapılan konsültasyonla başlar. Bu adım, tıbbi geçmişinizi değerlendirmek, tedavi hedeflerinizi tartışmak ve terapinin ihtiyaçlarınıza uygun olup olmadığını belirlemek için çok önemlidir. 
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama2">
        Hazırlık
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama2">
        Tedavi edilecek bölgeye bağlı olarak, bir önlük giymeniz istenebilir. Tedavinin uygulanacağı cilt bölgesinin temiz olması ve makyaj, losyon veya güneş kremi gibi engellerden arındırılmış olması önemlidir. Tedavi alanı geniş ise seans özel bir ışık terapisi odasında gerçekleşebilir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama3">
        Göz Koruması
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama3">
        Işığın yoğunluğuna ve tedavi edilen alana bağlı olarak, parlak ışığa maruz kalmaya karşı korunmak için koruyucu gözlük verilebilir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama4">
        Cihazın Konumlandırılması
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama4">
        Terapiyi uygulayan profesyonel, ışık terapisi cihazını doğrudan hedeflenen alanın üzerine yerleştirecektir. Taşınabilir ev cihazlarının aksine, klinik kırmızı ışık terapisi cihazları daha büyük olabilir ve daha geniş cilt yüzeylerini veya tüm vücut bölümlerini aynı anda hedefleyebilir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama5">
        Süre ve Frekans
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama5">
        Klinik bir ortamda tipik bir seans, tedavi edilen duruma ve cihazın özelliklerine bağlı olarak birkaç dakikadan yaklaşık 20 dakikaya kadar çıkabilir. Toplam seans sayısı, bireysel tedavi protokollerine bağlı olarak genellikle birkaç haftaya yayılarak değişecektir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama6">
        Seans Sırasında
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama6">
        En iyi pozlamayı sağlamak için seans sırasında hareketsiz kalmanız istenecektir. Uzman, tedavi edilen bölgeye göre cihazı gerektiği gibi ayarlayabilir. Klinik terapi cihazları bazen daha derin nüfuz için kırmızı ve yakın kızılötesi ışığın bir kombinasyonunu sunarak cilt yüzeyinin altındaki sorunları ele alır.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama7">
        Tedavi Sonrası
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama7">
        Seans sona erdikten sonra, genellikle gerekli bir kesinti süresi yoktur ve çoğu kişinin günlük hayatına hızlıca geri dönebilir. Uzman bu süreçte doğrudan güneş ışığından kaçınma gibi tedavi sonrası bakım talimatları sunabilir.
        </AccordionBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionHeader targetId="kirmizi-isik-uygulama8">
        Sonuçların İzlenmesi
        </AccordionHeader>
        <AccordionBody accordionId="kirmizi-isik-uygulama8">
        Uzmanınız, sonraki ziyaretlerde ilerlemenizi izleyecek ve en iyi sonuçları elde etmek için tedavi planını gerektiği şekilde ayarlayacaktır. Deneyiminiz ve tedavi süreci boyunca fark ettiğiniz yan etkiler hakkında açık bir şekilde iletişim kurmanız çok önemlidir.
        </AccordionBody>
      </AccordionItem>             
    </Accordion>
  </div>

  <div className="services-image skin-img8">
  </div>
</div>
<h2 className="services-title3 mt-2"><b> Güvenlik ve Etkinlik </b></h2>
<p className="services-text mt-2">Klinik bir ortamda, kırmızı ışık terapisinin kullanımı evde kullanılan basit cihazlara göre hem daha güvenli hem de daha etkilidir.  Terapistler ve tıbbi sağlayıcılar, en son araştırma ve klinik kılavuzları dikkate alarak tedavi parametrelerinin her birey için optimize edilmesini sağlar.</p>

<h1 className="services-title1">
Kırmızı Işık Terapisinin Yan Etkileri Var mı?
    </h1>
    <p className="services-text">
    Kırmızı ışık terapisi, bildirilen minimum yan etki ve güvenlik profiliyle öne çıkmaktadır. Güneşten gelen UV ışığının aksine, kırmızı ışık terapisi cilt hasarına neden olmaz. Bazı kullanıcılar geçici kızarıklık veya tahriş deneyimleyebilir, ancak ciddi yan etkiler nadirdir.
    </p>

    <h1 className="services-title1">Sık Sorulan Sorular</h1>
<div className="">
  <Accordion flush open={open} toggle={toggle}>
    <AccordionItem>
      <AccordionHeader targetId="kirmizi-isik-soru1">
      Kırmızı ışık terapisi ne işe yarar?
      </AccordionHeader>
      <AccordionBody accordionId="kirmizi-isik-soru1">
      Kırmızı ışık terapisi, cilde farklı derinliklere nüfuz eden kırmızı ve kızılötesi dalga boyları yayarak çalışır. Bu dalga boyları hücrelerinizdeki mitokondriyi uyararak enerji üretiminin artmasına yol açar ve potansiyel olarak çeşitli iyileşme ve gençleşme süreçlerini kolaylaştırır.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="kirmizi-isik-soru2">
      Kırmızı ışık faydalı mı?
      </AccordionHeader>
      <AccordionBody accordionId="kirmizi-isik-soru2">
      Evet, kırmızı ışığın faydaları arasında gelişmiş cilt sağlığı (artan kolajen üretimi ve aknenin azalması gibi), hızlandırılmış yara iyileşmesi, ağrının giderilmesi, iltihaplanmanın azaltılması ve gelişmiş kas iyileşmesi yer alır.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="kirmizi-isik-soru3">
      Kırmızı ışık terapisi kansere neden olabilir mi?
      </AccordionHeader>
      <AccordionBody accordionId="kirmizi-isik-soru3">
      Kırmızı ışık terapisinin kansere neden olduğunu gösteren hiçbir kanıt yoktur. Kırmızı ışık terapisinde kullanılan ışık, cilt kanserine katkıda bulunduğu bilinen UV ışınları içermez. Terapi, minimum riskle düzenli kullanım için güvenli kabul edilmektedir.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="kirmizi-isik-soru4">
      Kırmızı ışık terapisi ne kadar sürer?
      </AccordionHeader>
      <AccordionBody accordionId="kirmizi-isik-soru4">
      Tipik bir kırmızı ışık terapi seansı 10 ila 20 dakika arasında sürer ve kullanılan cihaza ve tedavi edilen duruma göre değişebilir. Çoğu tedavi protokolü, optimum sonuçlar için haftada birkaç kez seans önerir.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="kirmizi-isik-soru5">
      Kırmızı ışık terapisinin sonuçları ne kadar sürede görülür?
      </AccordionHeader>
      <AccordionBody accordionId="kirmizi-isik-soru5">
      Sonuçlar kişiye ve tedavi edilen duruma göre değişebilir. Bazıları, iltihaplanmanın azalması ve ağrının hafiflemesi gibi ani etkiler bildirirken, diğerleri birkaç haftalık tutarlı tedavi ile cilt sağlığı ve kas iyileşmesinde kademeli iyileşmeler fark eder.
      </AccordionBody>
    </AccordionItem>
    <AccordionItem>
      <AccordionHeader targetId="kirmizi-isik-soru6">
      Kırmızı ışık terapisinin herhangi bir yan etkisi var mı?
      </AccordionHeader>
      <AccordionBody accordionId="kirmizi-isik-soru6">
      Kırmızı ışık terapisinin bildirilen çok az yan etkisi vardır, en yaygın olanı tedavi bölgesinde geçici kızarıklık veya tahriştir. Ciddi yan etkiler nadirdir, bu da onu birçok kişi için düşük riskli bir seçenek haline getirir.
      </AccordionBody>
    </AccordionItem>
  </Accordion>
</div>


                
            </div>


        </div>
    );
}

export default Genclesme;