import "../operations.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import botoksKirisikP from "../../../assets/botoks-kirisiklik-tedavisi.jpg";
import masseterBotoksP from "../../../assets/masseter-botoks.png";
import gummySmileP from "../../../assets/gummy-botoks.png";
import burunDolgusuP from "../../../assets/burun-dolgusu.jpg";
import dudakDolgusuP from "../../../assets/dudak-dolgusu.jpg";
import gozAltıDolgusuP from "../../../assets/goz-alti.jpeg";
import ceneDolgusuP from "../../../assets/cene-dolgusu.png";
import sakakDolgusuP from "../../../assets/sakak-dolgusu.png";
import nazoLabialDolgusuP from "../../../assets/nazolabial-dolgu.png";
import elmacıkDolgusuP from "../../../assets/elmacik-dolgusu.jpg";
import alinDolgusuP from "../../../assets/alin-dolgusu.jpg";
import yanakInceltmeP from "../../../assets/yanak-inceltme.jpg";
import ContactCard from "../../services/contactCard/contactCard";
const FaceOperations = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="operations-body">
      <div className="md:grid grid-cols-2 operations-header">
        <div className="operations-responsive-header face-header-image"></div>
        <div className="operations-header-text">
          <h1 className="operations-header-title">
            Canlı ve Genç Bir Yüz İçin İşlemlerimiz
          </h1>
          <h3 className="operations-header-title2">
            Yüz tedavilerimizle daha canlı, genç ve sağlıklı bir görünüme
            kavuşun. Uzman ekibimizle yüzünüzün ihtiyaçlarına özel çözümler
            sunuyoruz.
            <br />
            <a className="operations-header-link" href="#botokspin">
              <div className="operations-header-link-div">
                <div className="link-icon-div">
                  <i class="fa-solid fa-arrow-right link-icon"></i>
                </div>
                <div> Botoks İşlemleri</div>
              </div>
            </a>
            <a className="operations-header-link" href="#dolgupin">
              <div className="operations-header-link-div">
                <div className="link-icon-div">
                  <i class="fa-solid fa-arrow-right link-icon"></i>
                </div>{" "}
                <div>Dolgu İşlemleri</div>
              </div>
            </a>
            <a className="operations-header-link" href="#yanakpin">
              <div className="operations-header-link-div">
                <div className="link-icon-div">
                  <i class="fa-solid fa-arrow-right link-icon"></i>
                </div>{" "}
                <div>Yanak İnceltme</div>
              </div>
            </a>
          </h3>
        </div>
        <div className="operations-header-image face-header-image"></div>
      </div>
      <div id="botokspin" />
      <div className="operations-content">
        <section className="botoks">
          <h1 className="operations-main-title">
            Botoks ile Kırışıklık Tedavisi
          </h1>
          <p className="operations-text">
            Botoks ile kırışıklık tedavisi, yaşlanmanın belirtilerini azaltmak
            ve cildi gençleştirmek amacıyla kullanılan yaygın bir kozmetik
            tedavi yöntemidir. Bu prosedür, botulinum toksin tip A adı verilen
            bir bileşenin kaslara enjekte edilmesiyle uygulanır. Böylece
            kasların geçici olarak gevşemesine ve kırışıklıkların görünümünün
            azalmasına yardımcı olur.{" "}
          </p>

          <div className=" gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
            <img
              src={botoksKirisikP}
              className="w-full h-auto object-cover rounded-[10px]"
              alt="botoks-img"
            />

            <div>
              <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="kb1">
                    Kırışıklık Botoksu Nasıl Yapılır?
                  </AccordionHeader>
                  <AccordionBody accordionId="kb1">
                    Botoks uygulaması, steril bir ortamda yapılır. İğne ile
                    botulinum toksini, belirli kaslara enjekte edilir. Uygulama
                    genellikle birkaç dakika sürer ve ağrı hissedilmesini
                    engellemek için lokal anestezik krem kullanılabilir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="kb2">
                    Kırışıklık Botoksu Hangi Bölgelere Uygulanabilir?
                  </AccordionHeader>
                  <AccordionBody accordionId="kb2">
                    Kırışıklık botoksu genellikle yüzün üst kısmında, alında,
                    göz çevresindeki kırışıklıklarda, kaş arasındaki çizgilerde
                    ve mimik kırışıklıklarında kullanılır. Ayrıca boyun ve
                    dekolte bölgesindeki kırışıklıkların tedavisinde de
                    etkilidir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="kb3">
                    Kırışıklık Botoksun Etkisi Ne Kadar Sürer?
                  </AccordionHeader>
                  <AccordionBody accordionId="kb3">
                    Botoks uygulamasının etkisi kişiden kişiye değişmekle
                    birlikte genellikle 3 ila 6 ay arasında devam eder. Daha
                    sonra tekrarlanması gerekebilir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="kb4">
                    Kırışıklık Botoksu Sonrası Dikkat Edilmesi Gerekenler
                  </AccordionHeader>
                  <AccordionBody accordionId="kb4">
                    • İşlem sonrası ilk birkaç saat boyunca masaj yapılmamalı ve
                    alkol kullanılmamalıdır. <br />
                    • 24 saat boyunca aşırı fiziksel aktivitelerden
                    kaçınılmalıdır. <br />
                    • İğne bölgesine dokunulmamalı ve ovalanmamalıdır. <br />•
                    İşlem sonrası belirtilen bakım talimatlarına uyulmalıdır.{" "}
                    <br />• Herhangi bir ağrı veya anormallik hissedilirse,
                    doktora başvurulmalıdır.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <h1 className="operations-title1">Sık Sorulan Sorular</h1>
          <div className="">
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="kbsoru1">
                  Botoks kırışıklıkları yok eder mi?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="kbsoru1">
                  Evet, botoks kırışıklıkları azaltmak veya hafifletmek için
                  etkili bir tedavi yöntemidir. Botoks enjeksiyonları, kasları
                  geçici olarak felç ederek kırışıklıkların görünümünü yumuşatır
                  ve daha genç bir cilt görünümü sağlar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kbsoru2">
                  Botoks derin kırışıklıklara iyi gelir mi?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="kbsoru2">
                  Botoks, derin kırışıklıkları azaltmak için etkili bir tedavi
                  seçeneği olabilir, ancak derin kırışıklıkları tamamen yok etme
                  garantisi vermez. Yine de botoks enjeksiyonları, derin
                  kırışıklıkları hafifletmeye yardımcı olabilir ve ciltte daha
                  pürüzsüz bir görünüm sağlayabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kbsoru3">
                  Kırışıklık botoksu acıtır mı?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="kbsoru3">
                  Kırışıklık botoksu işlemi sırasında genellikle hafif bir
                  rahatsızlık veya acı hissi olabilir. Ancak, işlem öncesi
                  uyuşturucu kremler veya lokal anestezikler kullanılarak acı
                  hissi en aza indirilebilir. Her bireyin ağrı eşiği farklı
                  olduğundan, acı algısı kişiden kişiye değişebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kbsoru4">
                  Kırışıklık botoksu ne kadar sürer?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="kbsoru4">
                  Kırışıklık botoks işlemi genellikle 10-15 dakika kadar sürer.
                  İşlem süresi, uygulanacak bölgeye ve kişinin ihtiyaçlarına
                  bağlı olarak değişebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="kbsoru5">
                  Kırışıklık botoksu kalıcı mıdır?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="kbsoru5">
                  Hayır, kırışıklık botoksu kalıcı bir çözüm değildir. Botoks
                  etkisi genellikle 3-6 ay sürebilir ve ardından tekrarlanması
                  gerekebilir. Botulinum toksin enjeksiyonları düzenli
                  aralıklarla tekrarlandığında uzun süreli etkiler sağlayabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <hr className="operations-hr"></hr>
          <h1 className="operations-main-title">
            Masseter Botoks (Diş Sıkma Botoksu)
          </h1>
          <p className="operations-text">
            Masseter Botoks, çene kasının (masseter kası) botulinum toksin
            enjeksiyonu ile tedavi edilmesidir. Bu tedavi, çene sıkma (bruxism)
            veya çene kasının aşırı gelişimi gibi çene sorunlarına neden olan
            kasın aşırı aktivitesini kontrol etmeyi amaçlar..{" "}
          </p>

          <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
            <div>
              <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="fayda1">
                    Masseter Botoks Nasıl Uygulanır?{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="fayda1">
                    Masseter Botoks uygulaması yapılırken, botulinum toksin çene
                    kasının belirli noktalarına ince iğnelerle enjekte edilir.
                    İşlem, genellikle birkaç dakika sürer ve acıyı en aza
                    indirmek için lokal anestezik krem kullanılabilir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="fayda2">
                    Masseter Botoks Yararları{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="fayda2">
                    Masseter Botoks, çene kasının aşırı aktivitesini azaltarak
                    çene sıkma, diş gıcırdatma ve çene ağrısı gibi semptomları
                    hafifletebilir. Ayrıca, çene kasının hacmini azaltmak
                    suretiyle yüz hatlarını daha ince ve daha oval bir görünüme
                    kavuşturabilir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="fayda3">
                    Masseter Botoks Hangi Bölgelere Uygulanır?{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="fayda3">
                    Masseter Botoks, genellikle çene yüzeyine yerleştirilen
                    masseter kasının bulunduğu bölgelere uygulanır. Bu tedavi,
                    genellikle çene alt kısımları, mandibula bölgesi ve yüz
                    hatlarını etkileyen bölgelerde kullanılır.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="fayda4">
                    Masseter Botoks Öncesi: Hazırlık Süreci{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="fayda4">
                    Masseter Botoks öncesinde, uzman bir sağlık profesyoneli
                    tarafından detaylı bir değerlendirme yapılması önemlidir.
                    Ayrıca hastanın tıbbi geçmişi, alerjik reaksiyonları ve ilaç
                    kullanımı gibi bilgilerin paylaşılması gerekmektedir. İşlem
                    öncesi alkol veya kan inceltici ilaçlardan kaçınmak da
                    tavsiye edilir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="fayda5">
                    Masseter Botoks Sonrası Dikkat Edilmesi Gerekenler{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="fayda5">
                    Masseter Botoks sonrası, enjeksiyon bölgesini ovuşturmamak
                    veya masaj yapmamak önemlidir. Ayrıca, 24 saat boyunca
                    eğilmekten, aşırı fiziksel aktivitelerden veya aşırı sıcak
                    ortamlardan kaçınılmalıdır.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
            <img
              src={masseterBotoksP}
              className="w-full h-auto rounded-[10px]"
              alt="masseter-botoks-img"
            ></img>
          </div>
          <h1 className="operations-title1">Sık Sorulan Sorular</h1>
          <div className="">
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="mbsoru1">
                  Masseter botoks sonrası çene ağrısı yaşanır mı?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="mbsoru1">
                  Masseter botoks uygulaması sonrasında çene ağrısı nadir bir
                  yan etkidir. Ancak, bu tür bir ağrı hissi yaşayan kişiler
                  olabilir. Genellikle ağrı hafif ve geçicidir, birkaç gün
                  içinde azalır veya tamamen kaybolur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="mbsoru2">
                  Masseter botoks sonrası şişlik ne zaman geçer?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="mbsoru2">
                  Masseter botoks sonrasında oluşabilecek hafif şişlik ve ödem
                  genellikle birkaç gün içinde azalır ve kaybolur. Ancak her
                  bireyin iyileşme süreci farklıdır. Ödem, soğuk kompres
                  uygulaması ve dinlenme ile hafifletilebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="mbsoru3">
                  Masseter botoks ne zaman etki eder?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="mbsoru3">
                  Masseter botoks tedavisinin etkileri uygulamadan sonra
                  genellikle birkaç gün içinde başlar. Genellikle ilk haftadan
                  sonra belirgin bir rahatlama ve çenede gerilim azalması
                  hissedilir. En iyi sonuçlar ise tedavi sonrası 2-4 hafta
                  içinde ortaya çıkar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="mbsoru4">
                  Masseter botoks kalıcı mıdır?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="mbsoru4">
                  Hayır, Masseter botoks kalıcı değildir. Botoks enjeksiyonları
                  geçici bir etkiye sahiptir ve genellikle 3-6 ay sürebilir.
                  Düzenli olarak tekrarlanması gerekmektedir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="mbsoru5">
                  Masseter botoks yüzü inceltir mi?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="mbsoru5">
                  Masseter botoks, çene kasının hacmini azaltarak yüz hatlarını
                  inceltme etkisi gösterebilir. Bu tedavi, çene hattının daha
                  oval ve estetik bir görünüme kavuşmasına yardımcı olabilir.
                  Ancak, her bireyin anatomisi ve tepkisi farklı olduğu için
                  sonuçlar kişiden kişiye değişebilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <hr className="operations-hr"></hr>
          <h1 className="operations-main-title">
            Gummy Smile Botox (Gülüş Botoksu){" "}
          </h1>
          <p className="operations-text">
            Gummy smile botox, aşırı diş eti gösteren bir gülümsemeye sahip olan
            kişilerde, gülüşün estetik görünümünü iyileştirmek amacıyla
            uygulanan bir tedavi yöntemidir. Bu tedavi, diş eti görünümünü
            azaltarak daha çekici bir gülümseme elde etmek için botulinum
            toksininin enjekte edilmesini içerir. <br />
            Diş eti gülümsemesi, kişinin gülüşünde alt diş etinin normalden daha
            fazla göründüğü bir durumdur. Bu durum birçok insanda estetik
            kaygılara neden olabilir ve kişinin özgüvenini etkileyebilir. Gummy
            smile botox ile bu durum düzeltilmeye çalışılır.
          </p>
          <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
            <img
              src={gummySmileP}
              className="w-full h-auto rounded-[10px]"
              alt="Gummy-smile-botox-img"
            ></img>
            <div>
              <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="gummy1">
                    Gummy Smile Botox Yararları
                  </AccordionHeader>
                  <AccordionBody accordionId="gummy1">
                    <p className="operations-text">
                      • Gummy smile botox, diş eti görünümünü azaltarak daha
                      çekici bir gülümseme sunmayı amaçlar. Bu, birçok kişinin
                      özgüvenini artırabilir ve estetik kaygıları azaltabilir.
                      <br />
                      • Diş eti gülüşü, dişlerin estetik görünümünü
                      etkileyebilir ve gülüşü dengesiz gösterebilir. Gummy smile
                      botox uygulaması, diş eti ve dişler arasındaki orantıyı
                      iyileştirebilir ve daha dengeli bir gülümsemeye yardımcı
                      olabilir.
                      <br />
                      • Diş eti gülümsemesi, bazı insanlarda yaşlılık belirtisi
                      olarak algılanabilir. Gummy smile botox, diş eti
                      görünümünü azaltarak daha genç bir görünüm elde etmeyi
                      hedefler.
                      <br />• Gummy smile botox, hızlı ve etkili bir tedavi
                      seçeneğidir. İşlem süresi kısadır, genellikle minimal
                      rahatsızlık ve yan etkilere sahiptir.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="gummy2">
                    Gummy Smile Botox Öncesi: Hazırlık Süreci
                  </AccordionHeader>
                  <AccordionBody accordionId="gummy2">
                    <p className="operations-text">
                      • Doktorunuzla tıbbi geçmişinizi ve mevcut sağlık
                      durumunuzu paylaşmalısınız. Bu, doktorunuzun işlem için en
                      uygun planı oluşturmasına yardımcı olacaktır.e sunmayı
                      amaçlar. Bu, birçok kişinin özgüvenini artırabilir ve
                      estetik kaygıları azaltabilir.
                      <br />
                      • Kullandığınız ilaçlar ve takviyeler hakkında doktorunuzu
                      bilgilendirmeniz önemlidir. Bazı ilaçların, uygulamadan
                      önce kesilmesi gerekebilir.
                      <br />
                      • Doktorunuz, diş eti gülümsemenizin seviyesini ve
                      tedaviye uygunluğunu değerlendirmek için bir muayene
                      yapacaktır.
                      <br />• Doktorunuzla tedavinin beklenen sonuçları ve
                      sınırlamaları hakkında konuşmanız önemlidir. Bu gerçekçi
                      beklentilere sahip olmanız adına oldukça önemlidir.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="gummy3">
                    Gummy Smile Botox Sonrası Dikkat Edilmesi Gerekenler
                  </AccordionHeader>
                  <AccordionBody accordionId="gummy3">
                    <p className="operations-text">
                      • İşlem sonrasında bazı yan etkiler görülebilir. Bunlar
                      genellikle hafif ve geçici olabilir. Enjeksiyon bölgesinde
                      ağrı, şişlik, morarma veya kızarıklık gibi belirtiler
                      görüldüğünde doktora başvurulmalıdır.
                      <br />
                      • İşlem sonrasında aşırı kas aktivitesinden
                      kaçınılmalıdır. Bunun için sert veya yapışkan gıdalardan
                      uzak durmak ve mimiklerin aşırı kullanımından kaçınmak
                      faydalı olabilir.
                      <br />
                      • İşlem sonrasında enjeksiyon bölgelerine masaj yapmaktan
                      kaçınılmalıdır. Bu, botoxun etkisini daha hızlı
                      kaybetmesine neden olabilir.
                      <br />
                      • İşlem sonrasında güneşe maruz kalmaktan kaçınılmalı veya
                      güneş koruyucu kullanılmalıdır. Bu, cildin korunmasına
                      yardımcı olacaktır.
                      <br />• Olası herhangi bir sorun, yan etki veya
                      beklenmeyen bir durumda sağlık uzmanına başvurulması
                      önemlidir.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <h1 className="operations-title1">Sık Sorulan Sorular</h1>
          <div className="">
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="gssoru1">
                  Gummy smile botox kalıcı mıdır?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="gssoru1">
                  Gummy smile botox tedavisi kalıcı değildir. Etkileri
                  genellikle 3-6 ay sürer. Bu süre zarfında kaslar zamanla
                  yeniden aktif hale gelebilir ve diş eti görünümü tekrar
                  artabilir. Ancak düzenli olarak tekrarlanan enjeksiyonlarla
                  etkilerin sürekli olarak devam etmesi sağlanır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gssoru2">
                  Gummy smile botox ne kadar sürer?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="gssoru2">
                  Gummy smile botox işlemi genellikle 10-15 dakika sürer. Bu,
                  işlem bölgesine ve tedavi planına bağlı olarak değişebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gssoru3">
                  Gummy smile botox acıtır mı?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="gssoru3">
                  MGummy smile botox işlemi genellikle minimal ağrı veya
                  rahatsızlık hissiyle ilişkilendirilir. Genellikle enjeksiyon
                  öncesi bir topikal anestezik uygulanır ve enjeksiyon sırasında
                  herhangi bir acı hissedilmez.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gssoru4">
                  Gummy smile botox işe yarar mı?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="gssoru4">
                  Gummy smile botox, gülüşün estetik görünümünü iyileştirmeye
                  yardımcı olabilir. Çoğu insanda diş eti görünümünü azaltarak
                  daha çekici bir gülümseme elde etmeyi başarır. Bununla
                  birlikte, etkinliği bireysel faktörlere bağlıdır ve herkes
                  için aynı sonuçları garanti etmek mümkün değildir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gssoru5">
                  Gummy smile botox ne zaman etki eder?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="gssoru5">
                  Gummy smile botox enjeksiyonlarının tam etkisi genellikle
                  birkaç gün ila bir hafta içinde görülür. Tam sonuçları görmek
                  için bu süreyi beklemek önemlidir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

<ContactCard/>

          <div id="dolgupin" />
          <hr className="operations-hr"></hr>
        </section>
        <section className="dolgu">
          <h1 className="operations-main-title">Dudak Dolgusu </h1>
          <p className="operations-text">
            Dudak dolgusu, daha dolgun ve çekici dudaklar elde etmek için
            yapılan kozmetik bir işlemdir. Hyaluronik asit veya kalsiyum
            hidroksiapatit gibi maddeler, ince bir iğne yardımıyla dudakların
            içine enjekte edilir. Bu işlem sayesinde, dudaklar hacim kazanır,
            sarkma ve kırışıklıklar giderilir, belirginleşir ve daha genç bir
            görünüm elde edilir.
          </p>

          <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
            <div>
              <Accordion flush open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="lips1">
                    Dudak Dolgusu Nasıl Yapılır?{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="lips1">
                    Dudak dolgusunda, öncelikle işlem alanı sterilize edilir ve
                    lokal anestezi uygulanır. Doktor, hyaluronik asit gibi bir
                    maddeyi ince bir iğne yardımıyla dudaklara enjekte eder.
                    Enjekte edilen dolgu maddesi, dudakların içindeki doğal
                    sıvılara benzer bir yapıya sahiptir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="lips2">
                    Dudak Dolgusu Yararları Nelerdir?{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="lips2">
                    Dudak dolgusu, dudaklardaki hacim kaybını ve sarkmayı
                    azaltır. Dudaklara daha dolgun bir görünüm kazandırır,
                    sıkılaştırır ve kırışıklıkları giderir. Ayrıca dudak
                    konturunu belirginleştirir ve daha genç bir görünüm sunar.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="lips3">
                    Dudak Dolgusu Öncesi: Hazırlık Süreci{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="lips3">
                    Dudak dolgusu öncesinde, hastanın herhangi bir ilaç alerjisi
                    veya ciddi bir sağlık problemi varsa doktorla paylaşması
                    gerekir. Tedavi öncesi mutlaka lokal anestezi uygulanır.
                    Dudak dolgusu yaptırmadan en az bir hafta öncesinde kan
                    sulandırıcı ilaçlar ve aspirin gibi maddelerin kullanımı
                    durdurulmalıdır. Ayrıca alkol ve sigaradan kaçınmak da
                    önemlidir.
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="lips4">
                    Dudak Dolgusu Sonrası Dikkat Edilmesi Gerekenler{" "}
                  </AccordionHeader>
                  <AccordionBody accordionId="lips4">
                    Dudak dolgusu sonrası, birkaç gün boyunca şişlik ve hafif
                    ağrılar hissedilebilir. Bunlar genellikle geçicidir ve
                    birkaç gün içinde kaybolur. İlk birkaç gün boyunca, sıcak
                    banyo, sauna veya spor yapmaktan kaçınmak gerekir. Ayrıca,
                    dolgu materyalinin tamamen yerleşmesi için birkaç gün
                    beklenmelidir.
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
            <img
              src={dudakDolgusuP}
              className=" w-full h-auto rounded-[10px]"
              alt="dudak-dolgusu-img"
            ></img>
          </div>

          <h1 className="operations-title1">
            Dudak Dolgusu Çeşitleri{" "}
          </h1>
          <p className="operations-text">
            Hyaluronik asit bazlı dolgular ve kalsiyum hidroksiapatit bazlı
            dolgular olmak üzere iki tür dudak dolgusu vardır. Hyaluronik asit
            bazlı dolgular, doğal bir malzeme olan hyaluronik asit içerir ve
            doğal bir sonuç elde etmek için kullanılırlar. Kalsiyum
            hidroksiapatit bazlı dolgular ise sentetik bir yapıya sahip olmakla
            birlikte daha kalıcı bir etkiye sahiptir ve daha fazla hacim elde
            etmek isteyen kişiler için uygundur.
          </p>
          <div className="">
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="lipsOption1">
                  Fransız Dudak Dolgusu{" "}
                </AccordionHeader>
                <AccordionBody accordionId="lipsOption1">
                  Fransız dudak dolgusu, dudaklara daha ince ve doğal bir
                  görünüm kazandıran bir dolgu tekniğidir. Hyaluronik asit bazlı
                  dolguların ince bir tabaka halinde dudaklara uygulanmasıyla
                  gerçekleştirilir. Bu teknik sayesinde, dudaklara daha doğal
                  bir görünüm kazandırılır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="lipsOption2">
                  Rus Dudak Dolgusu{" "}
                </AccordionHeader>
                <AccordionBody accordionId="lipsOption2">
                  Rus dudak dolgusu, dudak uçlarının daha belirgin hale
                  getirildiği ve dudaklara daha dolgun bir görünüm kazandıran
                  bir tekniktir. Hyaluronik asit bazlı dolguların uygulamasında,
                  dudak uçlarına yoğunlaşılır ve ağız çevresindeki kırışıklıklar
                  doldurulur.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <h1 className="operations-title1">Sık Sorulan Sorular</h1>
          <div className="">
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="ddsoru1">
                  Dudak dolgusu sonrası yapılmaması gerekenler nelerdir?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="ddsoru1">
                  Dudak dolgusu sonrası dikkat edilmesi gerekenler arasında,
                  dudakları sıkıca sıkma veya bastırmaktan kaçınma, aşırı
                  sıcaklık veya soğuğa maruz kalmama, dudaklara masaj yapmama
                  gibi unsurlar bulunur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ddsoru2">
                  Dudak dolgusu sonrası ödem ne zaman iner?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="ddsoru2">
                  Dudak dolgusu sonrasında ödem genellikle 1-2 hafta içinde
                  iner. Bu süre kişiden kişiye ve uygulanan dolgu maddesine
                  bağlı olarak değişebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ddsoru3">
                  Dudak dolgusu ne kadar kalıcıdır?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="ddsoru3">
                  Dudak dolgusunun etkisi kalıcı değildir. Genellikle 6-12 ay
                  arasında etkisini kaybedebilir. Ancak, bu süre değişebilir ve
                  genellikle kişinin metabolizmasına, dolgu maddesine ve
                  uygulama yöntemine bağlıdır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ddsoru4">
                  Dudak dolgusu ne kadar sürer?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="ddsoru4">
                  Dudak dolgusu işlemi genellikle 15-30 dakika arasında sürer.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ddsoru5">
                  Dudak dolgusu ne zaman oturur?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="ddsoru5">
                  Dudak dolgusu hemen sonuç vermez, genellikle 1-2 hafta içinde
                  oturur. Bu süre içerisinde dolgu maddesi ve dudak dokusu
                  adapte olur ve istenen sonuç elde edilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <hr className="operations-hr"></hr>
        </section>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">Burun Dolgusu </h1>
        <p className="operations-text">
          Burun dolgusu, burun şeklini iyileştirmek veya düzeltmek için
          kullanılan kozmetik bir işlemdir. Bu işlemde, dolgu maddesi burun
          bölgesindeki orantısızlıkları düzeltmek veya burna istenen şekli
          vermek için kullanılır. Dolgu maddesi, hyaluronik asit gibi doğal veya
          sentetik materyallerden oluşabilir. Bu yöntem, cerrahi müdahale
          gerektirmeden daha istenen bir görünüm elde etmek için tercih
          edilebilir.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <img
            src={burunDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="burun-dolgu-img"
          ></img>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="nose1">
                  Burun Dolgusu Nasıl Yapılır?{" "}
                </AccordionHeader>
                <AccordionBody accordionId="nose1">
                  Burun dolgusu yapılırken, öncelikle burun ve çevresi steril
                  hale getirilir. Ardından, anestezik krem veya lokal anestezi
                  uygulanabilir. Uygulama sırasında, dolgu maddesi bir
                  enjeksiyon iğnesi veya kanül aracılığıyla burun bölgesine
                  enjekte edilir. Dolgu maddesi, burun şeklini düzeltmek veya
                  doldurmak için istenen bölgelere enjekte edilir. Bu işlem
                  sırasında doktor, dikkatlice kontrol ederek ve uygun teknikler
                  kullanarak istenen sonucu elde etmek için dolgu maddesini
                  yerleştirir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="nose2">
                  Burun Dolgusu Ne Kadar Kalıcıdır?
                </AccordionHeader>
                <AccordionBody accordionId="nose2">
                  Burun dolgusunun etkisi genellikle geçicidir. Hyaluronik asit
                  bazlı dolgular genellikle 6 ay ila 2 yıl arasında etkili
                  olabilir. Diğer dolgu maddeleri ise daha uzun sürebilir. Ancak
                  kalıcılık süresi kişinin metabolizması, dolgu maddesinin
                  özellikleri ve uygulama tekniği gibi faktörlere bağlı olarak
                  değişiklik gösterebilir. Dolgu maddesinin etkisi zamanla
                  azalır ve yeniden enjeksiyon gerektirebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="nose3">
                  Burun Dolgusunun Yararları Nelerdir?
                </AccordionHeader>
                <AccordionBody accordionId="nose3">
                  • Burun dolgusu, burun şeklinde orta veya küçük ölçekte
                  düzeltmeler yaparak cerrahi müdahale gerektirmeyen bir seçenek
                  sunar.
                  <br />
                  • Burun dolgusu, burun köprüsü, burun ucu veya burun yanak
                  çizgilerinde hacim artışı sağlayarak daha dengeli ve simetrik
                  bir görünüm elde edilmesine yardımcı olur
                  <br />
                  • Dolgu maddeleri, burun şeklindeki orantısızlıkları
                  düzeltmek, çukur veya boşlukları doldurmak veya yara izlerini
                  gizlemek için kullanılabilir.
                  <br />• Bu işlem, daha hızlı bir iyileşme süresi sunar ve
                  minimum yan etkiye sahiptir. Üstelik cerrahi tedaviye kıyasla
                  daha az invazivdir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="nose4">
                  Burun Dolgusu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="nose4">
                  Burun dolgusu işlemine geçmeden önce doktor, burun şeklinizi
                  değerlendirir, beklentilerinizi anlar ve sizin için en uygun
                  tedavi planını belirler. Hazırlık sürecinde, doktorunuzun
                  vereceği talimatlara uymak önemlidir. Bunlar arasında kan
                  sulandırıcı ilaçlardan kaçınmak, alkol ve sigara kullanımını
                  sınırlandırmak, dolgunun nasıl uygulanacağına dair bilgileri
                  öğrenmek ve işlem öncesinde ihtiyaç duyacağınız herhangi bir
                  ilaç veya kremi kullanmak bulunabilir. Bu adımları takip
                  etmek, işlemin başarılı ve sorunsuz bir şekilde tamamlanması
                  için önemlidir
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="nose5">
                  Burun Dolgusu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="nose5">
                  <div>
                    • İşlem sonrasında şişlik, kızarıklık veya hassasiyet gibi
                    yan etkiler normaldir. Soğuk kompres uygulayarak şişlik
                    azaltılabilir.
                    <br />• Enjeksiyon yerlerine ve dolgu maddesine dokunmaktan
                    kaçınılmalıdır. Bu, enfeksiyon riskini azaltır ve dolgu
                    maddesinin yerinden kaymasını önler.
                    <br />• Yoğun egzersiz, sıcak duş veya hamam, uzun süreli
                    güneşe maruz kalmaktan kaçınılmalıdır. Bu, şişmeyi
                    artırabilir ve iyileşme sürecini olumsuz yönde
                    etkileyebilir.
                    <br />• Doktorun önerdiği şekilde ağız ve burun hijyenine
                    özen gösterilmelidir. İyileşme sürecinde işlem bölgesini
                    nazik bir şekilde temizlemek önemlidir.
                    <br />• İşlem sonrasında düşme veya travmaya karşı dikkatli
                    olunmalıdır. Mümkün olduğunca burun bölgesi korunmalıdır.
                    <br />• Doktorun talimatları takip edilmeli ve düzenli
                    şekilde kontrole gidilmelidir. Böylece işlem sonuçları
                    değerlendirilebilir ve ihtiyaç duyulduğunda ilave dolgu
                    uygulaması yapılabilir.
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        {/* <h1 className="operations-title1">
          Burun Dolgusu Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        <div>
          <p className="operations-text">
            <li className="mt-2">
              İşlem sonrasında şişlik, kızarıklık veya hassasiyet gibi yan
              etkiler normaldir. Soğuk kompres uygulayarak şişlik azaltılabilir.
            </li>
            <li className="mt-2">
              Enjeksiyon yerlerine ve dolgu maddesine dokunmaktan
              kaçınılmalıdır. Bu, enfeksiyon riskini azaltır ve dolgu maddesinin
              yerinden kaymasını önler.
            </li>
            <li className="mt-2">
              Yoğun egzersiz, sıcak duş veya hamam, uzun süreli güneşe maruz
              kalmaktan kaçınılmalıdır. Bu, şişmeyi artırabilir ve iyileşme
              sürecini olumsuz yönde etkileyebilir.
            </li>
            <li className="mt-2">
              Doktorun önerdiği şekilde ağız ve burun hijyenine özen
              gösterilmelidir. İyileşme sürecinde işlem bölgesini nazik bir
              şekilde temizlemek önemlidir.
            </li>
            <li className="mt-2">
              İşlem sonrasında düşme veya travmaya karşı dikkatli olunmalıdır.
              Mümkün olduğunca burun bölgesi korunmalıdır.
            </li>
            <li className="mt-2">
              Doktorun talimatları takip edilmeli ve düzenli şekilde kontrole
              gidilmelidir. Böylece işlem sonuçları değerlendirilebilir ve
              ihtiyaç duyulduğunda ilave dolgu uygulaması yapılabilir.
            </li>
          </p>
        </div> */}
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="bsoru1">
                Burun dolgusu burnu büyütür mü?{" "}
              </AccordionHeader>
              <AccordionBody accordionId="bsoru1">
                Burun dolgusu, burnun boyutunu büyütmez. Daha çok şeklini ve
                orantısını düzenlemek için kullanılır. Burun köprüsü veya burun
                ucu gibi bölgelerde hacim artışı sağlayabilir, ancak burnun
                boyutunu genel anlamda değiştirmez.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="bsoru2">
                Burun dolgusu yaş sınırı nedir?
              </AccordionHeader>
              <AccordionBody accordionId="bsoru2">
                Burun dolgusu genellikle 18 yaşından büyük bireylere uygulanır.
                Bu, burun yapısının tamamen gelişmiş olması ve dolgu maddesi
                kullanımına uygun olduğu anlamına gelir. Ancak, her bireyin
                durumu farklı olduğundan, doktorun önerilerine göre yaş sınırı
                belirlenebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="bsoru3">
                Burun dolgusu zararlı mıdır?{" "}
              </AccordionHeader>
              <AccordionBody accordionId="bsoru3">
                Burun dolgusu genellikle güvenli bir işlem olarak kabul edilir.
                Ancak, potansiyel riskler ve komplikasyonlar her tıbbi işlemde
                kaçınılmazdır. Enjeksiyon yerinde kızarıklık, şişlik, morarma
                gibi yan etkiler olabilir. Nadir durumlarda enfeksiyon, alerjik
                reaksiyonlar veya dolgu maddesinin yerinden kayması gibi ciddi
                komplikasyonlar da görülebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="bsoru4">
                Burun dolgusu ne işe yarar?
              </AccordionHeader>
              <AccordionBody accordionId="bsoru4">
                Burun dolgusu, çeşitli estetik nedenlerle kullanılabilir. Bunlar
                arasında burun şeklini düzeltmek, asimetriyi gidermek, burun
                köprüsü veya burun ucunu yükseltmek, çukurları veya boşlukları
                doldurmak veya yara izlerini gizlemek bulunur. Ayrıca, burun
                dolgusu daha simetrik bir burun görünümü elde etmek ve yüzün
                genel dengesini iyileştirmek için de kullanılabilir
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="bsoru5">
                Burun dolgusu kalıcı mıdır?{" "}
              </AccordionHeader>
              <AccordionBody accordionId="bsoru5">
                Burun dolgusu genellikle geçici bir çözümdür. Hyaluronik asit
                bazlı dolgular genellikle 6 ay ila 2 yıl arasında etkili
                olabilir. Diğer dolgu maddeleri daha uzun sürebilir, ancak
                kalıcılık süresi özellikle bireysel faktörlere bağlı olarak
                değişebilir. Dolgu maddesinin etkisi zamanla azalır ve düzenli
                olarak yenilenmesi gerekebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">
          Göz Altı Işık Dolgusu{" "}
        </h1>
        <p className="operations-text">
          Göz altı ışık dolgusu, göz altındaki koyu halkaları, torbaları ve ince
          çizgileri azaltmak için kullanılan minimal invaziv bir kozmetik
          prosedürdür. Bu işlem, genellikle hyaluronik asit gibi doğal
          maddelerin uygulama bölgesine enjekte edilmesiyle gerçekleştirilir. Bu
          dolgu maddeleri, cilde hacim kazandırır ve göz çevresindeki deriye
          daha aydınlık bir görünüm verir.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="gz1">
                  Göz Altı Işık Dolgusu Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="gz1">
                  Göz altı ışık dolgusu, uzman bir estetik doktor veya
                  dermatolog tarafından klinik ortamda gerçekleştirilir. İşlem
                  genellikle lokal anestezi veya topikal anestezik kremlerle
                  yapılır ve hasta için minimal rahatsızlık sunar. İşlem
                  sırasında, doktor ince bir iğne yardımıyla dolgu maddesini göz
                  altındaki belirli bölgelere enjekte eder. Prosedür genellikle
                  15 ila 30 dakika sürer ve hasta günlük aktivitelerine hızlı
                  bir şekilde geri dönebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gz2">
                  Göz Altı Işık Dolgusunun Yararları Nelerdir?
                </AccordionHeader>
                <AccordionBody accordionId="gz2">
                  <b className="font-bold">• Koyu Halkaların Azalması:</b> Göz
                  altındaki koyu halkaların ve morlukların azalmasına yardımcı
                  olur, daha aydınlık bir cilt tonu sağlar. <br />
                  <b className="font-bold">• Torbaların Giderilmesi:</b> Göz
                  altındaki torbaları ve şişlikleri azaltır, daha dinç ve genç
                  bir görünüm sunar.
                  <br />
                  <b className="font-bold">
                    • İnce Çizgilerin Doldurulması:
                  </b>{" "}
                  İnce çizgilerin ve kırışıklıkların görünümünü azaltır, cildin
                  daha pürüzsüz ve genç görünmesini sağlar.
                  <br />
                  <b className="font-bold">• Doğal Görünüm:</b> Doğru şekilde
                  uygulandığında, göz altı ışık dolgusu doğal bir görünüm sunar
                  ve yüz hatlarını dengelemeye yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gz4">
                  Göz Altı Işık Dolgusu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="gz4">
                  Göz altı ışık dolgusu öncesinde, doktorunuzla bir danışma
                  randevusu ayarlamak önemlidir. Bu randevu sırasında,
                  doktorunuz size prosedür hakkında bilgi verecek, beklediğiniz
                  sonuçları tartışacak ve muhtemel riskleri ve yan etkileri
                  açıklayacaktır. Ayrıca, alerjik reaksiyon riskini azaltmak
                  için mevcut ilaçlarınızı ve sağlık durumunuzu doktorunuzla
                  paylaşmanız gerekir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="gz5">
                  Göz Altı Işık Dolgusu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="gz5">
                  <div>
                    <b className="font-bold">• Soğuk Kompres Uygulaması:</b>{" "}
                    İşlem sonrası oluşabilecek hafif şişlik ve kızarıklığı
                    azaltmak için soğuk kompres uygulanmalıdır. <br />
                    <b className="font-bold">• Makyajdan Kaçınma:</b> İşlem
                    sonrası birkaç gün boyunca göz altı bölgesine makyaj
                    uygulamaktan kaçınılmalıdır.
                    <br />
                    <b className="font-bold">• Yan Etkileri Bildirme:</b> İşlem
                    sonrası herhangi bir anormal semptom veya yan etki fark
                    edildiğinde, derhal ilgili doktora başvurulmalıdır.
                    <br />
                    <b className="font-bold">• Güneş Koruması:</b> İşlem sonrası
                    cilt hassas olabileceğinden güneşe maruz kalma süresi
                    sınırlandırılmalı ve güneş koruyucu kullanılmalıdır.
                    <br />
                    <b className="font-bold">
                      • Doktorun Talimatlarına Uyma:
                    </b>{" "}
                    İlgili doktorun işlem sonrası bakım talimatlarına uyulmalı
                    ve belirtilen takip randevuları kaçırılmamalıdır.
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <img
            src={gozAltıDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="goz-alti-dolgu-img"
          ></img>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="gzsoru1">
                Göz altı ışık dolgusu sonrası şişlik ne zaman iner?
              </AccordionHeader>
              <AccordionBody accordionId="gzsoru1">
                Göz altı ışık dolgusu sonrasında hafif bir şişlik ve kızarıklık
                görülebilir. Bu yan etkiler genellikle birkaç gün içinde
                kendiliğinden geçer.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gzsoru2">
                Göz altı ışık dolgusu kırışıklıklara iyi gelir mi?
              </AccordionHeader>
              <AccordionBody accordionId="gzsoru2">
                Göz altı ışık dolgusu, göz altındaki ince çizgilerin ve
                kırışıklıkların görünümünü azaltmaya yardımcı olabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gzsoru3">
                Göz altı ışık dolgusu kaç seanstır?
              </AccordionHeader>
              <AccordionBody accordionId="gzsoru3">
                Göz altı ışık dolgusu genellikle tek seansta yapılır. Ancak göz
                altı bölgesindeki sorunun şiddetine ve kişinin beklentilerine
                göre birden fazla seansta da uygulanabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gzsoru4">
                Göz altı ışık dolgusu yaş sınırı nedir?
              </AccordionHeader>
              <AccordionBody accordionId="gzsoru4">
                Göz altı ışık dolgusu, genellikle 18 yaşından küçüklerde
                önerilmez. Uygulama için kesin bir yaş sınırı olmasa da kişinin
                cilt yapısı, sağlık durumu ve beklentileri göz önüne alınarak
                uzman tarafından değerlendirme yapılır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="gzsoru5">
                Göz altı ışık dolgusu kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="gzsoru5">
                Göz altı ışık dolgusunun kalıcılık süresi kişiden kişiye
                değişir. Ortalama etki süresi 8-15 ay arasındadır.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">
          Elmacık Kemiği Dolgusu{" "}
        </h1>
        <p className="operations-text">
          Elmacık kemiği dolgusu, yüzdeki elmacık kemiklerinin
          belirginleştirilmesi veya istenen bölgelerin dolgunlaştırılması
          amacıyla uygulanan kozmetik bir işlemdir. Bu işlem genellikle
          hyaluronik asit gibi dolgu maddeleri kullanılarak gerçekleştirilir.
          Elmacık kemiği dolgusu, yüzün simetrisini düzeltmek, daha genç ve
          canlı bir görünüm elde etmek veya yüzdeki çeşitli deformasyonları
          gidermek için tercih edilir.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <img
            src={elmacıkDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="elmacik-dolgu-img"
          ></img>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="ek1">
                  Elmacık Kemiği Dolgusu Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="ek1">
                  Elmacık kemiği dolgusu, genellikle uzman bir hekim veya
                  estetik uzman tarafından yapılır. İşlem öncesi hastanın
                  beklentileri ve ihtiyaçları değerlendirilir. Ardından
                  aşağıdaki adımlar izlenir:
                  <br />
                  <b className="font-bold">• Başlangıç muayenesi:</b> Elmacık
                  kemiği dolgusu yapmadan önce, kişinin yüz yapısı, cilt sağlığı
                  ve istekleri değerlendirilir. Uzman, kişiye en uygun dolgu
                  maddesini ve miktarını seçer.
                  <br />
                  <b className="font-bold">• İşaretleme:</b> Dolgu maddesinin
                  hangi bölgelere uygulanacağı belirlenir ve işaretlenir.
                  <br />
                  <b className="font-bold">• Uygulama:</b> Dolgu maddesi,
                  elmacık kemiği üzerine yerleştirilir ve istenen dolgunluğa ve
                  simetriye ulaşmak için dikkatlice uygulanır.
                  <br />
                  <b className="font-bold">• Kontrol:</b> İşlem tamamlandıktan
                  sonra, doktor veya estetik uzmanı sonuçları kontrol eder ve
                  gerekli olması durumunda son düzeltmeleri yapar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ek2">
                  Elmacık Kemiği Dolgusunun Yararları Nelerdir?
                </AccordionHeader>
                <AccordionBody accordionId="ek2">
                  <b className="font-bold">• Belirginleştirme:</b> Elmacık
                  kemiği dolgusu, elmacık kemiklerinin daha belirgin bir hale
                  getirilmesine yardımcı olur ve yüzün çekici bir görünüm
                  kazanmasına katkıda bulunur.
                  <br />
                  <b className="font-bold">• Daha genç bir görünüm:</b> Elmacık
                  kemiği dolgusu, yaşlanma süreciyle kaybedilen hacmin geri
                  kazanılmasına yardımcı olur, yüzdeki sarkmaların
                  azaltılmasıyla daha genç ve canlı bir görünüm sağlar.
                  <br />
                  <b className="font-bold">
                    • Yüzdeki asimetrinin düzeltilmesi:
                  </b>{" "}
                  Elmacık kemiği dolgusu, yüzdeki asimetri veya eşitsizliklerin
                  giderilmesine yardımcı olabilir, yüz hatlarının daha dengeli
                  ve uyumlu görünmesini sağlar.
                  <br />
                  <b className="font-bold">• Daha dolgun yanaklar:</b> Elmacık
                  kemiği dolgusu, yanak bölgelerindeki dolgunluğu artırarak
                  yüzün daha genç ve sağlıklı bir görünüm kazanmasını sağlar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ek3">
                  Elmacık Kemiği Dolgusu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="ek3">
                  <b className="font-bold">• Muayene:</b> Bir uzman tarafından
                  ayrıntılı bir muayene yapılır ve kişinin ihtiyaçları ve
                  beklentileri değerlendirilir. İnceleme ve görüşmeler sonucunda
                  dolgu maddesi ve miktarı belirlenir.
                  <br />
                  <b className="font-bold">• Kan sulandırıcılar:</b> Eğer kişi
                  düzenli olarak kan sulandırıcı ilaçlar kullanıyorsa, bu
                  ilaçları kullanmayı bırakması gerekebilir. Uzmanın tavsiyeleri
                  doğrultusunda gerekli önlemler alınmalıdır.
                  <br />
                  <b className="font-bold">• İşlem öncesi talimatlar:</b> Uzman
                  tarafından verilen talimatlara uymak önemlidir. Örneğin, işlem
                  öncesinde makyaj yapmamak veya belirli yiyecek ve içeceklerden
                  kaçınmak gerekebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ek4">
                  Elmacık Kemiği Dolgusu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="ek4">
                  <b className="font-bold"> • İzlem:</b> İşlem sonrası
                  oluşabilecek herhangi bir komplikasyonu gözlemlemek için
                  uzmanın tavsiyelerine uymak önemlidir.
                  <br />
                  <b className="font-bold">• Soğuk kompres:</b> İşlem sonrası
                  şişlik ve morlukları azaltmak için soğuk kompres uygulamak
                  faydalı olabilir.
                  <br />
                  <b className="font-bold">• Makyaj:</b> İşlem sonrası belirli
                  bir süre makyaj yapmaktan kaçınılması gerekebilir.
                  <br />
                  <b className="font-bold">• Güneş koruması:</b> İşlem
                  sonrasında güneşe maruz kalmaktan kaçınmak ve güneş koruyucu
                  kullanmak önemlidir. Bu sayede cilt tahrişini önlenebilir ve
                  işlemin etkilerini korunabilir.
                  <br />
                  <b className="font-bold">• Spor ve ağır aktiviteler:</b> İşlem
                  sonrasında aşırı fiziksel aktivitelerden ve ağır spor
                  aktivitelerinden kaçınılması önerilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="esoru1">
                Elmacık kemiği dolgusu sonrası yapılmaması gerekenler nelerdir?
              </AccordionHeader>
              <AccordionBody accordionId="esoru1">
                Elmacık kemiği dolgusu sonrası, işlem bölgesine masaj yapmak
                veya ovuşturmak, aşırı sıcak veya soğuk uygulamak, aşırı
                fiziksel aktivitelerde bulunmak veya ağır spor aktiviteleri
                yapmak, makyaj yapmak veya cildi tahriş edebilecek ürünler
                kullanmak önerilmez. Ayrıca, doktorun önerisi olmadan ek bir
                işlem veya tedavi yapmaktan da kaçınılmalıdır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="esoru2">
                Elmacık kemiği dolgusu ne kadar kalıcıdır?
              </AccordionHeader>
              <AccordionBody accordionId="esoru2">
                Elmacık kemiği dolgusunun kalıcılığı dolgu maddesine bağlı
                olarak değişir. Genellikle hyaluronik asit dolgu maddeleri 6 ila
                18 ay arasında kalıcı olabilir. Ancak, bireysel faktörlere ve
                kişinin yaşam tarzına bağlı olarak dolgunun süresi değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="esoru3">
                Elmacık kemiği dolgusu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="esoru3">
                Elmacık kemiği dolgusu işlemi, genellikle 30 ila 45 dakika
                arasında tamamlanır. Ancak, işlem süresi kişinin ihtiyaçlarına,
                kullanılan dolgu maddesinin miktarına ve uygulanacak bölgeye
                bağlı olarak değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="esoru4">
                Elmacık kemiği dolgusu kaç günde oturur?
              </AccordionHeader>
              <AccordionBody accordionId="esoru4">
                Elmacık kemiği dolgusu uygulamasının sonuçları genellikle hemen
                görülür. Ancak, dolgu maddesinin tam olarak yerleşmesi ve son
                halini alması birkaç gün sürebilir. İşlem sonrasında hafif bir
                şişlik veya kızarıklık olabilir, ancak bunlar genellikle birkaç
                gün içerisinde kaybolur
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="esoru5">
                Elmacık kemiği dolgusu ne işe yarar?
              </AccordionHeader>
              <AccordionBody accordionId="esoru5">
                Elmacık kemiği dolgusu yüzün daha belirgin, simetrik ve genç bir
                görünüm kazanmasını sağlar. Bu işlem elmacık kemiklerinin
                belirginleştirilmesine, yetersiz bölgelerin dolgunlaştırılmasına
                ve yüzdeki asimetri veya eşitsizliklerin düzeltilmesine yardımcı
                olabilir. Elmacık kemiği dolgusu aynı zamanda yüzün yaşlanma
                belirtilerini azaltmaya yardımcı olabilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">Çene Dolgusu</h1>
        <p className="operations-text">
          Çene dolgusu, çene hattını belirginleştirmek, düzeltmek veya
          şekillendirmek için yapılan kozmetik bir prosedürdür. Genellikle
          hyaluronik asit gibi dolgu maddeleri kullanılarak gerçekleştirilir. Bu
          dolgular, çeneye hacim kazandırmak ve istenilen estetik görünümü elde
          etmek için kullanılır.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="c1">
                  Çene Dolgusu Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="c1">
                  <b className="font-bold">• Muayene ve Danışma:</b> Elmacık
                  kemiği dolgusu yapmadan önce, İlk adım, bir estetik doktora
                  veya dermatoloğa danışmaktır. Uzman, hastanın beklentilerini
                  değerlendirir ve uygun tedavi planını belirler.
                  <br />
                  <b className="font-bold">• Anestezi:</b> İşlem öncesi, çene
                  bölgesi lokal anestezi ile uyuşturulur, böylece hastada
                  herhangi bir acı veya rahatsızlık hissetmez.
                  <br />
                  <b className="font-bold">• Dolgu Enjeksiyonu:</b> Uyuşturma
                  işleminden sonra, doktor belirlenen bölgelere hyaluronik asit
                  veya farklı bir dolgu maddesini enjekte eder. Enjeksiyonlar,
                  çene hattını belirginleştirmek ve istenilen şekli elde etmek
                  için stratejik bir şekilde uygulanır
                  <br />
                  <b className="font-bold">
                    • Son Kontrol ve Bakım Önerileri:
                  </b>{" "}
                  İşlem tamamlandıktan sonra, doktor işlem sonuçlarını kontrol
                  eder ve hastaya çene dolgusu sonrası bakım talimatları verir.
                  .
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="c2">
                  Çene Dolgusunun Yararları Nelerdir?
                </AccordionHeader>
                <AccordionBody accordionId="c2">
                  <b className="font-bold">• Çene Hattını Belirginleştirme:</b>{" "}
                  İşlem, çene hattını daha belirgin hale getirerek yüzün estetik
                  görünümünü iyileştirir.
                  <br />
                  <b className="font-bold">• Simetri ve Denge Sağlama:</b> Çene
                  dolgusu, yüzdeki simetriyi artırır ve genel yüz simetrisini
                  dengeler.
                  <br />
                  <b className="font-bold">• Genç ve Canlı Görünüm:</b> Belirgin
                  bir çene hattı, genç ve canlı bir görünüm sunar.
                  <br />
                  <b className="font-bold">• Özgüveni Artırma:</b> Daha belirgin
                  bir çene hattı, kişinin kendine olan güvenini artırır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="c3">
                  Çene Dolgusu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="c3">
                  <b className="font-bold">• Uzman Görüşü Alınması:</b> İşlem
                  öncesi, bir estetik doktora veya dermatoloğa danışılmalıdır.
                  Uzman, hastanın beklentilerini değerlendirecek ve uygun tedavi
                  planını belirleyecektir.
                  <br />
                  <b className="font-bold">• Alerji Testi:</b> Dolgu maddesine
                  karşı alerjik reaksiyon riskini azaltmak için, işlem öncesinde
                  bir alerji testi yapılabilir.
                  <br />
                  <b className="font-bold">• İlaç ve Beslenme:</b>
                  İşlem öncesinde, kan inceltici ilaçlar ve bazı takviyelerin
                  alımının durdurulması gerekebilir. Doktorun talimatlarına
                  uygun beslenme ve ilaç kullanımı önemlidir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="c4">
                  Çene Dolgusu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="c4">
                  <b className="font-bold">• İyileşme Süreci</b>: İşlem sonrası
                  çene bölgesinde hafif şişlik, kızarıklık veya hassasiyet
                  olabilir. Bu normaldir ve genellikle birkaç gün içinde geçer.
                  <br />
                  <b className="font-bold">
                    • Doktorun Talimatlarına Uyma:
                  </b>{" "}
                  İşlem sonrası, doktorun verdiği talimatlara uyulmalıdır. Bu
                  talimatlar, şişliği azaltmak, enfeksiyon riskini gidermek ve
                  iyileşme sürecini hızlandırmak açısından son derece önemlidir
                  <br />
                  <b className="font-bold">• Ağır Egzersizden Kaçınma:</b> İşlem
                  sonrası ilk birkaç gün ağır egzersizlerden kaçınılmalıdır,
                  çünkü bu çene bölgesindeki şişliği artırabilir.
                  <br />
                  <b className="font-bold">• Soğuk Kompres:</b> Doktorun
                  önerisiyle, işlem sonrası soğuk kompres uygulanabilir. Bu,
                  şişliği ve rahatsızlığı azaltabilir.
                  <br />
                  <b className="font-bold">
                    • Ağrı ve Rahatsızlık Durumunda Doktora Başvurma:
                  </b>
                  İşlem sonrası ağrı veya rahatsızlığın birkaç gün devam etmesi
                  durumunda, derhal doktora başvurulmalıdır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <img
            src={ceneDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="cene-dolgu-img"
          ></img>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="csoru1">
                Çene dolgusu ne kadar kalıcıdır?
              </AccordionHeader>
              <AccordionBody accordionId="csoru1">
                Çene dolgusu genellikle geçici bir yöntemdir. Hyalüronik asit
                bazlı dolgu malzemeleri genellikle 6 ila 18 ay arasında
                dayanırken, kalsiyum hidroksiapatit gibi dolgular 12 ila 24 ay
                arasında kalıcı olabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="csoru2">
                Çene dolgusu sonrası ağrı ne zaman geçer?
              </AccordionHeader>
              <AccordionBody accordionId="csoru2">
                Çene dolgusu işleminden sonra hafif ağrı veya rahatsızlık hissi
                olabilir. Bu genellikle birkaç gün içinde geçer. İşlem sonrası
                ağrıyı hafifletmek için doktorun önerdiği ağrı kesiciler
                kullanılabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="csoru3">
                Çene dolgusu kaç günde oturur?
              </AccordionHeader>
              <AccordionBody accordionId="csoru3">
                Çene dolgusu uygulandıktan sonra dolgunun oturması birkaç gün
                sürebilir. Fakat dolgunun tam oturması için 2 ila 4 hafta kadar
                beklemek gerekebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="csoru4">
                Çene dolgusu ne işe yarar?
              </AccordionHeader>
              <AccordionBody accordionId="csoru4">
                Çene dolgusu, estetik ve kozmetik amaçlarla uygulanan bir
                işlemdir. Çene bölgesine enjekte edilen dolgu maddeleri, çenenin
                belirginleştirilmesine, şekillendirilmesine ve yüz hatlarının
                daha simetrik bir görünüm kazanmasına yardımcı olur. Böylece
                yüzün estetik olarak daha dengeli ve çekici görünmesini sağlar.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="csoru5">
                Çene dolgusu gıdıyı yok eder mi?
              </AccordionHeader>
              <AccordionBody accordionId="csoru5">
                Çene dolgusu, çene hattını belirginleştirdiği ve daha simetrik
                hale getirdiği için gıdı görünümünü azaltabilir. Ancak, dolgu
                işlemi gıdıyı tamamen ortadan kaldırmaz. Gıdı görünümünü tam
                olarak azaltmak veya yok etmek için farklı yöntemlerin
                (liposuction gibi) kullanılması gerekebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">Şakak Dolgusu</h1>
        <p className="operations-text">
          Şakak dolgusu, şakak bölgesine hyalüronik asit gibi dolgu maddelerinin
          enjekte edilmesiyle uygulanan estetik bir işlemdir. Bu işlem,
          şakakların daha dolgun ve genç görünmesini sağlamak için uygulanır.
          Şakak dolgusu, yüz hatlarının dengelemesine yardımcı olurken, yaşlanma
          etkilerini azaltarak daha genç bir görünüm elde etmeyi hedefler.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <img
            src={sakakDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="sakak-dolgu-img"
          ></img>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="sd1">
                  Şakak Dolgusu Ne İşe Yarar?
                </AccordionHeader>
                <AccordionBody accordionId="sd1">
                  Şakak dolgusu, çeşitli amaçlarla uygulanabilir ve çeşitli
                  faydalar sağlayabilir. İşlemin temel amaçlarından bazıları
                  şunlardır:
                  <br />
                  • Şakak bölgesinin hacmini ve dolgunluğunu artırarak yüz
                  hattının dengelenmesi
                  <br />
                  • Şakakta oluşan çukurların ve deformasyonların düzeltilmesi
                  <br />
                  • Şakak bölgesindeki yaşlanma etkilerini hafifletmek ve daha
                  genç bir görünüm sağlamak
                  <br />
                  • Şakak kemiğinin belirginliğini artırarak yüz simetrisini
                  iyileştirmek
                  <br />
                  Yaşlanma süreciyle birlikte şakak bölgesi hacmini kaybedebilir
                  ve çöküntüler oluşabilir. Şakak dolgusu, bu sorunları gidermek
                  ve şakaklara daha genç, canlı bir görünüm kazandırmak için
                  etkili bir seçenektir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="sd2">
                  Şakak Dolgusu Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="sd2">
                  Şakak dolgusu işlemi genellikle bir estetik cerrah veya
                  medikal uzman tarafından steril bir ortamda uygulanır. İşlem
                  öncesi bölge uyuşturulur. Ardından, dolgu maddesi dikkatlice
                  şakak bölgesine enjekte edilir. İşlem sırasında bir miktar
                  masaj yapılabilir ve dolgunun doğru bir şekilde dağılması
                  sağlanabilir. İşlem genellikle kısa bir sürede tamamlanır ve
                  sonuçlar hemen görülebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="sd3">
                  Şakak Dolgusu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="sd3">
                  Şakak dolgusu işlemine karar verildiğinde, bir uzmanla
                  görüşerek işlem hakkında bilgi alınması önemlidir. Uzman,
                  sağlık geçmişini ve mevcut ilaç kullanımını değerlendirir.
                  İşlem öncesi bazı önlemlerin alınması gerekebilir. Bunlar
                  arasında kan sulandırıcı ilaçlar ve alkol tüketiminden
                  kaçınma, işlem günü makyajsız gelme ve işlem sonrası dikkat
                  edilmesi gereken hususlar hakkında ön bilgilendirme yer alır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="sd4">
                  Şakak Dolgusu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="sd4">
                  İşlem sonrası hafif şişlik, kızarıklık veya hassasiyet
                  olabilir, ancak bunlar genellikle kısa bir sürede geçer. İşlem
                  sonrasında ağır egzersizlerden, sıcak banyo veya saunadan
                  kaçınılmalıdır. Ayrıca doktorun önerdiği talimatlara uyulmalı,
                  düzenli kontrol randevularını kaçırılmamalı ve gerekli
                  durumlarda ilgili doktora başvurulmalıdır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="ssoru1">
                Şakak dolgusu kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="ssoru1">
                Hayır, şakak dolgusu geçici bir işlemdir ve etkileri ortalama
                6-12 ay arasında sürer.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="ssoru2">
                Şakak dolgusu tehlikeli midir?
              </AccordionHeader>
              <AccordionBody accordionId="ssoru2">
                Şakak dolgusu genellikle güvenli bir işlemdir, ancak her estetik
                işlemde olduğu gibi riskler bulunmaktadır. Yan etkiler nadir
                olmakla birlikte, işlem öncesi bir uzmandan bilgi almak
                önemlidir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="ssoru3">
                Şakak dolgusu ne zaman oturur?
              </AccordionHeader>
              <AccordionBody accordionId="ssoru3">
                Şakak dolgusu işleminden hemen sonra bazı sonuçlar görülebilir,
                ancak dolgunun tam oturması ve istenilen sonuçların görünmesi
                1-2 hafta kadar sürebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="ssoru4">
                Şakak dolgusu yüzü kaldırır mı?
              </AccordionHeader>
              <AccordionBody accordionId="ssoru4">
                Şakak dolgusu, yüzde dolgunluk sağlayarak yüz hatlarının
                dengelemesine yardımcı olabilir. Ancak yüz kaldırma işlemi ile
                karıştırılmamalıdır. Yüzü kaldırmak için ip askı gibi başka
                prosedürler mevcuttur.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="ssoru5">
                Şakak dolgusu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="ssoru5">
                Şakak dolgusu işlemi genellikle 15-30 dakika sürer. İşlem
                sonrası hafif bir şişlik ve kızarıklık olabilir, ancak
                genellikle kısa sürede iyileşir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">Nazolabial Dolgu</h1>
        <p className="operations-text">
          Nazolabial dolgu, burun ile dudaklar arasındaki bölgede yer alan
          nazolabial kıvrımın yani burun-yanak hattının daha dolgun ve genç
          görünmesini sağlamak için yapılan estetik bir işlemdir. Bu işlemde
          genellikle hyalüronik asit bazlı dolgu maddeleri kullanılır.
          Nazolabial bölge, yaşlanma ve cilt elastikiyetinin azalmasıyla
          birlikte derinleşebilir ve yüzdeki gençlik ve canlılık hissini
          azaltabilir. Nazolabial dolgu işlemi, bu etkileri dengeler ve yüz
          hatlarına daha genç ve canlı bir görünüm kazandırır.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="n1">
                  Nazolabial Dolgu Ne İşe Yarar?
                </AccordionHeader>
                <AccordionBody accordionId="n1">
                  <b className="font-bold">
                    • Nazolabial kıvrımların dolgunlaştırılması:
                  </b>{" "}
                  Yaşlanma süreciyle birlikte nazolabial kıvrımlarda derinleşme
                  ve çizgilenme görülebilir. Dolgu maddeleri bu bölgedeki hacim
                  kaybını doldurur ve yüz hatlarını yeniden şekillendirerek daha
                  estetik bir görünüm sağlar.
                  <br />
                  <b className="font-bold">
                    • Yüzde gençlik hissi yaratma:
                  </b>{" "}
                  Nazolabial dolgu, burun-yanak hattındaki orantısızlıkları
                  düzelterek yüze daha canlı ve genç bir görünüm kazandırır.
                  <br />
                  <b className="font-bold">• Yüz hatlarını dengeleme:</b>{" "}
                  Nazolabial dolgu, yüz hatlarını dengelemek ve simetrisini
                  geliştirmek için kullanılabilir. Bu işlem, burun ve yanaklar
                  arasındaki geçişteki keskinliği yumuşatarak daha uyumlu bir
                  görünüm sunar.
                  <br />
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="n2">
                  Nazolabial Dolgu Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="n2">
                  <b className="font-bold">• Konsültasyon:</b> İşlem öncesinde
                  bir uzman tarafından konsültasyon yapılır. Burada sağlık
                  durumu, beklentileri ve hangi dolgu maddesinin kullanılacağı
                  gibi konular konuşulur
                  <br />
                  <b className="font-bold">• Uyuşturma:</b> İşlem öncesinde
                  nazolabial bölgeye lokal anestezik bir krem uygulanır veya
                  cilt içine ince bir iğneyle lokal anestezi yapılır. Bu sayede
                  işlem sırasında ağrı veya rahatsızlık hissi minimuma
                  indirgenir.
                  <br />
                  <b className="font-bold">• Enjeksiyon:</b> İşlem sırasında
                  seçilen dolgu maddesi nazolabial bölgeye ince bir iğne veya
                  kanül ile enjekte edilir. Bu dolgu maddesi nazolabial kıvrımı
                  doldurarak hacim kazandırır ve derin çizgileri düzeltir.
                  <br />
                  <b className="font-bold">• Şekillendirme:</b> İşlem yapılan
                  bölgeye hafif bir masaj yapılır. Bu, dolgunun doğru şekilde
                  dağılmasını sağlamak ve en iyi sonucu elde etmek amacıyla
                  uygulanır.
                  <br />
                  <b className="font-bold">• Son değerlendirme:</b> İşlem
                  tamamlandıktan sonra doktor, dolgunun etkisini ve sonucunu
                  değerlendirir. Gerektiğinde ek enjeksiyonlar yapabilir veya
                  önerilerde bulunabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="n3">
                  Nazolabial Dolgu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="n3">
                  <b className="font-bold">• Optimal sağlık durumu:</b>{" "}
                  Nazolabial dolgunun güvenli bir şekilde uygulanabilmesi için
                  optimal bir sağlık durumu gerekir.
                  <br />
                  <b className="font-bold">• İlaç kullanımı:</b> Nazolabial
                  dolgu işleminden önce, kullanılan tüm ilaçlar, vitaminler,
                  takviyeler ve bitkisel ürünler doktor ile paylaşılmalıdır.
                  Bazı ilaçlar, kan inceltici etkilere sahip olabilir ve dolgu
                  maddesi ile birleşerek olumsuz etkiler oluşturabilir.
                  <br />
                  <b className="font-bold">
                    • Beklentilerin paylaşılması:
                  </b>{" "}
                  İşlem öncesinde, prosedürden ne beklendiği net bir şekilde
                  paylaşılmalıdır. Bu, en uygun uygulama planının belirlenmesine
                  yardımcı olur.
                  <br />
                  <b className="font-bold">• Alkol ve sigara kullanımı:</b>{" "}
                  Nazolabial dolgu işleminden önce alkolden uzak durulması
                  gerekebilir. Alkol ve sigara, işlem sonrası iyileşme sürecini
                  etkileyebilir ve yan etkileri artırabilir.
                  <br />
                  <b className="font-bold">
                    • İşlem sonrası düzenlemeler:
                  </b>{" "}
                  Nazolabial dolgu işlemi sonrası bazı ek düzeltmelere ihtiyaç
                  duyulabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="n4">
                  Nazolabial Dolgu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="n4">
                  <b className="font-bold">• İlk birkaç gün:</b> İşlem sonrası
                  birkaç gün boyunca şişlik, kızarıklık, hafif ağrı veya
                  hassasiyet gibi hafif yan etkiler normaldir. Bu semptomlar
                  genellikle kısa süre içinde kendiliğinden kaybolur. Ancak
                  belirgin ağrı, yüksek ateş veya anormal şişlik durumunda
                  ilgili doktora başvurulmalıdır.
                  <br />
                  <b className="font-bold">• İyileşme süreci:</b> İşlem sonrası
                  hızlı bir iyileşme süreci beklenebilir. Ancak işlem sonrası
                  şişlik veya morluklar birkaç gün sürebilir. Nazolabial
                  bölgedeki dolgunun tam olarak oturması ve istenen sonuçların
                  görülmesi birkaç hafta sürebilir. Dolgunun beklenen sonuçları
                  gösterebilmesi için sabırlı olmak önemlidir.
                  <br />
                  <b className="font-bold">• Uyku pozisyonu:</b> İşlem sonrası
                  uyku pozisyonuna dikkat etmek önemlidir. İlk birkaç gece sırt
                  üstü uyumak ve başı hafifçe yüksekte tutmak, şişlik ve morluk
                  riskini azaltmaya yardımcı olabilir.
                  <br />
                  <b className="font-bold">• Güneş koruması:</b> İşlem sonrası
                  güneşe maruz kalmaktan kaçınmak önemlidir. Güneş ışınları
                  dolgu maddesini olumsuz etkileyebilir ve cilde zarar
                  verebilir. İşlem sonrası güneş koruyucu krem kullanmak ve
                  güneşe çıkarken şapka takmak önerilir.
                  <br />
                  <b className="font-bold">• İşlem sonrası kontroller:</b> İşlem
                  sonrası, doktorun belirlediği kontrollerin düzenli olarak
                  yapılması gerekir. Bu kontrollerde işlem sonucu
                  değerlendirilir ve gerektiğinde ek düzeltmeler yapılabilir.
                  <br />
                  <b className="font-bold">• Yan etkiler:</b> İşlem sonrasında
                  herhangi bir anormal durum fark edilirse, derhal ilgili
                  doktora başvurulmalıdır. Bu, anormal ağrı, yüzde belirgin bir
                  şişlik veya kızarıklık, ateş veya çürük oluşumu gibi durumları
                  içerebilir.
                  <br />
                  <b className="font-bold">• Beklenmeyen sonuçlar:</b> İşlem
                  sonucunda beklentileri karşılamayan veya istenmeyen sonuçlarla
                  karşılaşıldığında, tıbbi uzmanla iletişime geçmek önemlidir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <img
            src={nazoLabialDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="nazolabia-dolgu-img"
          ></img>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="nsoru1">
                Nazolabial dolgu sonrası topaklanma ne zaman geçer?
              </AccordionHeader>
              <AccordionBody accordionId="nsoru1">
                Topaklanma, dolgunun enjekte edildikten sonra birkaç gün içinde
                oturmasıyla azalabilir. Ancak nadiren de olsa, topaklanma
                nedeniyle dolgunun yeniden şekillenmesi için ek bir tedavi
                gerekebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="nsoru2">
                Nazolabial dolgu ne kadar kalıcıdır?
              </AccordionHeader>
              <AccordionBody accordionId="nsoru2">
                Nazolabial dolgunun kalıcılığı kişisel olarak değişebilir.
                Genellikle 9 ile 18 ay arasında kalıcıdır, ancak bazı durumlarda
                birkaç yıl bile sürebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="nsoru3">
                Nazolabial dolgu kaç günde oturur?
              </AccordionHeader>
              <AccordionBody accordionId="nsoru3">
                Dolgunun tam olarak oturması genellikle birkaç gün sürer. Ancak
                tamamen oturması ve en iyi sonuçları vermesi için ortalama bir
                hafta beklemek gerekir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="nsoru4">
                Nazolabial zararlı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="nsoru4">
                Nazolabial dolgu, uzman bir doktor tarafından yapıldığında
                genellikle güvenilirdir. Ancak, herhangi bir tıbbi tedavi gibi
                bazı riskleri vardır. En iyi sonuçlar için yalnızca sertifikalı
                ve deneyimli bir sağlık uzmanının tercih edilmesi önemlidir
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="nsoru5">
                Nazolabial dolgu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="nsoru5">
                Nazolabial dolgu işlemi yaklaşık 15 dakika sürer.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">Alın Dolgusu</h1>
        <p className="operations-text">
          Alın dolgusu, estetik bir işlem olarak tanımlanır ve genellikle alın
          bölgesine uygulanır. Bu işlemde, hyaluronik asit veya kalsiyum
          hidroksiapatit gibi dolgu maddeleri, alın bölgesine enjekte edilir.
          Dolgu maddesi, cildin altına yerleştirilerek kırışıklıkların ve
          çizgilerin görünümünün azaltılmasına, hacim eklenmesine, kaş
          yüksekliğinin artırılmasına ve yüz simetrisinin geliştirilmesine
          yardımcı olur. Alın dolgusu, yüz gençleştirme işlemlerinin bir parçası
          olarak sıklıkla tercih edilir. Alın bölgesindeki yaşlanma belirtileri
          ve hacim kaybı, zamanla ortaya çıkabilir. Bunlar arasında alındaki
          derin çizgiler, kırışıklıklar, düşük ya da asimetrik kaşlar
          sayılabilir. Alın dolgusu bu sorunları düzeltmeye yardımcı olarak daha
          genç ve canlı bir görünüm sağlar.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <img
            src={alinDolgusuP}
            className="w-full h-auto rounded-[10px]"
            alt="alin-dolgu-img"
          ></img>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="ads2">
                  Alın Dolgusu Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="ads2">
                  <b className="font-bold">• Bölgenin Temizlenmesi:</b> İşlem
                  öncesinde, alın bölgesi antiseptik bir solüsyonla temizlenir.
                  Bu, enfeksiyon riskini azaltır ve işlemin hijyenik bir şekilde
                  gerçekleşmesini sağlar.
                  <br />
                  <b className="font-bold">• Uyuşturma:</b> İşlem bölgesine
                  lokal anestezi uygulanarak, ağrı minimuma indirilir. Bu,
                  işlemin daha konforlu ve rahat geçmesini sağlar.
                  <br />
                  <b className="font-bold">• Dolgu Enjeksiyonu:</b> Uzman, uygun
                  dolgu maddesini ince bir iğne veya kanül aracılığıyla alın
                  bölgesine enjekte eder. Dolgu maddesi, dermis tabakasının
                  altına yerleştirilir ve kırışıklıkları doldurarak alana hacim
                  kazandırır.
                  <br />
                  <b className="font-bold">• Şekillendirme ve Düzeltme:</b>{" "}
                  Dolgu enjekte edildikten sonra, doktor işlem bölgesini
                  şekillendirir ve gerektiğinde düzeltmeler yapar. Bu, istenen
                  sonucun elde edilmesini sağlar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ads1">
                  Alın Dolgusu Yararları
                </AccordionHeader>
                <AccordionBody accordionId="ads1">
                  <b className="font-bold">• Kırışıklıkları Azaltır:</b> Alın
                  bölgesindeki derin çizgiler ve kırışıklıkların görünümünü
                  azaltır. Dolgu maddesi, cilt altına yerleştirildiğinde,
                  kırışıklıkları azaltarak cildin daha pürüzsüz ve genç
                  görünmesini sağlar.
                  <br />
                  <b className="font-bold">• Hacim Ekler:</b> Alın bölgesindeki
                  hacim kaybını gidererek yüzün dolgun bir görünüm elde etmesini
                  sağlar. Yaşlanma ile birlikte alın bölgesindeki dokular
                  incelir ve yerçekimi etkisiyle aşağı doğru kayar. Alın
                  dolgusu, bu hacim kaybını telafi eder.
                  <br />
                  <b className="font-bold">• Kaşları Kaldırır:</b> Kaşların
                  kaldırılmasını sağlayarak daha genç ve enerjik bir görünüm
                  kazandırır. Alın dolgusu, kaşların düşük olduğu durumlarda
                  kaşları bir miktar yukarı kaldırarak gözlere daha açık ve genç
                  bir ifade kazandırır.
                  <br />
                  <b className="font-bold">
                    • Yüz Simetrisini Geliştirir:
                  </b>{" "}
                  Alın bölgesindeki asimetri veya düzensizlikleri gidererek yüz
                  simetrisini geliştirir. Alın dolgusu, yüzdeki dengesizlikleri
                  ortadan kaldırarak daha estetik bir görünüm elde edilmesine
                  yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ads3">
                  Alın Dolgusu Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="ads3">
                  <b className="font-bold">• Uzmana Danışma:</b> Alın dolgusu
                  işlemi hakkında daha fazla bilgi edinmek ve beklentileri
                  paylaşmak için bir uzmana danışmak önemlidir. Uzman, en uygun
                  dolgu maddesini ve tedavi planını önererek, istenilen sonuca
                  ulaşılmasına yardımcı olacaktır
                  <br />
                  <b className="font-bold">• İlaç ve Alkol Kullanımı:</b> Alın
                  dolgusu işleminden önce, kan inceltici ilaçlar ve alkol
                  kullanımını sınırlamak gerekebilir. Bunlar, kanama riskini
                  artırabilir ve işlemin etkinliğini olumsuz etkileyebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ads4">
                  Alın Dolgusu Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="ads4">
                  <b className="font-bold">• Soğuk Kompres Uygulaması:</b> İşlem
                  sonrası hafif şişlik veya kızarıklık oluşabilir. Bu durumu
                  hafifletmek için ilk birkaç saat soğuk kompres uygulanabilir.
                  Soğuk kompres, şişliği azaltmaya yardımcı olur.
                  <br />
                  <b className="font-bold">• Hafif Aktiviteler:</b> İşlem
                  sonrası ağır egzersizlerden ve güneşten kaçınmak önemlidir.
                  Hafif aktiviteler yapmaya devam edilebilir, ancak aşırı sıcak,
                  terleme veya yoğun fiziksel aktivitelerden kaçınılmalıdır. Bu,
                  işlemin etkilerini korumaya yardımcı olur ve iyileşme sürecini
                  hızlandırır.
                  <br />
                  <b className="font-bold">• Kozmetik Ürünler:</b> İşlem sonrası
                  makyaj yaparken dikkatli olunmalıdır. Alın bölgesine uygulanan
                  kozmetik ürünler nazikçe uygulanmalıdır. Aşırı sürtünme veya
                  baskı, dolgunun yerinden oynamasına veya etkisinin azalmasına
                  yol açabilir. Bu nedenle kozmetik ürünleri hassas bir şekilde
                  kullanmak önemlidir.
                  <br />
                  <b className="font-bold">• Takip Randevusu:</b> Alın dolgusu
                  sonrası, takip randevularının düzenli olarak yapılması
                  önemlidir. Bu randevular, işlem sonucunu kontrol etmek ve
                  gerektiğinde düzeltmeler yapabilmek için önemlidir. Uzman en
                  uygun takip planını oluşturacaktır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="adsoru1">
                Alın dolgusu ne zaman oturur?
              </AccordionHeader>
              <AccordionBody accordionId="adsoru1">
                İşlem sonrasında dolgunun oturması birkaç gün sürebilir. Tam
                oturma süresi kişiye, işlem yapılan bölgeye ve kullanılan dolgu
                malzemesine bağlıdır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="adsoru2">
                Alın dolgusu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="adsoru2">
                Alın dolgusu işlemi genellikle 15-30 dakika sürer.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="adsoru3">
                Alın dolgusu ne işe yarar?
              </AccordionHeader>
              <AccordionBody accordionId="adsoru3">
                Alın dolgusu, alındaki kırışıklıkları azaltır, hacim ekler,
                kaşların yüksekliğini artırır ve yüz simetrisini geliştirir. Bu
                işlem, yüz gençleştirme işlemlerinin bir parçası olarak sıklıkla
                tercih edilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="adsoru4">
                Alın ortasına dolgu yapılır mı?
              </AccordionHeader>
              <AccordionBody accordionId="adsoru4">
                Evet, alın orta bölgesine de dolgu yapılabilir. Bu, kaşların
                yükseltilmesine ve yüz simetrisinin geliştirilmesine yardımcı
                olabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="adsoru5">
                Alın dolgusu ağrılı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="adsoru5">
                İşlemden önce, işlem bölgesine lokal anestezi uygulanır, bu
                nedenle işlem sırasında ağrı hissedilmez.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        <div id="yanakpin" />
        <hr className="operations-hr"></hr>
        {/* ---------------------------------------- */}
        <h1 className="operations-main-title">
          Ameliyatsız Yanak İnceltme
        </h1>
        <p className="operations-text">
          Ameliyatsız yanak inceltme, lipoliz adı verilen bir prosedürle
          gerçekleştirilir. Lipoliz, vücutta yağ hücrelerini parçalayan bir
          tekniktir ve genellikle özel bir enjeksiyon ile uygulanır. Bu
          enjeksiyon, yağ hücrelerini hedef alarak onları küçültür ve yok eder,
          böylece yanak bölgesindeki hacmi azaltır. Sonuç olarak, yüz daha ince
          ve genç bir görünüm kazanır.
        </p>

        <div className="gap-x-4 md:gap-x-10 pt-4 md:pt-8 grid grid-cols-1 lg:grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="ayhs1">
                  Ameliyatsız Yanak İnceltme Nasıl Yapılır?
                </AccordionHeader>
                <AccordionBody accordionId="ayhs1">
                  Ameliyatsız yanak inceltme, genellikle klinik bir ortamda
                  uzman bir doktor veya dermatolog tarafından gerçekleştirilir.
                  İşlem sırasında, öncelikle belirlenen bölgelere lokal anestezi
                  uygulanır. Ardından, ince bir iğne ile lipolitik bir solüsyon
                  (yağları parçalayan madde) yanak bölgesine enjekte edilir. Bu
                  solüsyon, yağ hücrelerini parçalar ve vücut tarafından
                  emilerek atılır. İşlem genellikle 30 dakika ila bir saat
                  arasında sürer ve hasta günlük aktivitelerine hızlıca geri
                  dönebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ayhs2">
                  Ameliyatsız Yanak İnceltmenin Avantajları Nelerdir?
                </AccordionHeader>
                <AccordionBody accordionId="ayhs2">
                  <b className="font-bold">• Minimal İnvaziv:</b> Cerrahi
                  olmayan bir prosedür olduğu için ameliyat riskleri ve iyileşme
                  süreci minimaldir
                  <br />
                  <b className="font-bold">• Hızlı ve Etkili:</b> İşlem
                  genellikle kısa sürer ve sonuçlar hemen görülebilir.
                  <br />
                  <b className="font-bold">• Doğal Görünüm:</b> Yanak inceltme,
                  yüz hatlarını doğal bir şekilde dengeleyerek daha estetik bir
                  görünüm sunar.
                  <br />
                  <b className="font-bold">• Kişiselleştirilebilir:</b> Her
                  hasta için özel olarak uyarlanabilen bir prosedürdür, böylece
                  işlem özelleştirilebilir
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ayhs3">
                  Ameliyatsız Yanak İnceltme Öncesi: Hazırlık Süreci
                </AccordionHeader>
                <AccordionBody accordionId="ayhs3">
                  Ameliyatsız yanak inceltme öncesinde, bir danışma randevusu
                  ayarlamak önemlidir. Bu randevu sırasında, doktor prosedür
                  hakkında bilgi verecek, istenen sonuçları tartışacak ve
                  muhtemel riskleri ve yan etkileri açıklayacaktır. Ayrıca,
                  alerjik reaksiyon riskini azaltmak için mevcut ilaçları ve
                  sağlık durumunu doktorla paylaşmak önemlidir. İşlem öncesinde
                  doktorun önerdiği talimatlar da dikkatlice izlenmelidir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="ayhs4">
                  Ameliyatsız Yanak İnceltme Sonrası Dikkat Edilmesi Gerekenler
                </AccordionHeader>
                <AccordionBody accordionId="ayhs4">
                  <b className="font-bold">• Dinlenme:</b> İşlem sonrası
                  dinlenmek ve ağır fiziksel aktivitelerden kaçınmak önemlidir.
                  <br />
                  <b className="font-bold">• Soğuk Kompres Uygulaması:</b> İşlem
                  sonrası oluşabilecek hafif şişlik ve kızarıklığı azaltmak için
                  soğuk kompres uygulanmalıdır.
                  <br />
                  <b className="font-bold">• Makyaj ve Cilt Bakımı:</b> İşlem
                  sonrası birkaç gün boyunca makyaj yapmaktan ve cildi tahriş
                  edebilecek ürünler kullanmaktan kaçınılmalıdır.
                  <br />
                  <b className="font-bold">
                    • Doktorun Talimatlarına Uyulması:
                  </b>{" "}
                  İyileşme sürecini desteklemek için doktorun önerdiği
                  talimatlar dikkatlice izlenmeli ve belirtilen takip
                  randevuları kaçırılmamalıdır.
                  <br />
                  <b className="font-bold">• Yan Etkileri Bildirme:</b> İşlem
                  sonrası herhangi bir anormal semptom veya yan etki fark
                  edilirse, derhal doktora başvurulmalıdır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <img
            src={yanakInceltmeP}
            className="w-full h-auto rounded-[10px]"
            alt="yanak-inceltme-img"
          ></img>
        </div>
        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="aysoru1">
                Ameliyatsız yanak inceltme kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="aysoru1">
                Ameliyatsız yanak inceltme sonuçları genellikle uzun ömürlüdür,
                ancak kalıcı değildir. Fazla yağ hücreleri yok olduktan sonra
                geri dönüşümsüz olarak kaybolur. Ancak yaşlanma süreci ve yaşam
                tarzı faktörleri sonucunda zamanla yeniden yağ birikimi
                olabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="aysoru2">
                Ameliyatsız yanak inceltme ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="aysoru2">
                Ameliyatsız yanak inceltme işlemi genellikle 30 dakika ile 1
                saat arasında sürer. İşlem süresi, kullanılan tekniklere ve
                kişiye özel belirlenen prosedüre göre değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="aysoru3">
                Ameliyatsız yanak inceltme acıtır mı?
              </AccordionHeader>
              <AccordionBody accordionId="aysoru3">
                Ameliyatsız yanak inceltme işlemi sırasında hafif bir
                rahatsızlık hissedilebilir. Ancak ağrı genellikle lokal anestezi
                veya anestezik krem kullanılarak minimuma indirilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="aysoru4">
                Ameliyatsız yanak inceltme kaç seans sürer?
              </AccordionHeader>
              <AccordionBody accordionId="aysoru4">
                Ameliyatsız yanak inceltme genellikle 1-3 seans arasında
                uygulanır. Seans sayısı, kişinin ihtiyaçlarına, uygulanan
                yönteme ve istenen sonuca bağlı olarak değişebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        {/* ---------------------------------------- */}
      </div>
    </div>
  );
};

export default FaceOperations;
