import React from 'react';
import { Link } from 'react-router-dom';
import './modal.scss';

const IVModal = () => {

  const handleLinkClick = () => {
    // btn-close butonunu seç ve tıklama olayını tetikle
    const closeButton = document.querySelector('#ivModal .btn-close');
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="ivModal"
      tabIndex={-1}
      aria-labelledby="ivModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <strong className="modal-title fs-5" id="ivModalLabel">
              Hızlı ve Etkili Besin Takviyesi Yöntemi
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            IV terapi, vücuda doğrudan damar yoluyla sıvı takviyesi yapılmasını
            içeren bir tedavi yöntemidir. Genellikle, su, vitaminler, mineraller
            ve bazen ilaçlar içeren çözeltiler kullanılarak yapılan bu terapi,
            vücudun ihtiyaç duyduğu besinleri hızla almasını sağlar. Geleneksel
            oral takviyelerin aksine, IV terapi ile besinler sindirim sistemi
            tarafından emilmeden doğrudan kan dolaşımına ulaşır. Bu, özellikle
            emilim bozukluğu olan bireyler için önemli avantajlar sağlar. IV
            terapi, dehidrasyonun önlenmesi, enerji seviyelerinin artırılması,
            bağışıklık sisteminin güçlendirilmesi ve hastalıkların tedavisinde
            kullanılmaktadır.
          </div>
          <div className="modal-footer">
            <Link to="/iv" type="button" className="btn" onClick={handleLinkClick}>
              Daha Fazlası
            </Link>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IVModal;
