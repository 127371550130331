import "../layout.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from '../contactCard/contactCard';


const IV = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="services-body">
      <div className="md:grid grid-cols-2  services-header">
      <div className="services-responsive-header iv-header-image"></div>
        <div className="services-header-text">
          <h1 className="services-header-title">IV Terapi</h1>
          <h3 className="services-header-title2">
            IV terapi, vücuda doğrudan damar yoluyla sıvı takviyesi yapılmasını
            içeren bir tedavi yöntemidir. Genellikle, su, vitaminler, mineraller
            ve bazen ilaçlar içeren çözeltiler kullanılarak yapılan bu terapi,
            vücudun ihtiyaç duyduğu besinleri hızla almasını sağlar.
            </h3>
            <h3 className="services-header-title2">Geleneksel oral takviyelerin aksine, IV terapi ile besinler sindirim
            sistemi tarafından emilmeden doğrudan kan dolaşımına ulaşır. Bu,
            özellikle emilim bozukluğu olan bireyler için önemli avantajlar
            sağlar. IV terapi, dehidrasyonun önlenmesi, enerji seviyelerinin
            artırılması, bağışıklık sisteminin güçlendirilmesi ve hastalıkların
            tedavisinde kullanılmaktadır.
          </h3>
        </div>
        <div className="iv-header-image services-header-image"></div>
      </div>

      <div className="services-content">

      <h1 className="services-title1">IV Terapinin Faydaları </h1>
        <div className="md:grid gap-x-10 pt-2 grid-cols-2">
          <div className="iv-img1 services-image"></div>
          <div>
            
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="fayda1">
                  Hızlı Hidrasyon
                </AccordionHeader>
                <AccordionBody accordionId="fayda1">
                  Özellikle aşırı sıcaklar veya yoğun egzersiz sonrası
                  dehidrasyona uğrayan bireyler için hızlı hidrasyon sağlar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda2">
                  Vitamin ve Mineral Takviyesi
                </AccordionHeader>
                <AccordionBody accordionId="fayda2">
                  Vitamin ve mineral eksikliklerini hızlı bir şekilde gidererek
                  vücudun optimal sağlık durumuna ulaşmasına yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda3">
                  Detoksifikasyon ve Temizleme
                </AccordionHeader>
                <AccordionBody accordionId="fayda3">
                  Vücuttaki toksinlerin atılmasını sağlayarak genel sağlık
                  durumunu iyileştirir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda4">
                  Bağışıklık Sistemini Güçlendirme
                </AccordionHeader>
                <AccordionBody accordionId="fayda4">
                  Bağışıklık sistemini güçlendirir, hastalıklara karşı korunma
                  sağlar ve mevcut hastalıkların tedavisinde destekleyici bir
                  rol oynar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda5">
                  Enerji Seviyelerini Artırma
                </AccordionHeader>
                <AccordionBody accordionId="fayda5">
                  IV terapi, hücresel fonksiyonu ve metabolizmayı destekleyen
                  temel besinleri sağlayarak enerji seviyelerini iyileştirmeye
                  yardımcı olur. Besin eksikliklerinin giderilmesi yorgunluğu
                  giderebilir ve fiziksel ve zihinsel enerjinin artmasını
                  sağlayabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda6">
                  Geliştirilmiş Atletik Performans ve İyileşme
                </AccordionHeader>
                <AccordionBody accordionId="fayda6">
                  IV terapi, hidrasyon sağlayarak ve kas fonksiyonunu,
                  dayanıklılığı ve iyileşmeyi destekleyen temel besin
                  maddelerini yenileyerek sporcular için faydalı olabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="">
          <div>
            <h1 className="services-title1">IV Terapi Türleri</h1>
            <p className="services-text">
              IV terapi, çeşitli türleriyle farklı sağlık ihtiyaçlarına yanıt
              verir. Vitamin ve mineral infüzyonları, vücuda antioksidanlar ve
              temel vitaminler sağlayarak enerjiyi artırır ve hastalıklara karşı
              koruma sağlar. Mineraller ve antioksidanlar içeren infüzyonlar,
              vücudun genel işleyişini destekleyerek hücrelerin sağlıklı
              kalmasına yardımcı olur. Detoks ve hidrasyon kokteylleri ise
              vücuttaki zararlı maddelerin atılmasını sağlayarak, vücudun
              yenilenmesine ve hidrate kalmasına katkıda bulunur.
            </p>
          </div>
          <div>
            <h1 className="services-title1">IV Terapi Süreci</h1>
            <p className="services-text">
              IV terapi süreci, hastanın öncelikle tedavi için uygun olup
              olmadığının değerlendirilmesiyle başlar. Tedavi süreci, genellikle
              konforlu bir ortamda, uzman bir sağlık profesyoneli tarafından
              gerçekleştirilir. İnfüzyon süresi, kullanılan çözeltinin türüne ve
              hastanın ihtiyacına bağlı olarak değişkenlik gösterir. Tedavi
              sırasında ve sonrasında hastaların izlenmesi, olası yan etkilerin
              önlenmesi açısından önemlidir.
            </p>
          </div>
        </div>

        <ContactCard />

        <h1 className="services-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="soru1">
                IV Terapi güvenli bir işlem midir?
              </AccordionHeader>
              <AccordionBody accordionId="soru1">
                Evet, uygun koşullar altında ve deneyimli sağlık profesyonelleri
                tarafından uygulandığında IV terapi güvenlidir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru2">
                Herkes IV Terapi yaptırabilir mi?
              </AccordionHeader>
              <AccordionBody accordionId="soru2">
                IV terapi, çoğu insan için güvenli olsa da belirli hastalıklara
                sahip olan bireyler için uygun olmayabilir. Bu nedenle, tedaviye
                başlamadan önce bir sağlık profesyoneline danışmak önemlidir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru3">
                IV Terapi Ne Kadar Sürer?
              </AccordionHeader>
              <AccordionBody accordionId="soru3">
                Terapi süresi, kullanılan infüzyonun türüne ve hastanın
                ihtiyacına bağlı olarak değişiklik gösterebilir. Genellikle, bir
                IV terapi seansı 30 dakika ile bir saat arasında sürer.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru4">
                IV terapi hangi besinleri içerir?
              </AccordionHeader>
              <AccordionBody accordionId="soru4">
                IV terapide kullanılan besin maddeleri arasında C vitamini, B
                vitaminleri, amino asitler, magnezyum, kalsiyum, selenyum ve
                glutatyon gibi maddeler bulunur.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru5">
                IV terapi uygulaması sırasında acı hissedilir mi?
              </AccordionHeader>
              <AccordionBody accordionId="soru5">
                IV terapi uygulaması sırasında, bir miktar ağrı veya rahatsızlık
                hissedilebilir. Ancak, enjekte edilen besin maddeleri birçok
                hastada enerji ve canlılık artışı yarattığı için, bu rahatsızlık
                genellikle kısa sürer.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru6">
                IV terapi ne kadar sıklıkla yapılabilir?
              </AccordionHeader>
              <AccordionBody accordionId="soru6">
                IV terapi, bireyin sağlık durumuna, ihtiyaçlarına ve tedavi
                hedeflerine bağlı olarak farklı aralıklarla
                gerçekleştirilebilir. Bazı insanlar haftada bir kez, bazıları
                ayda bir kez ve bazıları daha nadir bir şekilde IV terapi
                alabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru7">
                IV terapi kimlere önerilir?
              </AccordionHeader>
              <AccordionBody accordionId="soru7">
                IV terapi, sağlıklı bir yaşam biçimi sürdürmek isteyenler,
                enerji ve canlılık düzeyi artırmak isteyenler, kronik hastalık
                ya da enfeksiyonlara sahip olanlar ve cerrahi işlem öncesi veya
                sonrasında vücutlarına ekstra besin maddesi almak isteyenler
                için önerilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default IV;
