import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./BlogDetail.css";
import ReactPlayer from "react-player";
import axios from "axios";
import { ClipLoader } from "react-spinners";

const BlogDetail = () => {
  let { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true); // Yükleniyor durumunu takip etmek için

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/blog/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Blogu getirirken hata oluştu:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  if (loading) {
    return (
      <div className="loading-spinner">
        <p>Yükleniyor...</p>
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (!blog) {
    return <p style={{ textAlign: "center" }}>Blog bulunamadı</p>;
  }

  return (
    <div id="bgd" className="blog-detail-container">
      <div className="blog-header">
        <h1 className="blog-title">{blog.title}</h1>
      </div>
      <p className="blog-category">Kategori: {blog.category}</p>
      <div className="blog-content">
        {blog.videoUrl ? (
          <div className="video-wrapper">
            <ReactPlayer
              url={blog.videoUrl}
              width="1100px"
              height="530px"
              controls={true}
              className="blog-video"
            />
          </div>
        ) : (
          <img src={blog.imageUrl} alt={blog.category} className="blog-image" />
        )}
        <p className="blog-description">{blog.description2}</p>
      </div>
      <div className="blog-meta">
        <div className="author-info"></div>
      </div>
    </div>
  );
};

export default BlogDetail;
