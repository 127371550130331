import { Swiper, SwiperSlide } from "swiper/react";
import OzonModal from "./modals/OzonModal";

//swiper style
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ozon from "./ozon.jpeg";
import iv from "./iv-therapy.jpg";
import kok from "./kok-hucre-tedavi.png";
import exosomeTherapy from "./exosome-therapy.png";
import rejuvenation from "./rejuvenation-with-placenta.jpg";
import genetic from "./genetic-treatments.jpg";

import "./Carousel.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import IVModal from "./modals/IVModal";
import KokModal from "./modals/KokModal";
import EksozomModal from "./modals/EksozomModal";
import RejuvenationModal from "./modals/RejuvenationModal";
import GeneticModal from "./modals/GeneticModal";

const Cards = ({ searchQuery }) => {
  const cardInfo = [
    {
      title: "Genetik Test",
      image: genetic,
      target: "#geneticModal",
    },
    {
      title: "IV Terapi",
      image: iv,
      target: "#ivModal",
    },
    {
      title: "Kök Hücre Tedavisi",
      image: kok,
      target: "#kokModal",
    },
    {
      title: "Eksozom Terapi",
      image: exosomeTherapy,
      target: "#eksozomModal",
    },
    {
      title: "Longevity (Uzun Yaşam)",
      image: rejuvenation,
      target: "#rejuvenationModal",
    },
    {
      title: "Ozon Terapi",
      image: ozon,
      target: "#ozonModal",
    },
  ];
  const matchedCards = cardInfo.filter((card) =>
    card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <section className="card-carousel">
        <Swiper
          slidesPerView={5}
          centeredSlides={true}
          spaceBetween={30}
          effect={"coverflow"}
          grabCursor={true}
          loop={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="card-my-swiper"
        >
          <div className="carousel__controls-overlay">
            <h3>
              Yenilikçi <br className="is-hidden-mobile" /> Sağlık Tedavileri
            </h3>
          </div>
          {/* Her eşleşen kart için bir SwiperSlide oluşturulur */}
          {matchedCards.map((card, index) => (
            <SwiperSlide key={index} className="card-swiper">
              <img src={card.image} alt={card.title} />
              <div className="carousel__slide-box__title">
                <h6 className="card-bottom-title">{card.title}</h6>
                <button
                  type="button"
                  className="carousel__add js-add-product"
                  data-bs-toggle="modal"
                  data-bs-target={card.target}
                >
                  <svg
                    className="is-flex"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    role="presentation"
                  >
                    <rect width="18" height="18" fill="none"></rect>
                    <path
                      d="M1416,570v-5h-5a1,1,0,1,1,0-2h5v-5a1,1,0,0,1,2,0v5h5a1,1,0,0,1,0,2h-5v5a1,1,0,0,1-2,0Z"
                      transform="translate(-1408 -555)"
                      fill="#032742"
                    ></path>
                  </svg>
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <OzonModal />
      <IVModal />
      <KokModal />
      <EksozomModal />
      <RejuvenationModal />
      <GeneticModal />
    </>
  );
};

export default Cards;
