import "../layout.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from '../contactCard/contactCard';

const KokHucre = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="services-body">
      <div className="md:grid grid-cols-2 services-header">
      <div className="services-responsive-header kok-header-image"></div>
        <div className="services-header-text">
          <h1 className="services-header-title">Kök Hücre Tedavisi</h1>
          <h3 className="services-header-title2">
            Kök hücre tedavisi, son yıllarda tıp alanında büyük bir ilgi gören yenilikçi bir tedavi yöntemidir. Kök hücreler, vücudumuzdaki diğer hücrelere dönüşebilen ve yeni hücreler oluşturabilen özel hücrelerdir. Bu tedavi yöntemi, çeşitli hastalıkların tedavisinde umut vaat eder.
            <br />
                        

                        <a className="services-header-link" href="#kokhucre-tedavisi">
                            <div className='services-header-link-div kokhucre-link-div'>
                                <div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> 
                                <div> Kök Hücre Tedavisi</div>
                                </div></a>
                        <a className="services-header-link" href="#yagdokusu-tedavi"><div className='services-header-link-div kokhucre-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div> Yağ Dokusundan Kök Hücre Tedavisi</div></div></a>
                        
          </h3>
        </div>
        <div className="services-header-image kok-header-image"></div>
      </div>
<div id="kokhucre-tedavisi"></div>
      <div className="services-content">
        <h1 className="services-title1" >
          Kök Hücre Tedavisi Nasıl Yapılır?
        </h1>
        <p className="services-text">
          Kök hücre tedavisi, hasar görmüş dokuları onarmak, fonksiyonel
          bozuklukları düzeltmek ve çeşitli hastalıkları tedavi etmek amacıyla
          uygulanan bir dizi prosedürü içerir:{" "}
        </p>

        <div className="md:grid gap-x-10 pt-4 grid-cols-2">
          <div className="services-image kok-img1">
          </div>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="fayda1">
                  Kök Hücre Toplanması
                </AccordionHeader>
                <AccordionBody accordionId="fayda1">
                  Kök hücreler, hastadan (otolog kök hücre nakli) veya uygun bir
                  donörden (allogenik kök hücre nakli) toplanabilir. Yetişkin
                  kök hücreler, kemik iliği, periferik kan veya yağ dokusundan
                  elde edilebilir. Embriyonik kök hücreler, genellikle
                  laboratuvar ortamda elde edilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda2">
                  Kök Hücrelerin İşlenmesi ve Hazırlanması
                </AccordionHeader>
                <AccordionBody accordionId="fayda2">
                  Toplanan kök hücreler, kullanılmadan önce özel işlemlerden
                  geçirilir. Bu işlemler, hücrelerin arındırılmasını, konsantre
                  edilmesini ve hastanın durumuna uygun hale getirilmesini
                  içerir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda3">
                  Kök Hücre Nakli veya Enjeksiyonu
                </AccordionHeader>
                <AccordionBody accordionId="fayda3">
                  Kök hücreler, doğrudan hasar görmüş dokuya enjekte edilebilir
                  veya damar yoluyla vücuda verilebilir. Nakil yöntemi, tedavi
                  edilen duruma ve kök hücre türüne bağlı olarak değişiklik
                  gösterir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda4">
                  İzleme ve Takip
                </AccordionHeader>
                <AccordionBody accordionId="fayda4">
                  Kök hücre tedavisi sonrası, hastanın iyileşme süreci ve
                  tedavinin etkinliği dikkatle izlenir. Olası yan etkiler ve
                  komplikasyonlara karşı hastalar yakından takip edilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        <h1 className="services-title1">Kök Hücre Nasıl Alınır?</h1>
        <p className="services-text">
          Kök hücreler çeşitli yöntemlerle elde edilebilir:{" "}
        </p>

        <div className="md:grid pt-4 gap-x-10 grid-cols-2">

          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="yontem1">
                  Kemik İliği Kök Hücre Toplama
                </AccordionHeader>
                <AccordionBody accordionId="yontem1">
                  Bu yöntemde kök hücreler genel anestezi altında bir iğne
                  kullanılarak pelvik kemikten toplanır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="yontem2">
                  Göbek Kordonu Kanı Kök Hücre Toplama
                </AccordionHeader>
                <AccordionBody accordionId="yontem2">
                  Doğum sırasında göbek kordonundan elde edilen kök hücreler
                  depolanabilir ve daha sonra kullanılabilir. Bu yöntem herhangi
                  bir invaziv işlem gerektirmez ve donör ile uyumluluk
                  gereksinimi daha düşüktür.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="yontem3">
                  Yetişkinlerde Periferik Kan Kök Hücre Toplama
                </AccordionHeader>
                <AccordionBody accordionId="yontem3">
                  Bu yöntem, bir dolaşım hattı aracılığıyla donörün kanından kök
                  hücrelerin toplanmasını içerir. Donörün kanı, kök hücre
                  üretimini artırmak için belirli ilaçlar ve uyarıcılarla
                  işlenir ve ardından kök hücreler ayrıştırılır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="services-image kok-img2">
          </div>
        </div>

        <h1 className="services-title1">
          Kök Hücre Kullanım Alanları Nelerdir?
        </h1>
        <div className="">
          <div>
            <p className="services-text">
              <li className="mt-2">
                <b className="services-title3">Rejeneratif Tıp </b>
                <br /> Kök hücreler çeşitli hücre tiplerine farklılaşma
                potansiyeline sahiptir, bu nedenle hasarlı dokuları ve organları
                yenilemek veya onarmak için kullanılabilirler. Bu, kalp
                hastalığı, omurilik yaralanmaları ve eklem dejenerasyonu gibi
                durumların tedavisinde faydalı olabilir.
              </li>
              <li className="mt-2">
                <b className="services-title3">Kan Hastalıkları</b>
                <br /> Kemik iliği veya kordon kanında bulunan kök hücreler
                lösemi, lenfoma ve bazı genetik kan hastalıkları gibi kanla
                ilgili bozuklukları tedavi etmek için kullanılabilir.
              </li>
              <li className="mt-2">
                
                <b className="services-title3">Organ Nakli </b> <br /> Kök hücreler organ naklinin başarı
                oranlarını artırmak için kullanılabilir. Hasarlı organların ve
                dokuların yenilenmesine ve onarılmasına yardımcı olarak
                reddedilme riskini azaltabilirler.
              </li>
              <li className="mt-2">
                
                <b className="services-title3">Otoimmün Hastalıklar</b> <br /> Kök hücreler multipl skleroz,
                romatoid artrit ve sistemik lupus eritematozus gibi durumlarda
                bağışıklık sistemini modüle etmek için kullanılabilir.
              </li>
              <li className="mt-2">
                <b className="services-title3">Estetik ve Yaşlanma Karşıtı Tedaviler</b> <br /> Kök
                hücreler, yüz gençleştirme ve saç uzatma tedavileri gibi
                kozmetik prosedürlerde de kullanılmaktadır.
              </li>
            </p>
          </div>
        </div>

        <ContactCard />
<div id="yagdokusu-tedavi"></div>
<hr className='services-hr'></hr>
        <h1 className="services-main-title" >
          Yağ Dokusundan Kök Hücre Tedavisi
        </h1>
        <p className="services-text">
          Son yıllarda kök hücre tedavisi, tıbbi tedavide devrim niteliğinde bir
          yaklaşım olarak ortaya çıkmış ve çeşitli rahatsızlıkları olan hastalar
          için umut ve yeni olanaklar sunmuştur. Bu alanlardan biri de yağ
          dokusundan elde edilen kök hücrelerdir.
        </p>

        <h1 className="services-title1">
          Yağ Dokusundan Elde Edilen Kök Hücreler Nedir?
        </h1>
        <p className="services-text">
          Yağ dokusundan elde edilen kök hücreler, vücudun yağ dokusunda bulunan
          bir tür mezenkimal kök hücredir. Bu kök hücreler, çoklu hücre
          tiplerine farklılaşma konusunda olağanüstü bir yeteneğe sahiptir ve bu
          da onları çeşitli terapötik uygulamalar için çok yönlü hale getirir.
          Bu hücreler, karın veya uyluk gibi bölgelerden yağ dokusunu çıkarmak
          için küçük bir kanülün kullanıldığı liposuction adı verilen minimal
          invaziv bir prosedürle elde edilir.
        </p>

        <div className="gap-x-10 pt-8 md:grid grid-cols-2">
          <div className="services-image kok-img3"></div>
          <div>
            <h1 className="services-title2">
              Yağ Dokusundan Elde Edilen Kök Hücrelerin Avantajları
            </h1>
            <p className="services-text">
              Yağ dokusundan elde edilen kök hücrelerin temel avantajı vücutta
              bol miktarda bulunmalarıdır. Yağ dokusu kolayca temin edilebilir
              ve ekstraksiyon prosedürü diğer kök hücre kaynaklarına kıyasla
              nispeten basittir. Bu da onları klinik kullanım için daha
              erişilebilir ve uygun maliyetli hale getirmektedir. Ek olarak,
              çalışmalar yağ dokusundan elde edilen kök hücrelerin diğer kök
              hücre türlerine kıyasla daha yüksek bir çoğalma oranına sahip
              olduğunu göstermiştir. Bu durum onları rejeneratif tıp için daha
              uygun hale getirmektedir.
            </p>
          </div>
        </div>

        <div className="pt-8 gap-x-10 md:grid grid-cols-2">
          <div>
            <h1 className="services-title2">
              Yağ Dokusundan Elde Edilen Kök Hücrelerin Potansiyel Uygulamaları
            </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="uygulama1">
                  Ortopedik Koşullar
                </AccordionHeader>
                <AccordionBody accordionId="uygulama1">
                  Yağ dokusundan elde edilen kök hücreler, osteoartrit gibi
                  ortopedik rahatsızlıkların tedavisinde umut vaat etmektedir.
                  Araştırmalar, bu kök hücrelerin hasarlı kıkırdağın
                  yenilenmesine yardımcı olabileceğini, eklem onarımını
                  destekleyerek ağrıyı azaltabileceğini ve hastalar için
                  işlevselliği artırabileceğini göstermektedir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="uygulama2">
                  Yara İyileştirme
                </AccordionHeader>
                <AccordionBody accordionId="uygulama2">
                  Yağ dokusundan elde edilen kök hücrelerin rejeneratif
                  özellikleri, onları yara iyileşmesini desteklemek için ideal
                  hale getirir. Bu kök hücreler doku rejenerasyonunu
                  artırabilir, anjiyogenezi (yeni kan damarlarının oluşumu)
                  uyarabilir ve bağışıklık tepkisini destekleyerek kronik
                  yaraların iyileşmesini hızlandırabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="uygulama3">
                  Estetik ve Rekonstrüktif Prosedürler
                </AccordionHeader>
                <AccordionBody accordionId="uygulama3">
                  Bu kök hücreler doku rejenerasyonunu uyarmak ve sonuçları
                  iyileştirmek için kozmetik ve rekonstrüktif prosedürlerde
                  kullanılabilir. Diğer uygulamaların yanı sıra yağ grefti
                  sağkalımını artırma, yara izlerini onarma ve cilt gençleştirme
                  alanlarında da oldukça etkilidir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="uygulama4">
                  Kardiyak Onarım
                </AccordionHeader>
                <AccordionBody accordionId="uygulama4">
                  Bu kök hücreler, özellikle kalp krizi veya diğer kardiyak
                  olayların ardından kardiyak onarımda umut verici sonuçlar
                  göstermiştir. Üstelik hasarlı kalp dokusunun yenilenmesine,
                  kalp fonksiyonunun iyileştirilmesine ve potansiyel olarak
                  komplikasyon riskinin azaltılmasına yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="services-image kok-img4">
          </div>
        </div>

        <h1 className="services-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="soru1">
                Kök Hücre Tedavisi Güvenli mi?
              </AccordionHeader>
              <AccordionBody accordionId="soru1">
                Kök hücre tedavisi genellikle güvenli kabul edilir. Ancak her
                tıbbi prosedür gibi riskleri vardır. Bu riskler, alınan kök
                hücre türüne ve hastanın genel sağlık durumuna bağlı olarak
                değişir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru2">
                Kök Hücre Tedavisinin Yan Etkileri Nelerdir?
              </AccordionHeader>
              <AccordionBody accordionId="soru2">
                Yan etkiler, enfeksiyon riski, alerjik reaksiyonlar ve tedavinin
                hedef alandaki etkinliği ile ilgili sorunlar gibi çeşitlilik
                gösterebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru3">
                Kök Hücre Tedavisi İçin En İyi Kaynak Hangisidir?
              </AccordionHeader>
              <AccordionBody accordionId="soru3">
                En iyi kök hücre kaynağı, tedavi edilecek duruma ve
                kullanılabilir kök hücre türlerine bağlıdır. Kemik iliği,
                periferik kan ve kordon kanı en yaygın kaynaklardır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru4">
                Kök Hücre Tedavisi Sonuçları Kalıcı mı?
              </AccordionHeader>
              <AccordionBody accordionId="soru4">
                Kök hücre tedavinin sonuçlarının oldukça kalıcı olduğunu
                söylemek mümkündür. Ancak tedavinin kalıcılığı, tedavi edilen
                duruma, hastanın genel sağlığına ve tedavi sırasında kullanılan
                kök hücre türüne bağlı olarak değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru5">
                Kök Hücre Tedavisi Ne Kadar Sürer?
              </AccordionHeader>
              <AccordionBody accordionId="soru5">
                Kök hücre tedavisinin süresi, tedavinin türüne ve hastanın
                tedaviye verdiği yanıta göre değişir. Bazı tedaviler birkaç saat
                içinde tamamlanırken, bazıları haftalar veya aylar sürebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru6">
                Kök Hücre Tedavisi Herkese Uygun mu?
              </AccordionHeader>
              <AccordionBody accordionId="soru6">
                Herkes kök hücre tedavisi için uygun olmayabilir. Hastanın tıbbi
                geçmişi, mevcut sağlık durumu ve tedavi edilecek spesifik
                hastalık, tedaviye uygunluğunu belirleyen faktörlerdir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default KokHucre;
