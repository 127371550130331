import "../operations.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from "../../services/contactCard/contactCard";



const SkinOperations = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="operations-body">
      <div className="md:grid grid-cols-2 operations-header">
        <div className="operations-responsive-header skin-header-image"></div>
        <div className="operations-header-text">
          <h1 className="operations-header-title">Sağlıklı ve Genç Bir Cilt İçin İşlemlerimiz</h1>
          <h3 className="operations-header-title2">
            Cilt tedavilerimizle daha sağlıklı, pürüzsüz ve genç bir cilde kavuşun. Uzman ekibimizle cildinizin ihtiyaçlarına özel çözümler sunuyoruz.
            <br />
            <a className="operations-header-link" href="#ip-aski">
              <div className='operations-header-link-div'>
                <div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div>
                <div> İp Askı (Fransız Askı)</div>
              </div></a>
            <a className="operations-header-link" href="#el-mezoterapisi">
            <div className='operations-header-link-div'>
                <div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>El Mezoterapisi</div></div></a>

            <a className="operations-header-link" href="#el-dolgusu"><div className='operations-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>El Dolgusu</div></div></a>

            <a className="operations-header-link" href="#kimyasal-peeling"><div className='operations-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> <div>Kimyasal Peeling</div></div></a>

            


          </h3>
        </div>
        <div className="operations-header-image skin-header-image"></div>
      </div>
      <div id="ip-aski"></div>
      <div className="operations-content">
        <h1 className="operations-main-title" >
          İp Askı (Fransız Askı)
        </h1>
        <p className="operations-text">
          İp askı, yüz ve boyun bölgesini minimal invaziv bir şekilde gençleştirmeyi amaçlayan bir prosedürdür. Bu yöntem, cildin altına özel olarak tasarlanmış çözünebilir ipliklerin yerleştirilmesiyle gerçekleştirilir. İpliklerin cilde yerleştirilmesi, cildin hemen kaldırılmasını sağlar ve aynı zamanda cilt altındaki kolajen üretimini teşvik ederek uzun vadeli bir gençleştirme etkisi yaratır. Genellikle "lunchtime lift" olarak adlandırılan bu prosedür, cerrahi bir yüz germe işlemine kıyasla daha az invazivdir ve daha kısa iyileşme süresine sahiptir.
        </p>

        <h1 className="operations-title1">
          İp Askı Nasıl Yapılır?
        </h1>
        <p className="operations-text">İp askı, ameliyatsız bir cilt germe tedavisidir. İp askı uygulaması aşağıdaki adımlardan oluşur: </p>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Konsültasyon ve Hazırlık</b>
                <br /> İlk adım, doktorunuzla konsültasyon yapmaktır. Bu süreçte, teknikler, malzemeler ve tedavinin sonuçları hakkında bilgi verilir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Uygulama Alanının Hazırlanması</b>
                <br /> İşlem öncesi, uygulama yapılacak bölge temizlenir ve sterilize edilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Uyuşturma</b> <br /> İp askı yapılacak bölgeye lokal anestezi uygulanır. Böylece işlem sırasında ağrı veya rahatsızlık hissedilmez.
              </li>
              <li className="mt-2">

                <b className="operations-title3">İp Askı Uygulaması</b> <br /> İp askılar, cilde iğne ile yerleştirilir ve sarkık bölgelerin desteklenmesini sağlanır. İp askılarının içindeki yapılar, cilde tutunmayı sağlayan balıksırtı benzeri bir sistemle tasarlanmıştır.
              </li>
              <li className="mt-2">
                <b className="operations-title3">İşlem Süresi</b> <br /> İp askı uygulamasının süresi genellikle 30 dakikadır.  İpler, en fazla 8 ay içinde kendiliğinden yok olur.
              </li>
            </p>
          </div>
        </div>

        <h1 className="operations-title1">İp Askı Hangi Bölgelere Uygulanabilir?</h1>
        <p className="operations-text">İp askı, ciltteki sarkmaların tedavisinde kullanılan bir estetik yöntemdir. Yüz, boyun, el ve ayak bileği gibi bölgelere uygulanabilir. Hangi bölgeye uygulanacağı ip askının özellikleri, hastanın sağlık durumu, cilt yapısı ve yaş faktörleri dikkate alınarak belirlenir.</p>
        <div className="md:grid gap-x-10 pt-4 grid-cols-2">
          <div className="operations-image skin-img1">
          </div>
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="aski-uygulama1">
                  Yüze İp Askı
                </AccordionHeader>
                <AccordionBody accordionId="aski-uygulama1">
                  Yüz bölgesindeki sarkıklıkları yeniden şekillendirmek için uygulanan bir tekniktir. Yüz hatlarını kaldırabilir, çene hattını belirginleştirebilir, yanakları yukarı kaldırabilir ve kaşları yükseltebilir. İp askı, cildin elastikiyetini artırırken daha genç ve daha sıkı bir görünüm sağlamaya yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="aski-uygulama2">
                  Burun İp Askı
                </AccordionHeader>
                <AccordionBody accordionId="aski-uygulama2">
                  Burun bölgesindeki sarkıklıkları düzeltmek ve daha şekilli bir burun görünümü elde etmek için uygulanan bir tekniktir. İp askı, burun ucunu yukarı kaldırabilir, burun sırtındaki düzensizlikleri giderebilir ve burun cildinin gerginleşmesine yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="aski-uygulama3">
                  Göz İp Askı
                </AccordionHeader>
                <AccordionBody accordionId="aski-uygulama3">
                  Göz bölgesindeki sarkıklıkları gidermek ve göz çevresine daha genç ve taze bir görünüm kazandırmak için uygulanan bir yöntemdir. İp askı, göz kapaklarının sıkılaşmasına, üst göz kapağının kaldırılmasına ve göz altı torbalarının azalmasına yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="aski-uygulama4">
                  Kaş İp Askı
                </AccordionHeader>
                <AccordionBody accordionId="aski-uygulama4">
                  Kaş bölgesindeki sarkmaları ve düşüklükleri düzeltmek için kullanılan bir tekniktir. İp askı, kaşları kaldırabilir ve kaş bölgesine daha genç ve kalkık bir görünüm kazandırabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        <h1 className="operations-title1">
          İp Askı Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Doktorun Talimatlarına Uyma</b>
                <br />İp askı prosedürü sonrasında, doktorunuzun verdiği talimatları dikkatlice takip etmek önemlidir. Bu talimatlar, prosedür bölgesine nasıl bakılacağı, nelere dikkat edilmesi gerektiği ve hangi aktivitelerden kaçınılması gerektiği gibi konuları içerebilir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Morluk ve Şişliklerin İzlenmesi</b>
                <br />İp askı prosedürü sonrasında hafif morluklar, şişlikler veya hassasiyetler normaldir. Ancak, bu belirtilerin aşırı olması veya anormal şekillerde gelişmesi durumunda doktora başvurmak gerekir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Yatış Pozisyonu</b> <br />İp askı prosedürü sonrasında, baş ve boyun bölgesindeki şişliği azaltmak için yatış pozisyonu dikkatlice seçilmelidir. Başın yüksek bir yastıkla desteklenmesi, şişliğin azalmasına yardımcı olabilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Soğuk Kompres Uygulaması</b> <br />İp askı uygulamasından sonra oluşan morluk ve şişlikleri azaltmak için soğuk kompres uygulamak faydalı olabilir. Bunun için buz torbası veya soğuk kompres torbaları kullanılabilir.  Ancak buzun cilde doğrudan temas etmesini engellemek için buz, bir bez veya havlu ile sarılmalıdır.
              </li>
            </p>
          </div>
        </div>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="soru1">
                Yüze ip askı sonrası ödem oluşur mu?
              </AccordionHeader>
              <AccordionBody accordionId="soru1">
                Evet, yüze ip askı işlemi sonrasında hafif şişlik ve ödem oluşması normaldir. Çoğu durumda, ödem kısa sürede düzelir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru2">
                İp askı etkisini ne zaman gösterir?
              </AccordionHeader>
              <AccordionBody accordionId="soru2">
                İp askı işlemi sonrasında hafif bir etki hemen görülebilir. Ancak, tam etki genellikle 2-4 hafta içinde ortaya çıkar.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru3">
                İp Askı sonrası çukur oluşur mu?
              </AccordionHeader>
              <AccordionBody accordionId="soru3">
                İp askı işlemi sonrasında çukur oluşması nadirdir. Bu durum genellikle işlemi yapan kişinin tecrübesizliğinden kaynaklanır. Uzman bir doktor tarafından yapılan işlemlerde çukur oluşumu nadirdir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru4">
                Dolgu mu ip askı mı?
              </AccordionHeader>
              <AccordionBody accordionId="soru4">
                Dolgu ve ip askı farklı amaçlara hizmet eden işlemlerdir. Dolgu, cildin altına dolgu malzemeleri enjekte edilerek hacim kazandırırken, ip askı cildi gererek sarkmaları ve kırışıklıkları düzeltir. Dolgu ve ip askı işlemleri birlikte kullanılabilir ama ayrı işlemlerdir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru5">
                İp askı ne kadar kalıcıdır?
              </AccordionHeader>
              <AccordionBody accordionId="soru5">
                İp askı işleminin etki süresi kişiden kişiye değişebilir. Çoğu durumda, etki süresi 1-2 yıl arasındadır. Ancak, işlemi yapan doktorun tecrübesi, kullanılan ürünün kalitesi ve kişinin genel sağlık durumu da etki süresini etkileyebilir.

              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>

        <ContactCard />

        <div id="el-mezoterapisi"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
          El Mezoterapisi
        </h1>
        <p className="operations-text">
          El mezoterapisi, el bölgesindeki estetik sorunların tedavisi için kullanılan bir tıbbi prosedürdür. Eller, yaşlanmanın belirtilerinin en sık görüldüğü bölgelerden biridir ve bu nedenle estetik görünümünü iyileştirmek için çeşitli tedaviler arasında el mezoterapisi de yer alır.
        </p>
        <p className="operations-text">
          El mezoterapisi genellikle yaşlanma belirtilerini azaltmak, cilt elastikiyetini ve sıkılığını artırmak, lekeleri azaltmak ve ellerin genel görünümünü iyileştirmek için kullanılır. Bu prosedür, eldeki cilt problemlerini hedeflemek, elin genç ve taze bir görünüm kazanmasını sağlamak amacıyla cilt altına belirli vitaminler, mineraller, amino asitler ve diğer besleyici maddelerin enjekte edilmesini içerir.
        </p>

        <h1 className="operations-title1">
          El Mezoterapisi Nasıl Uygulanır?
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Hazırlık</b>
                <br /> Uygulamadan önce, cilt temizlenir ve sterilize edilir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Mezoterapi İğnelerinin Hazırlanması</b>
                <br /> Enjeksiyon, cilt üzerine uygulanacak olan serum veya karışımla doldurulur.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Uygulama</b> <br /> Hazırlanan karışım, ince iğnelerle cilt yüzeyine enjekte edilir. Bu enjeksiyonlar, cildin ihtiyacına göre belirlenen bölgelere uygulanır.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Son Kontrol ve Bakım</b> <br /> Uygulama tamamlandıktan sonra, ciltte hafif kızarıklık veya şişlik olabilir. Uzmanlar, uygun bakım yönergeleri verir ve gerektiğinde takip randevuları ayarlar.
              </li>
            </p>
          </div>
        </div>

        <div className="pt-8 gap-x-10 md:grid grid-cols-2">
          <div>
            <h1 className="operations-title2">
              El Mezoterapisinin Faydaları Nelerdir?
            </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="el-mezoterapisi-fayda1">
                  Cilt Gençleştirme
                </AccordionHeader>
                <AccordionBody accordionId="el-mezoterapisi-fayda1">
                  El mezoterapisi, eldeki yaşlanma belirtilerini azaltabilir. Cilt altına enjekte edilen besleyici maddeler, cildin elastikiyetini artırarak kırışıklıkları ve ince çizgileri azaltabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="el-mezoterapisi-fayda2">
                  Hidrasyonu Artırma
                </AccordionHeader>
                <AccordionBody accordionId="el-mezoterapisi-fayda2">
                  El mezoterapisi, cildin nem seviyelerini artırabilir. Cilt altına enjekte edilen nemlendirici maddeler, ellerin daha dolgun ve nemli görünmesini sağlayabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="el-mezoterapisi-fayda3">
                  Ellerdeki Lekeleri Azaltma
                </AccordionHeader>
                <AccordionBody accordionId="el-mezoterapisi-fayda3">
                  Güneş hasarı veya yaşlanma ile ilişkili olarak ortaya çıkan lekeleri azaltabilir ve cildin daha homojen bir görünüm kazanmasını sağlayabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="el-mezoterapisi-fayda4">
                  Dolaşımı Artırma
                </AccordionHeader>
                <AccordionBody accordionId="el-mezoterapisi-fayda4">
                  El mezoterapisi, dolaşımı artırarak ellerdeki kan dolaşımını iyileştirebilir. Bu, ellerin daha canlı ve sağlıklı görünmesini sağlayabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="operations-image skin-img2">
          </div>
        </div>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="el-mezoterapisi-soru1">
                El mezoterapisi işe yarar mı?
              </AccordionHeader>
              <AccordionBody accordionId="el-mezoterapisi-soru1">
                Evet, el mezoterapisi genellikle etkili bir yöntemdir. Ciltteki kırışıklıkları azaltmaya, cildi nemlendirmeye, lekeleri gidermeye ve gençlik etkisi yaratmaya yardımcı olabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-mezoterapisi-soru2">
                El mezoterapisi acıtır mı?
              </AccordionHeader>
              <AccordionBody accordionId="el-mezoterapisi-soru2">
                El mezoterapi uygulaması sırasında hafif bir rahatsızlık veya acı hissedilebilir, ancak genellikle dayanılabilir bir düzeydedir. Uygulama öncesi cildin uyuşturulmasıyla acı hissi en aza indirgenir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-mezoterapisi-soru3">
                El mezoterapisi ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="el-mezoterapisi-soru3">
                El mezoterapisi uygulaması genellikle 15-30 dakika arasında sürer. Uygulama süresi, kişinin ihtiyaçlarına ve uygulama yapılacak bölgenin büyüklüğüne göre değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-mezoterapisi-soru4">
                El mezoterapisi kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="el-mezoterapisi-soru4">
                El mezoterapisi sonuçları genellikle kalıcı değildir. Belirli aralıklarla tekrarlanması gerekebilir, ancak düzenli olarak yapıldığında uzun süreli etkiler sağlayabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-mezoterapisi-soru5">
                El mezoterapisinin sonuçları ne zaman görülür?
              </AccordionHeader>
              <AccordionBody accordionId="el-mezoterapisi-soru5">
                El mezoterapisi sonuçları genellikle ilk birkaç gün içinde başlar ve iyileşme süreci devam ettikçe daha belirgin hale gelir. Tam etkilerin görülmesi birkaç hafta sürebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>

        <div id="el-dolgusu"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
          El Dolgusu
        </h1>
        <p className="operations-text">
          El dolgusu, ellere genç bir görünüm ve dolgunluk kazandırmak amacıyla uygulanan kozmetik bir işlemdir. Hyaluronik asit gibi dolgu maddeleri kullanılarak yapılır. Bu dolgu maddeleri, ellerin hacmini arttırır, kırışıklıkları azaltır ve gençlik etkisi yaratır.
        </p>

        <div className="pt-8 gap-x-10 md:grid grid-cols-2">
          <div className="operations-image skin-img3"></div>
          <div><h1 className="operations-title2" >
            El Dolgusu Öncesi Hazırlık Süreci
          </h1>
            <p className="operations-text">
              El dolgusu uygulamasından önce cilt analizi yapılır ve en uygun dolgu maddesi belirlenir. El dolgusu uygulamasına geçmeden önce, işlemi yapacak olan doktorun verdiği talimatlara uyulmalıdır. Bazı ilaçlar ve takviyeler, işlem öncesinde geçici olarak kesilebilir. İşlem öncesinde doktorla detaylı bir görüşme yapılmalı, beklentiler ve muhtemel riskler hakkında bilgi alınmalıdır.
            </p></div>
        </div>

        <h1 className="operations-title1" >
          El Dolgusu Nasıl Uygulanır?
        </h1>
        <p className="operations-text">
          El dolgusu, uzman bir doktor veya estetik uzmanı tarafından yapılan bir işlemdir. İşlem sırasında, dolgu maddesi ince bir iğne kullanılarak deri altına enjekte edilir. İşlem öncesinde cilt temizlenir ve anestezik krem uygulanabilir. İşlem süresi genellikle 15-30 dakika arasında değişir.
        </p>



        <div className="pt-8 gap-x-10 md:grid grid-cols-2">
          <div>
            <h1 className="operations-title2">
              El Dolgusunun Faydaları Nelerdir?
            </h1>
            <p className="operations-text">
              <li className="mt-2">
                El dolgusu uygulaması, ellerin gençlik ve dolgunluğunu geri kazandırır.
              </li>
              <li className="mt-2">
                Kırışıklıkları azaltır ve cildi gençleştirir.
              </li>
              <li className="mt-2">
                El sırtında görülen kemik veya damar çıkıntılarını gizler.
              </li>
              <li className="mt-2">
                Ellerin hacmini artırarak daha genç ve dolgun görünmelerini sağlar.
              </li>
              <li className="mt-2">
                Cildi nemlendirir ve elastikiyetini artırır.
              </li>
            </p>
          </div>

          <div className="operations-image skin-img4">
          </div>
        </div>

        <h1 className="operations-title1">
          El Dolgusu Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              İşlem sonrası hafif bir şişlik veya kızarıklık normaldir ve genellikle birkaç gün içinde geçer.
              İşlem sonrası doktorun önerdiği şekilde ellerin bakımı yapılmalıdır. Önerilen kremler veya losyonlar kullanılmalı ve eller güneşin zararlı etkilerinden korunmalıdır. İşlem sonrası aşırı güneşe maruz kalmaktan veya sıcak suyla temas etmekten kaçınılmalıdır. İşlem sonrası herhangi bir şüphe durumunda veya olumsuz bir reaksiyon fark edildiğinde doktora başvurulmalıdır.

            </p>
          </div>
        </div>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="el-dolgusu-soru1">
                El dolgusu işe yarar mı?
              </AccordionHeader>
              <AccordionBody accordionId="el-dolgusu-soru1">
                Evet, el dolgusu genellikle etkili bir yöntemdir. Ellerin hacmini artırarak daha genç ve dolgun görünmelerini sağlar, kırışıklıkları azaltır ve cildi nemlendirir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-dolgusu-soru2">
                El dolgusu kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="el-dolgusu-soru2">
                El dolgusu sonuçları genellikle kalıcı değildir. Belirli aralıklarla tekrarlanması gerekebilir, ancak düzenli olarak yapıldığında uzun süreli etkiler sağlayabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-dolgusu-soru3">
                El dolgusunun sonuçları ne zaman görülür?
              </AccordionHeader>
              <AccordionBody accordionId="el-dolgusu-soru3">
                El dolgusu sonuçları genellikle işlem sırasında hemen görülebilir, ancak tam etkilerin görülmesi birkaç gün sürebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-dolgusu-soru4">
                El dolgusu acıtır mı?
              </AccordionHeader>
              <AccordionBody accordionId="el-dolgusu-soru4">
                El dolgusu işlemi sırasında hafif bir rahatsızlık veya acı hissedilebilir, ancak genellikle dayanılabilir bir düzeydedir. İşlem öncesi cildin uyuşturulmasıyla acı hissi en aza indirgenir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="el-dolgusu-soru5">
                El dolgusu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="el-dolgusu-soru5">
                El dolgusu işlemi genellikle 15-30 dakika arasında sürer. İşlem süresi, kişinin ihtiyaçlarına ve uygulama yapılacak bölgenin büyüklüğüne göre değişebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>

        <div id="kimyasal-peeling"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
          Kimyasal Peeling
        </h1>
        <p className="operations-text">
          Kimyasal peeling, cildin üst tabakasını (epidermis) soyarak ve altındaki deri tabakasını (dermis) yenileyerek cilt tonunu düzeltmeyi amaçlayan kozmetik bir prosedürdür. Bu işlem sırasında, cilde özel bir solüsyon uygulanır ve belirli bir süre bekletilir. Solüsyon, cildin üst tabakasındaki ölü hücreleri ve lekeleri temizlerken, altındaki sağlıklı cilt hücrelerinin yenilenmesini teşvik eder.
        </p>

        <h1 className="operations-title1">
          Kimyasal Peeling Nasıl Uygulanır?
        </h1>
        <p className="operations-text">Kimyasal peeling işlemi şu adımları içerir:</p>
        <div className="pt-4 gap-x-10 md:grid grid-cols-2">
          <div className="operations-image skin-img5">
          </div>
          <div>
            <p className="operations-text">
              <li className="">
                <b className="operations-title3">Cildin Hazırlanması</b>
                <br /> İşlem öncesinde, cilt temizlenir ve bazen bir ön hazırlık solüsyonu uygulanır. Bu, aktif bileşenlerin cilt tarafından daha iyi emilmesini sağlar.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Kimyasal Solüsyonun Uygulanması</b>
                <br /> Belirli bir kimyasal solüsyon cilde uygulanır. Bu solüsyon, cildin ihtiyacına ve tedavi amaçlarına göre seçilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Bekleme Süresi</b> <br /> Solüsyon ciltte belirli bir süre bırakılır. Bu süre, solüsyonun gücüne ve cildin tepkisine bağlı olarak değişebilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Yıkama ve Nemlendirme</b> <br /> Belirli bir sürenin sonunda solüsyon ciltten temizlenir ve cilt nemlendirici ile yeniden nemlendirilir.
              </li>
            </p>
          </div>

        </div>

        <h1 className="operations-main-title" >
          Leke Tedavileri
        </h1>
        <p className="operations-text">
          Leke tedavileri, cilt lekelerini azaltmak veya ortadan kaldırmak için kullanılan çeşitli tedavi yöntemlerini kapsar. Bu tedaviler, güneş lekeleri, yaşlılık lekeleri, melazma, hiperpigmentasyon gibi çeşitli leke türlerini hedef alır.
        </p>

        <div className="pt-8 gap-x-10 md:grid grid-cols-2">
          <div>
            <h1 className="operations-title2">
              Leke Tedavileri Neleri Kapsar?
            </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="leke-tedavi-kapsam1">
                  Kimyasal Peeling
                </AccordionHeader>
                <AccordionBody accordionId="leke-tedavi-kapsam1">
                  Kimyasal peeling, ciltteki lekeleri azaltmak için etkili bir yöntemdir. Farklı konsantrasyonlardaki kimyasal solüsyonlar, ciltteki melanin üretimini azaltarak lekelerin görünümünü iyileştirir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="leke-tedavi-kapsam2">
                  Lazer Tedavileri
                </AccordionHeader>
                <AccordionBody accordionId="leke-tedavi-kapsam2">
                  Lazer tedavileri, ciltteki pigment lekelerini hedef alarak bunların azalmasını sağlar. Lazer ışığı, lekeli cilt bölgelerinde melanin pigmentini parçalar ve cilt hücrelerinin yenilenmesini teşvik eder.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="leke-tedavi-kapsam3">
                  Mikrodermabrazyon
                </AccordionHeader>
                <AccordionBody accordionId="leke-tedavi-kapsam3">
                  Mikrodermabrazyon, cilt yüzeyindeki ölü cilt hücrelerini ve lekeleri gidermek için kullanılan bir yöntemdir. Bu işlem sırasında, cilde ince kristaller püskürtülür ve ardından vakumlanarak cilt yüzeyinden uzaklaştırılır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="leke-tedavi-kapsam4">
                  Kriyoterapi
                </AccordionHeader>
                <AccordionBody accordionId="leke-tedavi-kapsam4">
                  Kriyoterapi, lekelerin dondurulması yoluyla ortadan kaldırılmasını sağlayan bir yöntemdir. Bu işlem sırasında, lekelerin üzerine sıvı nitrojen püskürtülür. Bu sayede lekeler donar ve soyulur.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          <div className="operations-image skin-img6">
          </div>
        </div>

        <h1 className="operations-title1">
          Leke Tedavilerinde Hangi Kimyasal Peeling Türleri Kullanılır?
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="">
                <b className="operations-title3">AHA Kimyasal Peeling</b>
                <br /> Alfa hidroksi asitler (AHA'lar), cildin üst tabakasını soyarak lekelerin azalmasına yardımcı olur. Bu peeling türü genellikle hafif lekeler için uygundur.
              </li>
              <li className="mt-2">
                <b className="operations-title3">TCA Kimyasal Peeling</b>
                <br /> Trikloroasetik asit (TCA), derinlemesine leke tedavisi için daha güçlü bir seçenektir. Daha belirgin lekeleri hedef almak için kullanılabilir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Fenol Kimyasal Peeling</b> <br />Fenol, ciltteki derin lekeleri ve kırışıklıkları tedavi etmek için kullanılan en güçlü kimyasal peeling türüdür. Bu peeling türü genellikle ciddi pigmentasyon sorunları için uygulanır.
              </li>
            </p>
          </div>
        </div>

        <h1 className="operations-title1" >
          Kimyasal Peeling ile Leke Tedavisi Sonrası İyileşme Süreci
        </h1>
        <p className="operations-text">
          Kimyasal peeling ile leke tedavisi sonrası iyileşme süreci, kullanılan peeling türüne ve cildin tepkisine bağlı olarak değişebilir. Genellikle, işlem sonrası cilt kızarabilir, hafif bir yanma hissi olabilir ve soyulma yaşanabilir. Bu süreçte, cildi güneşten korumak ve doktorun önerdiği cilt bakım ürünlerini kullanmak önemlidir. İyileşme süreci tamamlandığında, cilt daha genç, canlı ve lekesiz bir görünüme sahip olacaktır.
        </p>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="kimyasal-peeling-soru1">
                Kimyasal peeling kalıcı mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="kimyasal-peeling-soru1">
                Hayır, kimyasal peeling kalıcı bir tedavi yöntemi değildir. Sonuçlar, uygulanan peelingin türüne bağlı olarak değişebilir. Belirli bir süre boyunca leke görünümünü azaltabilir, ancak tamamen ortadan kaldırmaz.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kimyasal-peeling-soru2">
                Kimyasal peeling ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="kimyasal-peeling-soru2">
                Kimyasal peelingin süresi, uygulanan peeling türüne ve kullanılan kimyasalların yoğunluğuna bağlıdır. Genellikle 20 dakika ile bir saat arasında sürer.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kimyasal-peeling-soru3">
                Kimyasal peeling acıtır mı?
              </AccordionHeader>
              <AccordionBody accordionId="kimyasal-peeling-soru3">
                Kimyasal peeling işlemi sırasında hafif bir rahatsızlık hissi olabilir. Ancak birçok doktor, bu süreci tolere edebilecek şekilde lokal anestezi uygular. Ağrı eşiği her bireyde farklıdır, bu nedenle acı hissetme deneyimleri değişebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kimyasal-peeling-soru4">
                Kimyasal peeling ile leke tedavisi işe yarar mı?
              </AccordionHeader>
              <AccordionBody accordionId="kimyasal-peeling-soru4">
                Evet, kimyasal peeling leke tedavisinde etkili bir yöntemdir. Ciltteki koyu lekelerin görünümünü azaltarak, cildi pürüzsüzleştirebilir ve daha homojen bir görünüm sağlayabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="kimyasal-peeling-soru5">
                Kimyasal peeling ile leke tedavisinin zararları var mıdır?
              </AccordionHeader>
              <AccordionBody accordionId="kimyasal-peeling-soru5">
                Uzmanlar tarafından yapılan kontrollü ve doğru uygulamalarda kimyasal peeling, genellikle güvenli bir tedavi olarak kabul edilir. Ancak herkesin cilt tipi ve hassasiyeti farklıdır, bu nedenle bazı yan etkiler ortaya çıkabilir. Bunlar arasında kızarıklık, kabarma ve soyulma bulunur.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>



      </div>
    </div>
  );
};

export default SkinOperations;
