import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./bb.css";
import videoIcon from "../../assets/video.png";
import readIcon from "../../assets/read.png";
import ReactPlayer from "react-player";
import axios from "axios";

const Bloger = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`https://genesis-api-l0zr.onrender.com/blog`);
        // Gelen verinin bir dizi olup olmadığını kontrol et
        if (Array.isArray(response.data)) {
          setBlogs(response.data);
        } else {
          console.error("Gelen veri bir dizi değil:", response.data);
          setBlogs([]); // Boş bir dizi olarak ayarla
        }
      } catch (error) {
        console.error("Blogları getirirken hata oluştu:", error);
        setBlogs([]); // Hata durumunda boş bir dizi olarak ayarla
      }
    };

    fetchBlogs();
  }, []);

  const handleCardClick = (blog) => {
    localStorage.setItem("selectedBlog", JSON.stringify(blog));
    navigate(`/blog/${blog._id}`);
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('tr-TR', options);
  };

  return (
    <div id="dubai" className="blog-container">
      {blogs.map((blog) => (
        <div
          key={blog._id}
          className={`blog-card ${blog.categoryClass}`}
          onClick={() => handleCardClick(blog)}
        >
          {blog.videoUrl ? (
            <div className="video-wrapperx">
              <ReactPlayer
                url={blog.videoUrl}
                width="100%"
                height="200px"
                controls={true}
                className="blog-video"
              />
            </div>
          ) : (
            <img src={blog.imageUrl} alt={blog.category} className="blog-image" />
          )}
          <div className="blog-content">
            <span className={`blog-category ${blog.categoryClass}`}>
              {blog.category}
            </span>
            <h2 className="blog-title">{blog.title}</h2>
            <p className="blog-description">{blog.description}</p>
            {/* {blog.description2 && <p className="blog-description">{blog.description2}</p>} */}
            <div className="blog-author">
              <div className="author-left">
                <img
                  src={blog.icon === "readIcon" ? readIcon : videoIcon}
                  alt={blog.icon === "readIcon" ? "Okuma İkonu" : "Video İkonu"}
                  className="icon"
                />
                <span
                  className={
                    blog.icon === "readIcon" ? "read-time" : "watch-time"
                  }
                >
                  {blog.readTime || blog.watchTime}
                </span>
              </div>
              <div className="author-right">
                <span className="blog-time">{formatDate(blog.time)}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Bloger;
