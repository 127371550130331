import "../operations.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from "../../services/contactCard/contactCard";



const HairOperations = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="operations-body">
      <div className="md:grid grid-cols-2 operations-header">
        <div className="operations-responsive-header hair-header-image"></div>
        <div className="operations-header-text">
          <h1 className="operations-header-title">Sağlıklı ve Güçlü Saçlar İçin İşlemlerimiz</h1>
          <h3 className="operations-header-title2">
            Saç tedavilerimizle daha güçlü, dolgun ve sağlıklı saçlara kavuşun. Uzman ekibimizle saçınızın ihtiyaçlarına özel çözümler sunuyoruz.
            <br />
            <a className="operations-header-link" href="#sac-mezoterapisi">
              <div className='operations-header-link-div'>
              <div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> 
                                <div> Saç Mezoterapisi</div>
              </div></a>
            <a className="operations-header-link" href="#sac-botoksu"><div className='operations-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> 
                                <div>Saç Botoksu</div></div></a>
            <a className="operations-header-link" href="#sac-dolgusu"><div className='operations-header-link-div'><div className='link-icon-div'><i class="fa-solid fa-arrow-right link-icon"></i></div> 
                                <div>Saç Dolgusu</div></div></a>

          </h3>
        </div>
        <div className="operations-header-image hair-header-image"></div>
      </div>
      <div id="sac-mezoterapisi"></div>
      <div className="operations-content">
        <h1 className="operations-main-title" >
        Saç Mezoterapisi
        </h1>
        <p className="operations-text">
        Saç mezoterapisi, saç dökülmesini yavaşlatmak, saç yoğunluğunu artırmak ve saç derisinin hidrasyonunu iyileştirmek amacıyla saç derisine mikro enjeksiyon yoluyla aktif bileşenlerin enjekte edildiği bir tedavi yöntemidir.  Tedavide hyaluronik asit, amino asitler, vitaminler, koenzimler ve mineral tuzları gibi hücresel yenilenmeyi teşvik eden ve saç derisini canlandıran aktif bileşenlerin kokteylleri kullanılır. Böylece saç foliküllerini uyararak daha güçlü saç tellerinin oluşumu desteklenir, saç hücrelerin çoğaltımı teşvik edilir ve saç telleri canlandırılır. 
        </p>

        <div className="md:grid gap-x-10 pt-4 grid-cols-2">
          <div className="operations-image hair-img1">
          </div>
          <div>
          <h1 className="operations-title2" >
          Saç Mezoterapisi Faydaları
        </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="fayda1">
                Saçın Yeniden Büyümesini Uyarır
                </AccordionHeader>
                <AccordionBody accordionId="fayda1">
                Mezoterapi, gerekli besin maddelerini doğrudan ihtiyaç duyulan noktalara sağlayarak daha sağlıklı ve daha dolgun saçları teşvik eden tembel saç foliküllerini harekete geçirebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda2">
                Özelleştirilmiş Tedavi
                </AccordionHeader>
                <AccordionBody accordionId="fayda2">
                Her seans, saç sorunlarının ana nedenini hedefleyen spesifik saç endişelerini ele almak üzere özelleştirilmiştir, bu da kişiye özel bir yaklaşım sağlar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda3">
                Minimal İnvaziv
                </AccordionHeader>
                <AccordionBody accordionId="fayda3">
                İşlem neredeyse ağrısızdır, tedavi sonrasında hiçbir iyileşme süresi olmadan, hemen günlük aktivitelere dönülebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda4">
                Saç Derisinin Sağlığını İyileştirir
                </AccordionHeader>
                <AccordionBody accordionId="fayda4">
                Kan dolaşımını artırarak ve saç derisindeki iltihabı azaltarak, saç büyümesi için daha sağlıklı bir ortam yaratır. Ayrıca, sağlıklı saçlar için hayati öneme sahip olan saçlı derinin doğal pH seviyelerinin geri kazanılmasına da yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="fayda5">
                Saç Dokusunu ve Kalitesini Geliştirir
                </AccordionHeader>
                <AccordionBody accordionId="fayda5">
                Saç büyümesini teşvik etmenin ötesinde, mezoterapi tedavileri, saç dokusunu önemli ölçüde iyileştirerek, onu daha parlak, daha taranabilir ve görsel olarak çekici hale getirir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>

        

        <h1 className="operations-title1">
        Saç Mezoterapi Öncesi Hazırlık Süreci
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Saçın Durumu ve Temizliği</b>
                <br /> Saç mezoterapisi öncesi saçın temiz olması, uygulamanın daha etkili olmasını sağlayabilir. Bu nedenle, bazı uzmanlar işlem öncesinde saçın yıkanmasını tavsiye eder. İşlemden sonra saçın bir süre yıkanmaması gerektiğinden, bu önemli bir adımdır.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Sigara ve Alkol Kullanımı</b>
                <br /> Sigara ve alkol, kan dolaşımını olumsuz etkileyebileceğinden, tedavi öncesi bırakılması önerilir. Bu maddeler, tedavinin etkinliğini azaltabilir ve iyileşme sürecini uzatabilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Kafeinli İçecekler ve Yeşil Çay</b> <br /> Tedavi öncesi kafeinli içeceklerden ve yeşil çaydan kaçınılması önerilir. Nitekim bu ürünler, vücuttaki kan dolaşımını etkileyerek tedavinin sonuçları üzerinde olumsuz etkilere sahip olabilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Düzenli Kullanılan İlaçlar</b> <br /> Eğer düzenli olarak ilaç kullanılıyorsa, bu ilaçlar tedavi öncesinde doktora bildirilmelidir. Kan seyreltici ilaçlar gibi bazı ilaçlar, tedavi sürecini ve sonrasındaki iyileşme dönemini etkileyebilir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Saç Bakım Ürünleri</b> <br /> Tedaviden önce saça losyon, krem gibi saç bakım ürünleri uygulanmamalıdır. Bu ürünler, saç derisinde kalıntı bırakarak tedavinin etkinliğini azaltabilir.
              </li>
            </p>
          </div>
        </div>

        <div className="md:grid pt-4 gap-x-10 grid-cols-2">

          <div>
          <h1 className="operations-title2">
          Saç Mezoterapi Sonrası Dikkat Edilmesi Gerekenler
        </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="dikkat1">
                Masaj Uygulaması
                </AccordionHeader>
                <AccordionBody accordionId="dikkat1">
                Çözeltinin saç derisine eşit şekilde dağılmasına yardımcı olmak için, tedavi sonrası hafif bir masaj yapılabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dikkat2">
                Yıkama ve Bakım
                </AccordionHeader>
                <AccordionBody accordionId="dikkat2">
                Tedaviden sonraki ilk 24 saat saç yıkanmamalıdır. Sonrasında, saçlar hafif bir şampuanla yıkanıp, yumuşak hareketlerle kurulanmalıdır. Ağır kimyasal içeren ürünlerden ve ısı uygulamalarından kaçınılmalıdır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dikkat3">
                Güneşten ve Makyajdan Kaçınmak
                </AccordionHeader>
                <AccordionBody accordionId="dikkat3">
                Tedavi sonrası ilk gün güneş ışığına maruz kalmaktan kaçınılmalıdır. Güneşin zararlı etkileri iyileşme sürecini olumsuz etkileyebilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dikkat4">
                Olumsuz Yan Etki Durumunda Doktora Başvurun
                </AccordionHeader>
                <AccordionBody accordionId="dikkat4">
                Herhangi bir rahatsızlık ya da yan etki durumunda, derhal ilgili doktora danışılmalıdır. İşlem sonrası hafif bir kızarıklık veya şişlik normaldir ancak şiddetli bir durumda profesyonel yardım alınmalıdır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="operations-image hair-img2">
          </div>
        </div>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="mezoterapi-soru1">
              Saç Mezoterapisi Saç Çıkarır mı?
              </AccordionHeader>
              <AccordionBody accordionId="mezoterapi-soru1">
              Saç mezoterapisi, saç köklerini besleyici öğelerle destekleyerek saç çıkışını teşvik edebilir. Saç dökülmesinin önlenmesine ve incelen saçların güçlenmesine yardımcı olur, ancak tamamen kel alanlarda yeni saç çıkışı garanti edilmez.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="mezoterapi-soru2">
              Saç Mezoterapisi Acıtır mı?
              </AccordionHeader>
              <AccordionBody accordionId="mezoterapi-soru2">
              Saç mezoterapisi, ince iğnelerle yapıldığı için hafif bir rahatsızlık hissedilebilir. Ancak, işlem öncesi uygulanan lokal anestezik kremler sayesinde acı minimalize edilir ve çoğu kişi işlemi rahatlıkla tolere edebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="mezoterapi-soru3">
              Saç Mezoterapisinin Yan Etkileri Nelerdir?
              </AccordionHeader>
              <AccordionBody accordionId="mezoterapi-soru3">
              En yaygın yan etkiler arasında tedavi edilen bölgede hafif kızarıklık, şişlik veya morarma bulunur. Bu etkiler genellikle geçicidir ve birkaç gün içinde kendiliğinden kaybolur. Nadiren alerjik reaksiyonlar görülebilir, bu nedenle alerjik durumlar mutlaka ilgili doktor ile paylaşılmalıdır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="mezoterapi-soru4">
              Saç Mezoterapisi Kaç Seanstır?
              </AccordionHeader>
              <AccordionBody accordionId="mezoterapi-soru4">
              Saç mezoterapisi genellikle 4-6 seans arasında tamamlanır. Ancak bu kişinin saç durumuna ve tedaviye verdiği yanıta göre değişiklik gösterebilir. Optimal sonuçlar için seanslar genellikle haftalık veya iki haftada bir düzenlenir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="mezoterapi-soru5">
              Saç Mezoterapisi ve PRP Arasındaki Fark Nedir?
              </AccordionHeader>
              <AccordionBody accordionId="mezoterapi-soru5">
              Saç mezoterapisi, saç köklerine doğrudan vitaminler, mineraller ve büyüme faktörleri gibi besin maddelerinin enjekte edilmesidir. PRP (Platelet Rich Plasma) ise hastanın kendi kanından alınan ve yoğunlaştırılan trombositlerin saç derisine enjekte edilmesidir. PRP, saç foliküllerini onarmak ve yeni saç büyümesini teşvik etmek için vücudun doğal iyileşme mekanizmalarını kullanır. Her iki yöntem de saç dökülmesini tedavi etmek için kullanılır, ancak farklı yaklaşımlar ve bileşenler içerir.
              </AccordionBody>
            </AccordionItem>
            
          </Accordion>
        </div>

        <ContactCard />

        <div id="sac-botoksu"></div>

        <hr className='operations-hr'></hr>

        <h1 className="operations-main-title" >
        Saç Botoksu
        </h1>
        <p className="operations-text">
        Saç Botoksu, yıpranmış saçları canlandırmayı ve onarmayı amaçlayan yoğun bir bakım uygulamasıdır. Adına rağmen, gerçek bir Botoks enjeksiyonu içermez. Bunun yerine, havyar yağı, B5 ve E vitaminleri, kolajen ve antioksidanlar gibi faydalı bileşenlerden oluşan bir kokteyldir.  İçeriğinde bulunan bileşenler, birlikte çalışarak saçı pürüzsüz, yumuşak ve nemli hale getirir. Bu bakım tüm saç tipleri için uygundur ve gözle görülür sonuçlar gösterir.
        </p>

        <h1 className="operations-title1">
        Saç Botoksu Nasıl Yapılır?
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Saçın Yıkanması</b>
                <br /> Tedaviye başlamadan önce, saçın temizlenmesi gerekir. Bu, saçın üzerindeki kir ve ürün kalıntılarını temizleyerek saç botoksu ürünlerinin saç tellerine daha iyi nüfuz etmesini sağlar.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Ürünün Uygulanması</b>
                <br /> Saç botoksu herhangi bir enjeksiyon içermez, bakım maskeleri saçın tamamına veya hasar görmüş bölümlerine uygulanır. Uygulama genellikle bir fırça veya tarak yardımıyla yapılır ve ürünün saçın her yerine eşit şekilde dağıtılmasına özen gösterilir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Bekleme Süresi</b> <br /> Ürün, saça uygulandıktan sonra belirli bir süre saçta bekletilir. Bu süre, kullanılan ürüne ve saçın durumuna göre değişiklik göstermekle birlikte genellikle 20-30 dakika arasındadır.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Durulama ve Kurulama</b> <br /> Ürün saçta yeterince bekletildikten sonra, saç iyice durulanır. Sonrasında saç, doğal bir şekilde kurumaya bırakılabilir veya düşük ısıda kurutma makinesi kullanılabilir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Son İşlemler</b> <br /> Bazı saç botoksu tedavilerinde, ürünün saça daha iyi bağlanması ve uzun süre kalıcı olması için saç düzleştirici ile ısı uygulaması yapılabilir. Ancak bu adım, kullanılan ürüne göre değişiklik gösterebilir.
              </li>
            </p>
          </div>
        </div>

        <div className="md:grid pt-4 gap-x-10 grid-cols-2">

          <div>
          <h1 className="operations-title2">
          Saç Botoksu Ne İşe Yarar?
        </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="botoks-yarar1">
                Hasar Görmüş Saçların Onarılması
                </AccordionHeader>
                <AccordionBody accordionId="botoks-yarar1">
                Saç botoksu, saçın hasar görmüş yapısını onararak saç tellerini güçlendirir. Bu tedavi, kırık saç uçları, güneşin neden olduğu hasarlar ve kimyasal işlemlerden dolayı yıpranmış saçlar için idealdir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-yarar2">
                Nemlendirme ve Yumuşatma
                </AccordionHeader>
                <AccordionBody accordionId="botoks-yarar2">
                Saç botoksu, saç tellerine derinlemesine nüfuz ederek yoğun bir şekilde nemlendirir. Bu işlem, saçın daha yumuşak ve parlak görünmesini sağlar. Ayrıca, saçın kolay taranmasına ve şekillendirilmesine yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-yarar3">
                Frizz (Kabarma) Kontrolü
                </AccordionHeader>
                <AccordionBody accordionId="botoks-yarar3">
                Nemlendirici özelliği sayesinde saç botoksu, özellikle nemli hava koşullarında ortaya çıkan kabarmayı kontrol altına alır. Bu, saçın daha düz ve pürüzsüz görünmesine olanak tanır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-yarar4">
                Saçın Dolgunlaşması
                </AccordionHeader>
                <AccordionBody accordionId="botoks-yarar4">
                Saç botoksu uygulaması, saç tellerini kalınlaştırır ve dolgun bir görünüm kazandırır. Bu, ince ve seyrek saçlara sahip kişiler için özellikle faydalıdır, çünkü saça ekstra hacim ve yoğunluk katar.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-yarar5">
                Uzun Süreli Etki
                </AccordionHeader>
                <AccordionBody accordionId="botoks-yarar5">
                Saç botoksu tedavisinin etkileri genellikle 2 ila 4 ay arasında sürer. Bu süre zarfında, saçın sağlıklı ve canlı görünümü korunur. Tedavinin etkisinin devam etmesi için düzenli bakım ve uygun ürünlerin kullanılması önerilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="operations-image hair-img3">
          </div>
        </div>

        
            <h1 className="operations-title1">
            Saç Botoksunun Zararları Var mıdır?
            </h1>
            <p className="operations-text">
            Saç botoksu, genel olarak saçın sağlığını ve görünümünü iyileştirmeyi amaçlayan güvenli bir tedavi olarak kabul edilir. Ancak, alerjik reaksiyonlar, uygulama hataları, beklentilerin karşılanmaması gibi potansiyel riskleri içerebilir. Tedavinin etkileri geçicidir ve düzenli olarak tekrarlamak gerekebilir, bu da uzun vadede saç yapısında değişikliklere neden olabilir. Tedaviye başlamadan önce bir uzmana danışmak, bu riskleri en aza indirmeye yardımcı olur.
            </p>

            <h1 className="operations-title1">
            Saç Botoksu Öncesi Hazırlık Süreci
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              Saç botoksu, öncesinde uygulanan doğru hazırlık süreci, tedavinin başarısını önemli ölçüde artırabilir. 

İşte saç botoksu öncesi hazırlık sürecine ilişkin öneriler:

              <li className="mt-2">
                <b className="operations-title3">Saçın Temizlenmesi</b>
                <br />Saç botoksu uygulamasından birkaç gün önce, saçınızı derinlemesine temizleyen bir şampuan ile yıkamak faydalıdır. Bu, saçın ve saç derisinin, tedavi sırasında ürünlerin daha iyi emilimini sağlayacak şekilde temiz ve kalıntısız olmasını sağlar.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Kimyasal İşlemlerden Kaçınma</b>
                <br />Saç boyama, perma gibi kimyasal işlemler, saç botoksu tedavisinden en az iki hafta önce yapılmamalıdır. Bu, saçın doğal yapısının tedaviye hazırlanması ve olası kimyasal reaksiyonların önlenmesi için önemlidir.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Saçın Durumunu Değerlendirme</b> <br /> Saç botoksu tedavisine başlamadan önce, saçınızın genel sağlık durumunu ve tedaviye ihtiyacını bir uzmanla değerlendirmek önemlidir. Bu, tedavinin saç tipinize uygun olup olmadığını anlamanıza yardımcı olur.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Saç Ürünlerini Sınırlama</b> <br />Tedavi günü, saçınıza herhangi bir ürün (saç spreyi, jel, mousse gibi) uygulamaktan kaçının. Saçınızın doğal durumda olması, saç botoksu uygulamasının etkinliği artırır.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Uzman Seçimi</b> <br /> Saç botoksu, deneyimli bir uzman tarafından uygulanmalıdır. Tedaviyi yapacak salon veya uzman hakkında önceden araştırma yapmak ve mümkünse önceki müşteri yorumlarını okumak, beklentilerinizin karşılanmasına yardımcı olur.
              </li>
            </p>
          </div>
        </div>
        

        <div className="pt-8 gap-x-10 md:grid grid-cols-2">
        <div className="operations-image hair-img4">
          </div>
          <div>      
            <h1 className="operations-title2">
            Saç Botoksu Sonrası Dikkat Edilmesi Gerekenler
            </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="botoks-dikkat1">
                İlk 48 Saat İçinde Saçınızı Yıkamayın
                </AccordionHeader>
                <AccordionBody accordionId="botoks-dikkat1">
                Saç botoksu tedavisi sonrasında, saçın üzerindeki ürünlerin saça tamamen nüfuz etmesine izin vermek için, ilk 48 saat boyunca saçınızı yıkamaktan kaçının. Bu süre, ürünün saça etki etmesi için gereklidir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-dikkat2">
                Sülfatsız Şampuan Kullanımı
                </AccordionHeader>
                <AccordionBody accordionId="botoks-dikkat2">
                İlk yıkamadan sonra, saçınızın nemini korumak ve tedavinin etkisini uzatmak için sülfatsız şampuan ve saç bakım ürünleri kullanın. Sülfat içeren ürünler, saçın doğal yağlarını çıkararak tedavinin etkisini azaltabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-dikkat3">
                Isıya Maruziyeti Sınırlayın
                </AccordionHeader>
                <AccordionBody accordionId="botoks-dikkat3">
                Saç botoksu sonrası, saç kurutma makinesi, düzleştirici veya maşa gibi ısıya dayalı şekillendirici aletlerin kullanımını minimuma indirin. Eğer kullanmak gerekiyorsa, düşük ısı ayarlarını tercih edin ve ısıya karşı koruyucu ürünler kullanın.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-dikkat4">
                Düzenli Bakım
                </AccordionHeader>
                <AccordionBody accordionId="botoks-dikkat4">
                Saç botoksu tedavisinin etkilerini uzatmak için, saçınıza düzenli olarak nemlendirici maskeler uygulayın. Bu, saçınızın yumuşak, pürüzsüz ve sağlıklı kalmasına yardımcı olur.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="botoks-dikkat5">
                Kimyasal İşlemlerden Kaçının
                </AccordionHeader>
                <AccordionBody accordionId="botoks-dikkat5">
                Saç botoksu uygulandıktan sonra, saçınıza boyama veya perma gibi ek kimyasal işlemler uygulamaktan kaçının. Bu tür işlemler, saçınızın yapısına zarar verebilir ve saç botoksu tedavisinin etkilerini azaltabilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>

          
        </div>

        <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="botoks-soru1">
              Saç Botoksu Ne İşe Yarar?
              </AccordionHeader>
              <AccordionBody accordionId="botoks-soru1">
              Saç botoksu, hasar görmüş ve yıpranmış saçları onarmak, nemlendirmek ve genel olarak saç sağlığını iyileştirmek için kullanılan besleyici bir tedavidir. Saç tellerine derinlemesine nüfuz ederek, saçı daha parlak ve yumuşak hale getirir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="botoks-soru2">
              Saç Botoksu ve Keratin Arasındaki Fark Nedir?
              </AccordionHeader>
              <AccordionBody accordionId="botoks-soru2">
              Saç botoksu, doğal içeriklerle zenginleştirilmiş bir tedavi olup, saça derinlemesine nem sağlar ve onarır. Keratin tedavisi ise saçın doğal keratinini yeniden yapılandırarak, uzun süreli düz bir görünüm sağlar ve friz kontrolü sunar. Ana fark, saç botoksunun saçı besleyici öğelerle onarması; keratin tedavisinin ise daha çok düzleştirme ve pürüzsüzleştirme üzerine odaklanmasıdır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="botoks-soru3">
              Saç Botoksu Saçı Düzleştirir mi?
              </AccordionHeader>
              <AccordionBody accordionId="botoks-soru3">
              Saç botoksu esas olarak onarıcı ve nemlendirici bir tedavi olduğundan, saçı düzleştirme amacı taşımaz. Ancak, saçın daha iyi kontrol altına alınmasını sağlayarak, dolaylı olarak düzleştirici bir etki sunabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="botoks-soru4">
              Saç Botoksunun Etkisi Ne Kadar Sürer?
              </AccordionHeader>
              <AccordionBody accordionId="botoks-soru4">
              Saç botoksunun etkileri genellikle 2 ila 4 ay arasında sürer. Uygulamanın kalıcılığı, saçın yapısal özelliklerine, kullanılan ürünün kalitesine ve kişinin saç bakım rutinine bağlı olarak değişiklik gösterebilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="botoks-soru5">
              Saç Botoksundan Kaç Gün Sonra Saç Yıkanabilir?
              </AccordionHeader>
              <AccordionBody accordionId="botoks-soru5">
              Saç botoksu uygulamasından sonra, saçın üzerindeki ürünlerin etkili bir şekilde nüfuz etmesine izin vermek için genellikle ilk 48 saat içinde saç yıkanmamalıdır. Bu süre zarfında saçı yıkamak, tedavinin etkilerini azaltabilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>

        </div>
   
        <div id="sac-dolgusu"></div>

        <hr className='operations-hr'></hr>
       
        <h1 className="operations-main-title" >
        Saç Dolgusu
        </h1>
        <p className="operations-text">
        Saç dolgusu, özellikle saç dökülmesi yaşayanlar için geliştirilen bir tedavi yöntemidir. Temel amacı, saçın kalitesini ve yoğunluğunu artırarak daha sağlıklı ve dolgun görünmesini sağlamaktır. İşlem, kıl köklerini canlandırarak saçlı derinin yeniden yapılanmasına yardımcı olur ve genellikle peptit kompleksi, büyüme faktörleri ve kök hücre destekli ürünler kullanılarak uygulanır.
        </p>

        <h1 className="operations-title1">
        Saç Dolgusu Nasıl Yapılır?
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Danışma ve Değerlendirme</b>
                <br /> İlk adım bir uzmana danışmaktır. Bir uzman saçınızın durumunu değerlendirecek ve sizin için en uygun saç dolgusu yöntemini önerecektir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Saçın Hazırlanması</b>
                <br /> Saç dolgusu işleminden önce saçın temiz ve kuru olması önemlidir. Saç derinizin temiz olduğundan emin olun, ardından saçınızı yıkayın ve kurutarak işleme hazırlayın.
              </li>
              <li className="mt-2">

                <b className="operations-title3">Saç Dolgu Malzemelerinin Uygulanması</b> <br /> Saç dolgu malzemeleri saç köklerine enjekte edilir veya doğrudan kafa derisine uygulanır. Bu malzemeler saça hacim ve kalınlık kazandırmak için özel olarak formüle edilmiştir. Enjeksiyon veya uygulama işlemi, daha dolgun ve kalın bir görünüm elde edilmesine yardımcı olur.
              </li>
            </p>
          </div>
        </div>

        <div className="md:grid pt-4 gap-x-10 grid-cols-2">

          <div>
          <h1 className="operations-title2">
          Saç Dolgusunun Yararları Nelerdir?
        </h1>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="dolgu-yarar1">
                Artan Hacim ve Kalınlık
                </AccordionHeader>
                <AccordionBody accordionId="dolgu-yarar1">
                Saç dolgusu, saçlara hacim ve kalınlık katarak daha dolgun ve hacimli bir görünüm sağlar. Prosedür, bireylerin istenen saç yoğunluğuna ulaşmalarına ve özgüvenlerini geri kazanmalarına yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dolgu-yarar2">
                Doğal Sonuçlar
                </AccordionHeader>
                <AccordionBody accordionId="dolgu-yarar2">
                Saç dolgusu saçın doğal büyüme modelini taklit edecek şekilde tasarlanmıştır. Dolgu için kullanılan malzemeler, mevcut saçın rengine, dokusuna ve yapısına uyacak şekilde özenle seçilir, böylece kusursuz bir entegrasyon ve doğal görünümlü sonuçlar elde edilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dolgu-yarar3">
                Hızlı Geri Dönüş
                </AccordionHeader>
                <AccordionBody accordionId="dolgu-yarar3">
                Saç dolgusu, kısa bir iyileşme süresi sunan minimal invaziv bir prosedürdür. Çoğu kişi işlemden kısa bir süre sonra günlük aktivitelerine devam edebilir. Bu, saç dolgusunu yoğun programları olanlar için uygun bir seçenek haline getirir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dolgu-yarar4">
                Uzun Ömürlü Sonuçlar
                </AccordionHeader>
                <AccordionBody accordionId="dolgu-yarar4">
                Uygun bakım ve muhafaza ile saç dolgusunun sonuçları uzun bir süre boyunca korunabilir. İşlemden sonra uyarılan yeni saç folikülleri büyümeye devam eder ve sürekli hacim ve kalınlık sunar. Düzenli takip uygulamaları, zaman içinde istenen sonuçların korunmasına yardımcı olabilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="dolgu-yarar5">
                Çok Yönlü ve Özelleştirilebilir
                </AccordionHeader>
                <AccordionBody accordionId="dolgu-yarar5">
                Saç dolgusu prosedürleri bireysel ihtiyaç ve tercihleri karşılayacak şekilde uyarlanabilir. İster kısmi saç dökülmesi, ister saç incelmesi veya saç çizgilerinin azalması ile uğraşıyor olun, saç dolgusu belirli endişe alanlarına hitap edecek şekilde ayarlanabilir. Tedavi, her hasta için istenen sonucu elde etmek üzere kişiselleştirilebilir.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="operations-image hair-img5">
          </div>
        </div>

        <h1 className="operations-title1">
        Saç Dolgusu Öncesi Hazırlık Süreci
        </h1>
        <div className="">
          <div>
            <p className="operations-text">
              <li className="mt-2">
                <b className="operations-title3">Konsültasyon</b>
                <br /> İlk adım, saç restorasyonu konusunda uzmanlaşmış bir saç uzmanı veya dermatolog ile bir konsültasyon planlamaktır. Konsültasyon sırasında uzman, saç durumunuzu değerlendirecek, hedeflerinizi tartışacak ve sizin için en uygun saç dolgusu tekniğini belirleyecektir.
              </li>
              <li className="mt-2">
                <b className="operations-title3">Kan İnceltici İlaçlardan Kaçınma</b>
                <br />Saç dolgusu işleminden önce aspirin veya ibuprofen gibi kan inceltici ilaçlardan kaçınmak çok önemlidir. Bu ilaçlar işlem sırasında kanama riskini artırabilir. 
              </li>
              <li className="mt-2">

                <b className="operations-title3">Sigara ve Alkolü Bırakma</b> <br /> Sigara ve aşırı alkol tüketimi iyileşme sürecini bozabilir ve saç dolgusu işleminin başarısını etkileyebilir. İşlemden önce ve sonra belirli bir süre boyunca sigaranın bırakılması ve alkol alımının sınırlandırılması tavsiye edilir. Bu, daha iyi kan dolaşımını teşvik etmeye yardımcı olur ve iyileşme sürecini hızlandırır.
              </li>
              <li className="mt-2">

                <b className="operations-title3">İşlem Öncesi Talimatlara Uyma</b> <br /> Uzman doktor, saç dolgusu işleminden önce size özel talimatlar verecektir. Bu talimatlar, saçınızı reçeteli bir şampuanla yıkamayı ve işlem günü herhangi bir saç ürünü kullanmaktan kaçınmayı içerebilir. 
              </li>
            </p>
          </div>
        </div>

        <h1 className="operations-title1">
        Saç Dolgusu Sonrası Dikkat Edilmesi Gerekenler
        </h1>
        
            <p className="operations-text">
            Saç dolgusu sonrasında dikkat edilmesi gerekenler uzmanın verdiği talimatları dikkatlice takip etmek, tedavi alanına dokunmaktan veya kaşımaktan kaçınmak ve kafa derisini doğrudan güneş ışığından korumak gibi iyileşme süreci için hayati öneme sahip hususları içerebilir. Bu talimatlar, işlem sonrası kafa derisinin nasıl temizleneceği, ilaçların nasıl kullanılacağı ve takip randevuları gibi önemli bilgileri içerir. Güneşin zararlı ışınlarından korunmak için şapka giymek veya güneş kremi kullanmak, dengeli beslenme, yeterli uyku gibi sağlıklı yaşam tarzı alışkanlıklarını benimsemek, saç dolgusu sonuçlarının korunmasına yardımcı olur.
            </p>
          

            <h1 className="operations-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="dolgu-soru1">
              Saç dolgusu işe yarar mı?
              </AccordionHeader>
              <AccordionBody accordionId="dolgu-soru1">
              Evet, saç dolguları saç dökülmesinin tedavisinde, saç yoğunluğunun artırılmasında ve ince saçların güçlendirilmesinde etkilidir.  
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="dolgu-soru2">
              Saçlı deriye saç dolgusu yapılır mı?
              </AccordionHeader>
              <AccordionBody accordionId="dolgu-soru2">
              Evet yapılabilir. Saç dolguları özellikle saçlı deriye uygulanmak üzere tasarlanmıştır. Prosedür, peptitler gibi aktif bileşenler içeren bir jelin doğrudan saçlı deriye enjekte edilmesini içerir. Bu, saç büyümesini uyarmaya, kafa derisini beslemeye ve saç dökülmesini önlemek için saç folikülü boyutunu artırmaya yardımcı olur.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="dolgu-soru3">
              Saç dolgusunun yan etkisi var mı?
              </AccordionHeader>
              <AccordionBody accordionId="dolgu-soru3">
              Doğal malzemelerden oluşan saç dolguları genellikle güvenlidir ve çok az yan etkiye sahiptir. Bazı hastalar enjeksiyon bölgesinde hafif kızarıklık, şişlik veya hafif ağrı yaşayabilir, ancak bu semptomlar tipik olarak 72 saat içinde azalır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="dolgu-soru4">
              Saç dolgusu ağrılı bir işlem mi?
              </AccordionHeader>
              <AccordionBody accordionId="dolgu-soru4">
              Hayır, saç dolguları genellikle ağrılı değildir. Herhangi bir rahatsızlık hissi minimaldir ve sadece enjeksiyon bölgesinde hafif şişlik veya kızarıklık gibi geçici yan etkiler şeklinde görülür.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="dolgu-soru5">
              Saç dolgusu ne kadar sürer?
              </AccordionHeader>
              <AccordionBody accordionId="dolgu-soru5">
              Saç dolgusunun sonuçları önemli bir süre boyunca devam edebilir, bazı uygulamalar altı aya kadar etki gösterir. Ancak bu, kişinin saç durumuna ve izlenen işlem protokolüne bağlı olarak değişebilir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>

        </div>







      </div>
    </div>
  );
};

export default HairOperations;
