import "./mobileSwiper.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import ozon from "./ozon.jpeg";
import iv from "./iv-therapy.jpg";
import kok from "./kok-hucre-tedavi.png";
import exosomeTherapy from "./exosome-therapy.png";
import rejuvenation from "./rejuvenation-with-placenta.jpg";
import genetic from "./genetic-treatments.jpg";

import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";

const MobileSwiper = ({ searchQuery }) => {
  const cardInfo = [
    {
      title: "Genetik Test",
      image: genetic,
      target: "#geneticModal",
    },
    {
      title: "Longevity (Uzun Yaşam)",
      image: rejuvenation,
      target: "#rejuvenationModal",
    },
    {
      title: "IV Terapi",
      image: iv,
      target: "#ivModal",
    },
    {
      title: "Kök Hücre Tedavisi",
      image: kok,
      target: "#kokModal",
    },
    {
      title: "Eksozom Terapi",
      image: exosomeTherapy,
      target: "#eksozomModal",
    },
    {
      title: "Ozon Terapi",
      image: ozon,
      target: "#ozonModal",
    },
  ];
  const matchedCards = cardInfo.filter((card) =>
    card.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="swiper-container">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        // autoplay={{
        //   delay: 2000,
        //   disableOnInteraction: false,
        // }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[Autoplay, EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        {matchedCards.map((card, index) => (
          <SwiperSlide key={index} className="mobil-swiper-slide">
            <img src={card.image} alt={card.title} />
            <div>
              <h3 className="mobile-swiper-h3">{card.title}</h3>
              <button
                type="button"
                className="carousel__add_mobil"
                data-bs-toggle="modal"
                data-bs-target={card.target}
              >
                <svg
                  className="is-flex"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  role="presentation"
                >
                  <rect width="18" height="18" fill="none"></rect>
                  <path
                    d="M1416,570v-5h-5a1,1,0,1,1,0-2h5v-5a1,1,0,0,1,2,0v5h5a1,1,0,0,1,0,2h-5v5a1,1,0,0,1-2,0Z"
                    transform="translate(-1408 -555)"
                    fill="#032742"
                  ></path>
                </svg>
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MobileSwiper;
