import React from 'react';
import { Link } from "react-router-dom";
import "./modal.scss";

const GeneticModal = () => {

  const handleLinkClick = () => {
    // btn-close butonunu seç ve tıklama olayını tetikle
    const closeButton = document.querySelector('#geneticModal .btn-close');
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="geneticModal"
      tabIndex={-1}
      aria-labelledby="geneticModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <strong className="modal-title fs-5" id="geneticModalLabel">
              Kişiselleştirilmiş Tıbbın Geleceği
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            Genetik tedavi, hastalıkların genetik nedenlerini hedef alarak
            tedavi etmeyi amaçlayan yenilikçi bir tıbbi yaklaşımdır. Bu tedavi
            yöntemi, DNA'daki hatalı genlerin düzeltilmesi veya değiştirilmesi
            ile gerçekleştirilir. Genetik tedavi, kalıtsal hastalıkların,
            kanserlerin ve nadir genetik bozuklukların tedavisinde umut vaat
            eder. Bilim ve teknolojideki ilerlemeler sayesinde, genetik
            tedavinin potansiyeli her geçen gün artmakta ve gelecekte daha
            yaygın bir tedavi seçeneği haline gelmesi beklenmektedir.
          </div>
          <div className="modal-footer">
            <Link to="/genetik" type="button" className="btn" onClick={handleLinkClick}>
              Daha Fazlası
            </Link>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneticModal;
