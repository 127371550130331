import "../layout.css";
// import { useState } from "react";
// import {
//   Accordion,
//   AccordionBody,
//   AccordionHeader,
//   AccordionItem,
// } from "reactstrap";
import ContactCard from '../contactCard/contactCard';


const Eksozom = () => {
  // const [open, setOpen] = useState("1");
  // const toggle = (id) => {
  //   if (open === id) {
  //     setOpen();
  //   } else {
  //     setOpen(id);
  //   }
  // };

  return (
    <div id="services-body">
      <div className="md:grid grid-cols-2 services-header">
        <div className="services-responsive-header eksozom-header-image"></div>
        <div className="services-header-text">
          <h1 className="services-header-title">Eksozom Terapi</h1>
          <h3 className="services-header-title2">
          Eksozom terapi, vücudumuzun iyileşme sürecine destek olmak için küçük hücre dışı veziküller olan eksozomları kullanır. Bu veziküller, proteinler, yağlar ve RNA gibi maddeler taşıyarak hücreler arası iletişimi sağlar ve hücrelerin birbirleriyle önemli bilgileri ve materyalleri paylaşmasına olanak tanır. Eksozom terapi, hasarlı dokuların iyileşmesini ve yeni doku oluşumunu teşvik ederek, hücre bazlı tedavilere alternatif bir yöntem sunar.
          </h3>
        </div>
        <div className="services-header-image eksozom-header-image"></div>       
      </div>

      <div className="services-content">
        <h1 className="services-title1">Eksozom Terapi Nasıl Uygulanır?</h1>
        <p className="services-text">
          Eksozom terapide ilk adım, küçük vezikülleri kök hücrelerden elde
          etmektir. Kök hücreler, eksozom üretmek üzere teşvik edilir ve elde
          edilen eksozomlar, bir dizi filtreleme ve santrifüj işleminden sonra
          izole edilir. Bu süreç, tedavi için yüksek saflıkta ve konsantrasyonda
          eksozom elde edilmesini sağlar. İzole edilen eksozomlar, doğrudan
          hasar gören bölgeye enjekte edilebilir, damar yoluyla verilebilir veya
          cilt üzerine uygulanabilir. Terapi yöntemi, tedavi edilmek istenen
          duruma bağlı olarak seçilir.
        </p>

        <div className="gap-x-10 pt-8 md:grid grid-cols-2">
          <div className="services-image eksozom-img"></div>
          <div>
            <h1 className="services-title2">
              Eksozom Terapinin Faydaları Nelerdir?
            </h1>
            <p className="services-text">
              Eksozom terapinin en büyük avantajlarından biri, hücre nakli
              gerektirmemesidir. Bu durum, organ uyumsuzluğu gibi riskleri ve
              diğer potansiyel komplikasyonları azaltır. Ayrıca, eksozomlar
              belirli tedavi hedeflerine yönelik olarak tasarlanabilir, böylece
              tedavinin etkinliği artarken yan etkiler azalır. Terapinin minimal
              invaziv yapısı, doğal iyileşme sürecini teşvik eder ve bazı
              durumlarda cerrahiye veya uzun süreli ilaç tedavilerine alternatif
              bir yöntem sunar.
            </p>
          </div>
        </div>

        <ContactCard />

  
      </div>
    </div>
  );
};

export default Eksozom;
