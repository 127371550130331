import '../layout.css';
import React from 'react';


const ContactCard = () => {
    return (
        <div>
            <div className="pt-8">
                <div className="contact-card grid grid-cols-3 px-18 contact-card-image">
                    <div className="md:pt-16 md:pr-8 h-80 col-span-2 text-center ">
                        <h1 className="contact-card-title">Daha fazlasını merak ediyor musunuz?</h1>
                        
                        <h3 className="md:text-xl">
                        Size özel kişiselleştirilmiş bir tedavi rutini hazırlayalım.
                        </h3>
                        <a href="/contact" className="contact-card-link">
                            BİZE ULAŞIN!
                        </a>
                    </div>
                    <div className=""></div>
                </div>
            </div>
        </div>
    )
}

export default ContactCard;