import "../layout.css";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import ContactCard from "../contactCard/contactCard";

const Genetik = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div id="services-body">
      <div className="md:grid grid-cols-2  services-header">
      <div className="services-responsive-header genetik-header-image"></div>
        <div className="services-header-text">
          <h1 className="services-header-title">Genetik Test</h1>
          <h3 className="services-header-title2">
            Genetik tedaviler, bireyin DNA'sını ve genetik yapısını inceleyerek,
            kişiye özel güzellik ve sağlık çözümleri sunmayı amaçlayan yenilikçi
            bir tıp dalıdır. Genetik analizler sayesinde cilt tipi, yaşlanma
            süreci, saç yapısı ve metabolizma gibi birçok biyolojik özellik
            ayrıntılı bir şekilde değerlendirilir ve bu bilgiler ışığında en
            etkili tedavi yöntemleri belirlenir.
          </h3>
        </div>
        <div className="genetik-header-image services-header-image"></div>
      </div>
      <div className="services-content">
        <div>
          <h1 className="services-title1">Neden Genetik Test?</h1>
          <p className="services-text">
            Genetik tedaviler, standart güzellik ve bakım yöntemlerinden farklı
            olarak, her bireyin genetik özelliklerine göre özelleştirilmiş
            çözümler sunar. Bu sayede, tedavi süreçleri daha etkili ve kalıcı
            olur. Genetik tedavilerin sunduğu avantajlar arasında şunlar yer
            alır:
          </p>
        </div>

        <div className="md:grid gap-x-10 pt-8 grid-cols-2">
          <div className="genetik-img1 services-image"></div>
          <div>
            <p className="services-text">
              <li className="mt-2">
                <b className="services-title3">Kişiye Özel Çözümler</b>
                <br />
                Her bireyin genetik yapısı farklıdır. Bu yüzden, genetik
                analizler sonucunda en uygun tedavi yöntemleri belirlenir.
              </li>
              <li className="mt-2">
                <b className="services-title3">Yüksek Etkinlik </b>
                <br />
                Genetik bilgiler ışığında hazırlanan tedavi planları, hedefe
                yönelik ve daha etkili sonuçlar verir.
              </li>
              <li className="mt-2">
                <b className="services-title3">Uzun Süreli Sonuçlar </b>
                <br />
                Genetik tedaviler, sorunun kökenine inerek kalıcı çözümler
                sunar.
              </li>
            </p>
          </div>
        </div>
        <h1 className="services-title1">Genetik Test Nasıl Uygulanır?</h1>
        <p className="services-text">
          Genetik tedavi süreci genellikle şu adımları içerir:{" "}
        </p>

        <div className="md:grid pt-4 gap-x-10 grid-cols-2">
          <div>
            <Accordion flush open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="genetik1">
                  Genetik Test
                </AccordionHeader>
                <AccordionBody accordionId="genetik1">
                  Basit bir kan örneği veya tükürük testi ile bireyin genetik
                  yapısı analiz edilir. Bu örnekler laboratuvara gönderilerek
                  genetik bilgilerin detaylı bir analizi yapılır.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="genetik2">
                  Veri Analizi
                </AccordionHeader>
                <AccordionBody accordionId="genetik2">
                  Genetik test sonuçları uzmanlar tarafından detaylı bir şekilde
                  incelenir. Cilt, saç, metabolizma ve hormonlar gibi farklı
                  alanlara yönelik veriler değerlendirilir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="genetik3">
                  Kişiye Özel Plan
                </AccordionHeader>
                <AccordionBody accordionId="genetik3">
                  Analiz sonuçlarına göre, kişiye özel bir tedavi ve bakım planı
                  hazırlanır. Bu plan, genetik yapınıza en uygun ürünler, diyet
                  programları ve tedavi yöntemlerini içerir.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem>
                <AccordionHeader targetId="genetik4">Uygulama</AccordionHeader>
                <AccordionBody accordionId="genetik4">
                  Hazırlanan plan doğrultusunda tedavi süreçleri başlatılır.
                  Düzenli takipler ve kontrollerle tedavi sürecinin etkinliği
                  değerlendirilir ve gerektiğinde güncellemeler yapılır.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="services-image genetik-img2"></div>
        </div>
        <div className="">
          <div>
            <h1 className="services-title1">
              Genetik Testlerle Geleceğinizi Şekillendirin
            </h1>
            <p className="services-text">
              Genetik testler, bireyin genetik yapısını keşfederek, daha
              sağlıklı ve mutlu bir yaşam sürmesini sağlar. Siz de genetik
              testlerle geleceğinizi şekillendirin, güzellik ve sağlık alanında
              en etkili çözümleri deneyimleyin. Genetik biliminin sunduğu
              olanaklarla, kendinize en uygun tedavi ve bakım planlarını
              keşfedin.
            </p>
          </div>
        </div>

        <ContactCard />

        <h1 className="services-title1">Sık Sorulan Sorular</h1>
        <div className="">
          <Accordion flush open={open} toggle={toggle}>
            <AccordionItem>
              <AccordionHeader targetId="soru1">
                Genetik ile güzellik tedavisi nedir?
              </AccordionHeader>
              <AccordionBody accordionId="soru1">
                Genetik ile güzellik tedavisi, bireylerin genetik yapılarının
                analiz edilerek, cilt bakımı, saç tedavisi ve estetik cerrahi
                gibi estetik uygulamaların kişiselleştirilmesi işlemidir. Bu
                tedaviler, genetik bilgilerin ışığında bireye özel olarak
                planlanır ve uygulanır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru2">
                Genetik analiz nasıl yapılır ve ne tür bilgiler sağlar?
              </AccordionHeader>
              <AccordionBody accordionId="soru2">
                Genetik analiz, genellikle bir kan veya tükürük örneği alınarak
                gerçekleştirilir. Bu örnekler laboratuvarda incelenir ve bireyin
                DNA'sı analiz edilerek cilt tipi, yaşlanma süreci, saç dökülmesi
                gibi estetikle ilgili genetik faktörler hakkında bilgi sağlar.
                Bu bilgiler, kişiye özel tedavi planlarının oluşturulmasında
                kullanılır.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru3">
                Hangi estetik problemler genetik ile çözülebilir?
              </AccordionHeader>
              <AccordionBody accordionId="soru3">
                Genetik ile çözülebilen estetik problemler arasında cilt
                yaşlanması, saç dökülmesi ve incelmesi, cilt hassasiyeti ve akne
                gibi cilt problemleri yer alır. Ayrıca, estetik cerrahi
                müdahalelerinin planlanmasında da genetik bilgiler
                kullanılabilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru4">
                Genetik analiz sonuçları nasıl yorumlanır ve uygulanır?
              </AccordionHeader>
              <AccordionBody accordionId="soru4">
                Genetik analiz sonuçları, uzmanlar tarafından yorumlanır ve
                bireyin genetik yapısına uygun tedavi planları oluşturulur.
                Örneğin, cilt yaşlanmasıyla mücadele eden bir birey için hangi
                anti-aging ürünlerin veya tedavi yöntemlerinin daha etkili
                olacağı belirlenir. Saç dökülmesi problemi yaşayan biri için
                ise, genetik yapısına uygun saç tedavi yöntemleri önerilir.
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId="soru6">
                Genetik ile güzellik tedavisi kimler için uygundur?
              </AccordionHeader>
              <AccordionBody accordionId="soru6">
                Genetik ile güzellik tedavisi, estetik kaygıları olan herkes
                için uygundur. Özellikle, cilt bakımı, saç sağlığı ve estetik
                cerrahi konularında daha etkili ve kişiselleştirilmiş çözümler
                arayan bireyler için idealdir. Ancak, tedaviye başlamadan önce
                bir genetik uzmanı ve estetik doktoru ile görüşmek önemlidir.
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Genetik;
