import { useState, useEffect } from 'react';
import axios from 'axios';
import "./wpIcon.css";

const Icons = () => {
  const [whatsappNumber, setWhatsappNumber] = useState('');

  useEffect(() => {
    fetchWhatsappNumber();
  }, []);

  const fetchWhatsappNumber = async () => {
    try {
      const response = await axios.get(`https://genesis-api-l0zr.onrender.com/whatsapp`); 
      if (response.data.length > 0) {
        const rawNumber = response.data[0].number;
        // Numaradaki boşlukları ve + işaretini kaldırarak sadece rakamları al
        const cleanedNumber = rawNumber.replace(/\s+/g, '').replace('+', '');
        // Başına +90 ekle
        const formattedNumber = "+90" + cleanedNumber;
        setWhatsappNumber(formattedNumber);
      }
    } catch (error) {
      console.error('Error fetching whatsapp number:', error);
    }
  };
  
  return (
    <>
      <div className="whats-float">
        <a
          href={`https://wa.me/${whatsappNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa-brands fa-whatsapp"></i>
          <span>
            WhatsApp
            <br />
            <small>{whatsappNumber}</small>
          </span>
        </a>
      </div>
    </>
  );
};

export default Icons;