import React from "react";
import "./Blog.css";
import acne from "../../assets/akne.jpg";
import natur from "../../assets/natur.jpeg";
import sun from "../../assets/sun.jpg";
import videoIcon from "../../assets/video.png"; // Video ikonu
import readIcon from "../../assets/read.png"; // Okuma ikonu
import { useNavigate, Link } from "react-router-dom";

const Blog = () => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate("/blog");
    window.location.reload();
  };

  return (
    <div id="blg" className="blog-container">
      <div className="blog-header-section">
        <div className="blog-header">
          <h2 className="blog-title">Cilt Bakımı Rehberi</h2>
          <p className="blog-description">
            Cilt bakımındaki hokus pokusu, sahte iddialar ve zayıf formüller bir
            tam zaman işi - bizim işimiz. Bu yüzden güvenebileceğiniz bilgiler
            sunmak için fazlaca makale hazırladık.
          </p>
          <button className="explore-btn" onClick={handleExploreClick}>
            DAHA FAZLA KEŞFET
          </button>
        </div>
      </div>
      <div className="blog-cards-section">
        <div onClick={handleExploreClick} className="blog-card">
          <div className="blog-card-image">
            <img src={acne} alt="Akne" />
          </div>
          <div className="blog-card-content">
            <h3 className="blog-card-title">
              Vücut Aknesini Yok Etmenin 5 Temel İpucu: Pürüzsüz Bir Cilt İçin
              Rehberiniz
            </h3>
            <Link
              onClick={handleExploreClick}
              className="blog-card-description"
            >
              <div>
                Akneye karşı savaş açın ve cildinizi pürüzsüz hale getirin. Bu
                makalede, vücut aknesini yok etmek için beş temel ipucunu
                keşfedeceksiniz.
              </div>
            </Link>
            <div className="icon-wrapper">
              <img className="icon" src={readIcon} alt="Okuma Süresi" />
              <span className="blog-card-read-time">3 dakika okuma süresi</span>
            </div>
          </div>
        </div>
        <div onClick={handleExploreClick} className="blog-card">
          <div className="blog-card-image">
            <img src={natur} alt="Doğal İçerikler" />
          </div>
          <div className="blog-card-content">
            <h3 className="blog-card-title">
              Cilt Bakımı İçin Doğal İçerikler
            </h3>
            <Link
              onClick={handleExploreClick}
              className="blog-card-description"
            >
              <div>
                Güneşli serenatlarla buzlu düetlere kadar, cilt bakım rutininin
                uyumlu hale getirilmesinin sırlarını çözüyoruz...
              </div>
            </Link>
            <div className="icon-wrapper">
              <img className="icon" src={readIcon} alt="Okuma Süresi" />
              <span className="blog-card-read-time">3 dakika okuma süresi</span>
            </div>
          </div>
        </div>
        <div onClick={handleExploreClick} className="blog-card">
          <div className="blog-card-image">
            <img src={sun} alt="Hava Durumu" />
          </div>
          <div className="blog-card-content">
            <h3 className="blog-card-title">
              Hava Durumu Cildinizi Nasıl Etkiler?
            </h3>
            <Link
              onClick={handleExploreClick}
              className="blog-card-description"
            >
              <div>
                Hava durumu cildiniz üzerinde büyük bir etkiye sahiptir. Bu
                makalede, farklı hava koşullarının cildinizi nasıl etkilediğini
                ve buna karşı nasıl korunabileceğinizi öğrenin.
              </div>
            </Link>
            <div className="icon-wrapper">
              <img className="icon" src={videoIcon} alt="Video Süresi" />
              <span className="blog-card-read-time">
                10 dakika izleme süresi
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
