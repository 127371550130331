import { motion } from "framer-motion";
import { useState } from "react";
import MobileSearchBar from "../searchBar/MobileSearchBar";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const Links = ({ toggleMenu }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [estetikDropdownOpen, setEstetikDropdownOpen] = useState(false);

  const items = [
    { name: "Anasayfa", href: "/" },
    { name: "Hizmetlerimiz", href: "/", isDropdown: true, isEstetik: false },
    {
      name: "Estetik İşlemlerimiz",
      href: "/",
      isDropdown: true,
      isEstetik: true,
    },
    { name: "Blog", href: "/blog" },
    { name: "Hikayemiz", href: "/about" },
    { name: "İletişim", href: "/contact" },
  ];

  const dropdownItems = [
    { name: "Ozon Terapi", href: "/ozon" },
    { name: "IV Terapi", href: "/iv" },
    { name: "Kök Hücre Tedavisi", href: "/kokhucre" },
    { name: "Eksozom Tedavisi", href: "/eksozom" },
    { name: "Longevity (Uzun Yaşam)", href: "/genclesme" },
    { name: "Genetik Test", href: "/genetik" },
  ];

  const estetikDropdownItems = [
    { name: "Cilt", href: "/skin" },
    { name: "Saç", href: "/hair" },
    { name: "Vücut", href: "/body" },
    { name: "Yüz", href: "/face" },
  ];

  const handleClick = (href) => {
    toggleMenu(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) setEstetikDropdownOpen(false); // Close the other dropdown
  };

  const toggleEstetikDropdown = () => {
    setEstetikDropdownOpen(!estetikDropdownOpen);
    if (!estetikDropdownOpen) setDropdownOpen(false); // Close the other dropdown
  };

  return (
    <motion.div className="links" variants={variants}>
      <div className="searchBar">
        <MobileSearchBar toggleMenu={toggleMenu} />
      </div>
      {items.map((item) =>
        item.isDropdown ? (
          <div className="dropdown" key={item.name}>
            <motion.span
              className="dropbtn"
              variants={itemVariants}
              whileHover={{ scale: 1.5 }}
              whileTap={{ scale: 0.95 }}
              onClick={item.isEstetik ? toggleEstetikDropdown : toggleDropdown}
            >
              {item.name}
              <i className="fa-solid fa-caret-down ms-2 fs-7"></i>
            </motion.span>
            <motion.div
              className="dropdown-content"
              style={{
                display: item.isEstetik
                  ? estetikDropdownOpen
                    ? "flex"
                    : "none"
                  : dropdownOpen
                  ? "flex"
                  : "none",
              }}
              initial={{ opacity: 0, y: 50 }}
              animate={{
                opacity: item.isEstetik
                  ? estetikDropdownOpen
                    ? 1
                    : 0
                  : dropdownOpen
                  ? 1
                  : 0,
                y: item.isEstetik
                  ? estetikDropdownOpen
                    ? 0
                    : 50
                  : dropdownOpen
                  ? 0
                  : 50,
              }}
            >
              {(item.isEstetik ? estetikDropdownItems : dropdownItems).map(
                (dropdownItem) => (
                  <motion.a
                    href={dropdownItem.href}
                    key={dropdownItem.name}
                    variants={itemVariants}
                    whileHover={{ scale: 1.5 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleClick(dropdownItem.href)}
                  >
                    {dropdownItem.name}
                  </motion.a>
                )
              )}
            </motion.div>
          </div>
        ) : (
          <motion.a
            href={item.href}
            key={item.name}
            variants={itemVariants}
            whileHover={{ scale: 1.5 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleClick(item.href)}
          >
            {item.name}
          </motion.a>
        )
      )}
    </motion.div>
  );
};

export default Links;
