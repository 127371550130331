import React from "react";
import { Link } from "react-router-dom";
import "./modal.scss";

const Modal = () => {
  const handleLinkClick = () => {
    // btn-close butonunu seç ve tıklama olayını tetikle
    const closeButton = document.querySelector("#ozonModal .btn-close");
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="ozonModal"
      tabIndex={-1}
      aria-labelledby="ozonModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <strong className="modal-title fs-5" id="ozonModalLabel">
              Sağlık İçin Yenilikçi Bir Yöntem
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            Ozon terapi, ozon gazının tıbbi amaçlarla kullanılmasıdır. Bu tedavi
            yöntemi, ozonun antimikrobiyal, antioksidan ve anti inflamatuar
            özelliklerinden yararlanarak pek çok sağlık sorununun tedavisinde
            uygulanır. Ozon gazı, vücuda uygulandığında oksijenle reaksiyona
            girer ve bazı biyokimyasal tepkimeleri tetikleyerek hücresel düzeyde
            bir dizi olumlu etkiye neden olur. Ozon terapisi, genellikle
            enfeksiyonlar, yara iyileşmesi, antioksidan desteği, kronik ağrı,
            iltihaplanma ve bağışıklık sistemini güçlendirme gibi durumlarda
            oldukça etkilidir.
          </div>
          <div className="modal-footer">
            <Link
              to="/ozon"
              type="button"
              className="btn"
              onClick={handleLinkClick}
            >
              Daha Fazlası
            </Link>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
