import React, { useState, useEffect } from "react";
import "./scroll.css";
import logo from "./logo.png"; // Logo image
import cream1 from "./cream1.png"; // Cream bottle images
import cream2 from "./cream2.png";
import cream3 from "./cream3.png";
import cream4 from "./cream4.png";
import { Link } from "react-router-dom";

const Scroll = () => {
  const [message, setMessage] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [showArrow, setShowArrow] = useState(true); // Arrow visibility state
  const [showButton, setShowButton] = useState(false); // Button visibility state

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Update message based on scroll position
      if (scrollY < 1000) {
        setMessage(
          <>
            <span className="nrml">En iyi</span>
            <br />
            <span className="bigT">Hizmet </span>
            <br />
          </>
        );
        setShowArrow(true);
        setShowButton(false); // Hide button
      } else if (scrollY >= 1000 && scrollY < 1300) {
        setMessage(
          <>
            <span className="nrml">En iyi</span>
            <br />
            <span className="bigT">Tedavi </span>
            <br />
          </>
        );
        setShowArrow(true);
        setShowButton(false); // Hide button
      } else if (scrollY >= 1400 && scrollY < 1700) {
        setMessage(
          <>
            <span className="nrml">En iyi</span>
            <br />
            <span className="bigT">Sen </span>
            <br />
          </>
        );
        setShowArrow(true);
        setShowButton(false); // Hide button
      } else if (scrollY >= 1700) {
        setMessage(
          <>
            <span className="nrml">En iyi</span>
            <br />
            <span className="bigT">Sen</span>
            <br />
          </>
        );
        setIsScrolled(true);
        setShowArrow(true);
        setShowButton(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`scroll-container ${isScrolled ? "scrolled" : ""}`}>
      <div className="text-container">
        <img
          src={logo}
          alt="Logo"
          className="logoo"
          style={{ marginBottom: 10 }}
        />
        <h1 className="title">{message}</h1>
        {showArrow && <div className="arrow">&#8595;</div>}
      </div>
      <div className="cream-bottles">
        <img
          src={cream1}
          alt="Cream Bottle 1"
          className="cream-bottle cream-bottle-1"
        />
        <img
          src={cream2}
          alt="Cream Bottle 2"
          className="cream-bottle cream-bottle-2"
        />
        <img
          src={cream3}
          alt="Cream Bottle 3"
          className="cream-bottle cream-bottle-3"
        />
        <img
          src={cream4}
          alt="Cream Bottle 4"
          className="cream-bottle cream-bottle-4"
        />
      </div>
      <div className={`button-container ${showButton ? "visible" : ""}`}>
        <Link to={"/contact"} className="explore-buttonw">
          <span className="weus">Bize Ulaş</span>
        </Link>
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default Scroll;
