import React, { useEffect, useRef, useState } from "react";
import "./reservation.css";
import shape from "./img/shape.png";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import toast from "react-hot-toast";
import axios from "axios";

registerLocale("tr", tr);

const Reservation = () => {
  const inputRefs = useRef([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    date: null,
    time: "",
    message: "",
  });

  const { name, email, phone, service, date, time, message } = formData;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const workingHours = [
    { day: "Pazartesi", hours: "09:00 - 19:00" },
    { day: "Salı", hours: "09:00 - 19:00" },
    { day: "Çarşamba", hours: "09:00 - 19:00" },
    { day: "Perşembe", hours: "09:00 - 19:00" },
    { day: "Cuma", hours: "09:00 - 19:00" },
    { day: "Cumartesi", hours: "10:00 - 16:00" },
    { day: "Pazar", hours: "Kapalı" },
  ];

  const servicesList = [
    "Saç Yenileme",
    "Cilt Bakımı",
    "Yüz Tedavileri",
    "Vücut Tedavileri",
    "Genetik Test",
    "Ozon Terapi",
    "IV Terapi",
    "Kök Hücre Tedavisi",
    "Eksozom Tedavisi",
    "Longevity (Uzun Yaşam)",
    "Diğer",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDayChange = (date) => {
    const selectedDayName = date.toLocaleDateString("tr-TR", {
      weekday: "long",
    });
    if (selectedDayName === "Pazar") {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
    setFormData((prevData) => ({ ...prevData, date, time: "" }));
  };

  const getAvailableTimes = () => {
    if (!date) return [];

    const dayName = date.toLocaleDateString("tr-TR", {
      weekday: "long",
    });
    const workingHour = workingHours.find((item) => item.day === dayName);

    if (!workingHour || workingHour.hours === "Kapalı") return [];

    const times = workingHour.hours.split(" - ");
    let availableTimes = [];
    for (let i = parseInt(times[0]); i < parseInt(times[1]); i++) {
      availableTimes.push(`${i}:00`);
      availableTimes.push(`${i}:30`);
    }
    return availableTimes;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitDisabled) {
      toast.error(
        "Pazar günleri randevu alınamamaktadır. Lütfen başka bir gün seçiniz."
      );
      return;
    }
    try {
      // Tarih formatını kontrol et
      const formattedFormData = {
        ...formData,
        date: date ? date.toISOString().split("T")[0] : "", // Tarihi ISO formatında gönder
      };

      // console.log("Gönderilen veri:", formattedFormData); Hata ayıklama için gönderilen veriyi kontrol et

      const response = await axios.post(
        `https://genesis-api-l0zr.onrender.com/appointments`,
        formattedFormData
      );

      if (response.status >= 200 && response.status < 300) {
        toast.success(
          "Randevunuz oluşturuldu. Lütfen e-posta'nızı kontrol ediniz!"
        );
        setFormData({
          name: "",
          email: "",
          phone: "",
          service: "",
          date: null,
          time: "",
          message: "",
        });
      } else {
        toast.error(
          "Randevu oluşturulurken bir hata oluştu: Tüm alanların dolu olduğundan emin olunuz!"
        );
        // console.log(response.data.message);
        // console.log("Sunucu yanıtı:", response);
        // console.log("Sunucu durum kodu:", response.status);
      }
    } catch (error) {
      if (error.response) {
        // Sunucudan dönen hata
        // console.log("Hata yanıtı:", error.response.data);
        toast.error(
          `Randevu oluşturulurken bir hata oluştu: ${error.response.data.message}`
        );
      } else {
        // Sunucudan yanıt alınamadı
        // console.log("Hata:", error.message);
        toast.error(
          "Randevu oluşturulurken bir hata oluştu: Tüm alanların dolu olduğundan emin olunuz!"
        );
      }
    }
  };

  useEffect(() => {
    const inputs = inputRefs.current;

    const focusFunc = (e) => {
      let parent = e.target.parentNode;
      parent.classList.add("focus");
    };

    const blurFunc = (e) => {
      let parent = e.target.parentNode;
      if (e.target.value === "") {
        parent.classList.remove("focus");
      }
    };

    inputs.forEach((input) => {
      if (input) {
        input.addEventListener("focus", focusFunc);
        input.addEventListener("blur", blurFunc);
      }
    });

    return () => {
      inputs.forEach((input) => {
        if (input) {
          input.removeEventListener("focus", focusFunc);
          input.removeEventListener("blur", blurFunc);
        }
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <span className="big-circle"></span>
        <img src={shape} className="square" alt="" />
        <div className="reservation-form">
          <div className="reser-info">
            <h3 className="reser-title">Çalışma Saatlerimiz</h3>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Gün</th>
                  <th>Saatler</th>
                </tr>
              </thead>
              <tbody>
                {workingHours.map((item) => (
                  <tr key={item.day}>
                    <td>{item.day}</td>
                    <td>{item.hours}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="reser-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form
              className="form-container"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <h3 className="reser-title">Randevu Oluşturun</h3>
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  ref={(el) => (inputRefs.current[0] = el)}
                  value={name}
                  onChange={handleChange}
                />
                <label htmlFor="name">İsim Soyisim</label>
                <span>İsim Soyisim</span>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  ref={(el) => (inputRefs.current[1] = el)}
                  value={email}
                  onChange={handleChange}
                />
                <label htmlFor="email">Email</label>
                <span>Email</span>
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  ref={(el) => (inputRefs.current[2] = el)}
                  value={phone}
                  onChange={handleChange}
                />
                <label htmlFor="phone">Telefon</label>
                <span>Telefon</span>
              </div>
              <div className="input-container">
                <select
                  name="service"
                  className="input service-select"
                  value={service}
                  onChange={handleChange}
                >
                  <option value="">Hizmet Seçiniz</option>
                  {servicesList.map((service) => (
                    <option key={service} value={service}>
                      {service}
                    </option>
                  ))}
                </select>
                <span>Hizmet Seçiniz</span>
              </div>
              <div className="input-container">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center position-relative">
                    <DatePicker
                      selected={date}
                      onChange={handleDayChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Gün Seçiniz"
                      className="input date-picker"
                      locale="tr"
                      showPopperArrow={false}
                      minDate={new Date()} // Geçmiş tarihler engelleniyor
                    />
                    <i className="fa-solid fa-calendar-days position-absolute right-7 text-white"></i>
                  </div>
                  <div className="col-md-6">
                    <select
                      name="time"
                      className="input select-scroll"
                      value={time}
                      onChange={handleChange}
                      required
                      disabled={isSubmitDisabled}
                    >
                      <option value="">Saat Seçiniz</option>
                      {getAvailableTimes().map((time, index) => (
                        <option key={index} value={time}>
                          {time}
                        </option>
                      ))}
                    </select>
                    <span>Saat Seçiniz</span>
                  </div>
                </div>
              </div>

              <div className="input-container textarea">
                <textarea
                  name="message"
                  className="input"
                  ref={(el) => (inputRefs.current[3] = el)}
                  value={message}
                  onChange={handleChange}
                ></textarea>
                <label htmlFor="message">
                  Almak İstediğiniz Hizmetin Detayları (tercihi)
                </label>
                <span>Almak İstediğiniz Hizmetin Detayları (tercihi)</span>
              </div>
              <input
                type="submit"
                value="Gönder"
                className="btn"
                disabled={isSubmitDisabled}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
