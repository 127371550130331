import React from 'react';
import { Link } from 'react-router-dom';
import './modal.scss';

const EksozomModal = () => {

  const handleLinkClick = () => {
    // btn-close butonunu seç ve tıklama olayını tetikle
    const closeButton = document.querySelector('#eksozomModal .btn-close');
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="eksozomModal"
      tabIndex={-1}
      aria-labelledby="eksozomModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <strong className="modal-title fs-5" id="eksozomModalLabel">
              Hücresel İyileşme ve Yenilenme
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            Eksozom terapi, vücudumuzun iyileşme sürecine destek olmak için küçük hücre dışı veziküller olan eksozomları kullanır. Bu veziküller, proteinler, yağlar ve RNA gibi maddeler taşıyarak hücreler arası iletişimi sağlar ve hücrelerin birbirleriyle önemli bilgileri ve materyalleri paylaşmasına olanak tanır. Eksozom terapi, hasarlı dokuların iyileşmesini ve yeni doku oluşumunu teşvik ederek, hücre bazlı tedavilere alternatif bir yöntem sunar.
          </div>
          <div className="modal-footer">
            <Link to="/eksozom" type="button" className="btn" onClick={handleLinkClick}>
              Daha Fazlası
            </Link>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EksozomModal;
