import React from "react";
import { Link } from "react-router-dom";
import "./modal.scss";

const RejuvenationModal = () => {
  const handleLinkClick = () => {
    // btn-close butonunu seç ve tıklama olayını tetikle
    const closeButton = document.querySelector("#rejuvenationModal .btn-close");
    if (closeButton) {
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="rejuvenationModal"
      tabIndex={-1}
      aria-labelledby="rejuvenationModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <strong className="modal-title fs-5" id="rejuvenationModalLabel">
              Yaşlanmayı Geri Çevirme
            </strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            Gençleşme tedavileri yaşlanmanın etkilerini tersine çevirme ve daha
            genç ve sağlıklı bir görünümü teşvik etme amacını taşır. Son
            yıllarda, sünnet derisinin gençleşme tedavilerinde potansiyel olarak
            kullanılması konusunda artan bir ilgi görülmektedir. Sünnet derisi,
            penis başını kaplayan ve sünnet sırasında çıkarılan cilt bölgesidir
            ve regeneratif özellikler sunan çeşitli hücreler ve büyüme
            faktörleri içerir.
          </div>
          <div className="modal-footer">
            <Link
              to="/genclesme"
              type="button"
              className="btn"
              onClick={handleLinkClick}
            >
              Daha Fazlası
            </Link>
            <i className="fa-solid fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejuvenationModal;
