import React from "react";
import "./hmsection.css";

const HomeSection = () => {
  return (
    <div className="section-container">
      <div className="text-containerx">
        <h1 className="main-text">Kendini Sev</h1>
        <h2 className="sub-text">Sağlığın İçin Doğru Yerdesin..</h2>
        <p className="description">
          Sağlığınızı ve güzelliğinizi ön planda tutarak size en iyiyi sunuyoruz. Uzman ekibimizi yenilikçi yöntemlerle sizlerle buluşturuyoruz.
        </p>
      </div>
      <div className="icon-container">
        <div className="icon-item">
          <span className="icone vegan"></span>
          <span className="icon-label">Sağlık</span>
        </div>
        <div className="icon-item">
          <span className="icone cruelty-free"></span>
          <span className="icon-label">Güzellik</span>
        </div>
        <div className="icon-item">
          <span className="icone derm-tested"></span>
          <span className="icon-label">Estetik</span>
        </div>
      </div>
    </div>
  );
};

export default HomeSection;